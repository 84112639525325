import React, { useEffect, useState } from "react";
import {addUser, checkUser} from "../../../services/userService"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HeaderMB from "../../Layout/Menu/HeaderMB/Header";
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';

const CreateAccountMB =(props)=>{
    const { t } = useTranslation();
    const [fullName,setFullName] = useState()
    const [email,setEmail] = useState()
    const [phoneNumber,setPhoneNumber] = useState()
    const [password,setPassword] = useState()
    const [showPassword,setShowPassword] = useState(false)
    const [rePassword,setRePassword] = useState()
    const [reShowPassword,setReShowPassword] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();

    const navigate = useNavigate()

    const handleSubmit = async (event)=>{
        event.preventDefault();
        if(password !=rePassword){
            alert("Mật khẩu không trùng khớp")
            return
        }
        let browserId = localStorage.getItem("browserId")
        if(!browserId) {
            browserId = uuidv4()
            localStorage.setItem("browserId",browserId)
        }
        const param = {            
            displayName:fullName,
            fullName,
            email,
            phoneNumber,
            password,
            rePassword,
            browserId
        }
        const create = await addUser(param);
        if(create?.data && create.isSuccess){
            alert(" Create Account Success")
            let r = searchParams.get("r")
            navigate(`/auth/login?r=${r?? ""}`)
        }else{
            alert(create.errors[0]?.message);
        }
    }

    const [errorC,setErrorC] = useState()
    
    const checkLogin = async () =>{
        try {
            const result = await checkUser()            
            return window.location.replace('/');
        } catch (error) {
            localStorage.removeItem("userInfo")
            localStorage.removeItem("token")
        }
       
    };
    useEffect(() => {
        checkLogin()
    }, [])
    return(
        <>
             <div className='container-fluid loginpageMB' id="loginpageMB">
                <HeaderMB content={"Đăng ký"} />
                <div className="box-img">
                    <img src="/images/mb/haveaniceday.png" />
                    <img src="/images/img-have-a-nice-day.gif" />
                </div>
                <div className="form-login">
                    <form className="row g-3 needs-validation" novalidate onSubmit={handleSubmit}>                       
                        {
                            errorC && <div className="form-group">
                                        <p className="text-center text-danger">{errorC}</p>
                                    </div>
                        }
                         <div className="input-group ">
                            <span className="input-group-text" ><i className="fa-solid fa-user icon-prespend fa-lg"></i></span>
                            <input type="text" className="form-control border-l-none" placeholder={t(`authPage.register.name`)}onChange={(e)=>setFullName(e.target.value) } required/>
                        </div>
                        <div className="input-group ">
                            <span className="input-group-text" ><i className="fa-solid fa-envelope icon-prespend "></i></span>
                            <input type="text" className="form-control border-l-none" placeholder="Email" onChange={(e)=>setEmail(e.target.value)} required/>
                        </div>
                        <div className="input-group ">
                            <span className="input-group-text" ><i className="fa-solid fa-phone icon-prespend fa-lg"></i></span>
                            <input type="number" className="form-control border-l-none" placeholder={t(`authPage.register.phone`)} onChange={(e)=>setPhoneNumber(e.target.value) } required/>
                        </div>
                        <div className="input-group ">
                            <span className="input-group-text" ><i className="fa-solid fa-lock icon-prespend "></i></span>
                            <input type={showPassword ? "text" : "password"} className="form-control form-control-show" placeholder={t(`authPage.register.password`)} onChange={(e)=>setPassword(e.target.value) } required/>
                            <span className="input-group-text" onClick={()=>setShowPassword(!showPassword)}><i className={showPassword ? "fa fa-eye " : "fa fa-eye-slash fa-fw "}></i></span>
                        </div>
                        <div className="input-group ">
                            <span className="input-group-text" ><i className="fa-solid fa-lock icon-prespend "></i></span>
                            <input type={showPassword ? "text" : "password"} className="form-control form-control-show" placeholder={t(`authPage.register.rePassword`)} onChange={(e)=>setRePassword(e.target.value) } required/>
                            <span className="input-group-text" onClick={()=>setShowPassword(!showPassword)}><i className={showPassword ? "fa fa-eye " : "fa fa-eye-slash fa-fw "}></i></span>
                        </div>
                        <div className="input-group">
                            <button className="btn btn-default btn-auth">
                                {t(`authPage.register.btnRegis`)}
                            </button>
                        </div>
                        <div className="register">                                
                            <p className="form-check-label text-center">
                            <p>{t(`authPage.register.guide`)}<a href={`/auth/login?r=${searchParams.get("r")?? ""}`} className="color-anttest"> <b>{t(`authPage.loginPage.btnLogin`)}</b> </a></p>
                            </p>                               
                        </div>
                        <div className="continue">
                            <div className="progress continue-item">
                                <div className="progress-bar" style={{"width":`100%`}} role="progressbar" aria-valuenow={100} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div className="continue-item2">
                                Hoặc tiếp tục với
                            </div>
                            <div className="progress continue-item">
                                <div className="progress-bar" style={{"width":`100%`}} role="progressbar" aria-valuenow={100} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="continue-social">
                            <div className="social social-gg" onClick={()=>props.onGoogleClick()}><i className="fa-brands fa-google-plus fa-2xl p-1" ></i> Google</div>
                            <div className="social social-fb" onClick={()=>props.onFacebookClick()}><i className="fa-brands fa-square-facebook fa-2xl p-1" ></i>Facebook</div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

export default CreateAccountMB