import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// import Backend from 'i18next-http-backend';
// import LanguageDetector from 'i18next-browser-languagedetector';
// don't want to use this?
// have a look at the Quick start guide 
// for passing in lng and translations on init

import en from './assets/locales/en/data.json';
import vi from './assets/locales/vi/data.json';

const defaultLang = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng') : 'vi';

i18n
.use(initReactI18next) // passes i18n down to react-i18next
.init({
    lng: defaultLang,
    fallbackLng: defaultLang,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
        escapeValue: false
    },
    resources: {
        en: {
            translations: en,
        },
        vi: {
            translations: vi,
        },
    },

  });


export default i18n;