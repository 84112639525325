import React, { useEffect, useState } from "react";
import "./index.scss"
import { useParams } from "react-router-dom";
import { getAssessmentById } from "../../../../services/assessmentTestService";
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar';
import { useTranslation } from "react-i18next";
import moment from "moment-timezone";
import { scoreToAnalysis } from "../../../../constants/scoreToAnalysis";
import BoxOverView from "./BoxOverView";
import { isDesktop} from "react-device-detect";

const OverView = ()=>{
    const {id} = useParams();
    const [data,setDate] = useState()
    const { t } = useTranslation();

    const getDataByID = async (id)=> {
        if(!id) return
        const result = await getAssessmentById(id)
        if(result && result.isSuccess){
            setDate(result.data)
        }else{

        }
    }
    const calculate_age =(dob)=> {        
        return new Date().getFullYear() - dob.getFullYear()
    }
    const returnAnalysis = (score)=>{
        let detailText =scoreToAnalysis.speaking.find(e=>e.max == parseFloat(score) ) ?? scoreToAnalysis.speaking[0]
        
        return t(`${detailText.analysis}`)
    }
    
    useEffect(()=>{
        getDataByID(id)
    },[])
    // console.log("-----------",data)

    return(
        <div className="col-md-8 assesment-details2 offset-md-1">
            <div className="row row-header">
                <img className="img-logo" src="/images/logo.png"/>
                <p className="header-title">
                    {t(`overview.header`)}
                </p>
                <p className="header-type">
                    &#8212; {data?.testType ?? ''} Test &#8212;
                </p>
            </div>            
            <div className="accordion" >
                {
                    (!data || data?.isSuccess ===false) && 
                    <>Chưa submit bài thi</>
                }
                { data &&
                    <>
                        <div className="row box-user">
                            <div className="col-md-6 ">
                                <p>{t(`overview.user.fullName`)}: {data?.user?.displayName}</p>
                                <p>{t(`overview.user.Age`)}: {data?.user?.dob ? calculate_age(new Date(data?.user?.dob)) : 0}</p>
                            </div>
                            <div className="col-md-6">
                                <p>Email: { data?.user?.email}</p>
                                <p>{t(`overview.user.testingDay`)}: {data?.submitTime ? moment.utc(data?.submitTime).tz("Asia/Ho_Chi_Minh").format('DD/MM/YYYY') : '' }</p>
                            </div>
                        </div>
                        {data && <BoxOverView data={data}/>}
                        <div className="row box-analysis">
                            <p className="analysis-title">{t(`resultAnalysis.boxResultAnalysis.title`)}</p>
                            {
                                (data?.testType =="AISpeakingAdvanced" || data?.testType =="AISpeakingBasic")  ?
                                <div className="row row-by-skill">
                                    <p className="skill-name">3. {t(`overview.skill.speaking`)}</p>
                                    <div className="box-process col-md-5 ">
                                        <div className="process-header">
                                            <div className="process-header-left">{t(`resultAnalysis.boxResultAnalysis.correctAnswerWriting`)}</div>
                                            <div className="process-header-right">{data?.speakingTriPoints ?? 0}</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{"width":`${data?.speakingPoints*10 ?? 0}%`}} aria-valuenow={data?.speakingPoints*10 ?? 0} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <p className="skill-note" dangerouslySetInnerHTML={{__html:returnAnalysis(data?.speakingPoints ?? 0)}} ></p>

                                </div>
                                :
                                <>
                                <div className="row row-by-skill">
                                    <p className="skill-name">1. {t(`overview.skill.listening`)}</p>
                                    <div className="box-process col-md-5 ">
                                        <div className="process-header">
                                            <div className="process-header-left">{t(`resultAnalysis.boxResultAnalysis.correctAnswer`)}</div>
                                            <div className="process-header-right">{data?.listeningTriPoints ?? 0 }</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar " role="progressbar" style={{"width":`${data?.listeningPoints*10 ?? 0}%`}} aria-valuenow={data?.listeningPoints*10 ?? 0} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <p className="skill-note" dangerouslySetInnerHTML={{__html:returnAnalysis(data?.listeningPoints ?? 0)}} ></p>
                                </div>
                                <div className="row row-by-skill">
                                    <p className="skill-name">2. {t(`overview.skill.reading`)}</p>
                                    <div className="box-process col-md-5 ">
                                        <div className="process-header">
                                            <div className="process-header-left">{t(`resultAnalysis.boxResultAnalysis.correctAnswer`)}</div>
                                            <div className="process-header-right">{data?.readingTriPoints ?? 0}</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar " role="progressbar" style={{"width":`${data?.readingPoints*10 ?? 0}%`}} aria-valuenow={data?.readingPoints*10 ?? 0} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <p className="skill-note" dangerouslySetInnerHTML={{__html:returnAnalysis(data?.readingPoints ?? 0)}} ></p>
                                </div>
                                <div className="row row-by-skill">
                                    <p className="skill-name">3. {t(`overview.skill.speaking`)}</p>
                                    <div className="box-process col-md-5 ">
                                        <div className="process-header">
                                            <div className="process-header-left">{t(`resultAnalysis.boxResultAnalysis.correctAnswerWriting`)}</div>
                                            <div className="process-header-right">{data?.speakingTriPoints ?? 0}</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" role="progressbar" style={{"width":`${data?.speakingPoints*10 ?? 0}%`}} aria-valuenow={data?.speakingPoints*10 ?? 0} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <p className="skill-note" dangerouslySetInnerHTML={{__html:returnAnalysis(data?.speakingPoints ?? 0)}} ></p>

                                </div>
                                <div className="row row-by-skill">
                                    <p className="skill-name">3. {t(`overview.skill.writing`)}</p>
                                    <div className="box-process col-md-5 ">
                                        <div className="process-header">
                                            <div className="process-header-left">{t(`resultAnalysis.boxResultAnalysis.correctAnswerWriting`)}</div>
                                            <div className="process-header-right">{data?.writingTriPoints ?? 0}</div>
                                        </div>
                                        <div className="progress">
                                            <div className="progress-bar" style={{"width":`${data?.writingPoints*10 ?? 0}%`}} role="progressbar" aria-valuenow={data?.writingPoints*10 ?? 0} aria-valuemin="0" aria-valuemax="100"></div>
                                        </div>
                                    </div>
                                    <p className="skill-note" dangerouslySetInnerHTML={{__html:returnAnalysis(data?.writingPoints ?? 0)}} ></p>

                                </div>
                                </>
                            }
                            
                        </div>
                        <p className="text-center mt-4" ><i  className={isDesktop? "color-white" : 'color-black'} dangerouslySetInnerHTML={{__html:t(`overview.detailLink1`,{id}) }}></i></p>
                    </>
                }
                             
            </div>
        </div>
    )
}

export default OverView
