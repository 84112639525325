import httpClient from '../../../services/httpClient.service'
import APICONFIGS from './api-configs.json'

export const editorCommentService = {
  getCommentsByChannelId,
  getThreadsByChannelId,
  getThreadByThreadId,
  createComment,
  updateComment,
  deleteComment,
  createThread,
  updateThread,
  deleteThread,
}

function getCommentsByChannelId(channelId) {

  let apiUrl = 
    `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/comments/by-channel/${channelId}`

  return httpClient.get(apiUrl).then(res => res.data)

  // return customFetch(apiUrl, requestOptions).then((res) => res)
}

function getThreadsByChannelId(channelId) {
  // let requestOptions = {
  //   method: 'GET',
  //   cache: 'no-cache',
  //   headers: authHeader(),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/threads/by-channel/${channelId}`
  return httpClient.get(apiUrl).then(res => res.data)
  // return customFetch(apiUrl, requestOptions).then((res) => res.data)
}

function getThreadByThreadId(threadId) {
  // let requestOptions = {
  //   method: 'GET',
  //   cache: 'no-cache',
  //   headers: authHeader(),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/threads/${threadId}`
  
  return httpClient.get(apiUrl).then(res => res.data)
  // return customFetch(apiUrl, requestOptions).then((res) => res)
}

function createComment(model) {
  // let requestOptions = {
  //   method: 'POST',
  //   cache: 'no-cache',
  //   headers: authHeader(jsonHeader()),
  //   body: JSON.stringify(model),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/comments`
  return httpClient.post(apiUrl, model).then(res => res.data)
  // return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateComment(model) {
  // let requestOptions = {
  //   method: 'PUT',
  //   cache: 'no-cache',
  //   headers: authHeader(jsonHeader()),
  //   body: JSON.stringify(model),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/comments`
  return httpClient.put(apiUrl, model).then(res => res.data)
  // return customFetch(apiUrl, requestOptions).then((res) => res)
}

function deleteComment(commentId) {
  // let requestOptions = {
  //   method: 'DELETE',
  //   cache: 'no-cache',
  //   headers: authHeader(),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/comments/${commentId}`
  return httpClient.delete(apiUrl).then(res => res.data)
  // return customFetch(`${apiUrl}`, requestOptions).then((res) => res)
}

function createThread(model) {
  // let requestOptions = {
  //   method: 'POST',
  //   cache: 'no-cache',
  //   headers: authHeader(jsonHeader()),
  //   body: JSON.stringify(model),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/threads`
  return httpClient.post(apiUrl, model).then(res => res.data)
  // return customFetch(apiUrl, requestOptions).then((res) => res)
}

function updateThread(model) {
  // let requestOptions = {
  //   method: 'PUT',
  //   cache: 'no-cache',
  //   headers: authHeader(jsonHeader()),
  //   body: JSON.stringify(model),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/threads`
  
  return httpClient.put(apiUrl, model).then(res => res.data)
  // return customFetch(apiUrl, requestOptions).then((res) => res)
}

function deleteThread(threadId) {
  // let requestOptions = {
  //   method: 'DELETE',
  //   cache: 'no-cache',
  //   headers: authHeader(),
  // }
  let apiUrl = `${process.env.REACT_APP_BE_HOST}${APICONFIGS.EditorComments}/threads/${threadId}`
    
  return httpClient.delete(apiUrl).then(res => res.data)
  // return customFetch(`${apiUrl}`, requestOptions).then((res) => res)
}
