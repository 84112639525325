import React, { useEffect, useState } from "react";
import "./index.scss";
import SingleChoice from "../share/SingleChoice";
import TableStriped from "../share/TableStriped";
import QuestionSelectAnswer from "../share/QuestionSelectAnswer";
import WriteAnswer from "../share/ListenWriteAnswer";
import ChoiceAnswer from "../share/ListenChoiceAnswer";
import MultipleChoice from "../share/MultipleChoiceAnswer";
import { isDesktop} from "react-device-detect";

const Reading = ({nextSkill})=>{
    const [data,setData] = useState()
    const [totalQuestion,setTotalQuestion] = useState([])
    const darkMode = localStorage.getItem('darkMode') ?? "dark"
    const [passage,setPassage] = useState(0)
    
    // const [listAnswered,setListAnsered]= useState(localStorage.getItem('ReadingAnswer') ? JSON.parse(localStorage.getItem('ReadingAnswer')): [] )
    const [listAnswered,setListAnsered]= useState(new Map())

    const nextPassage =()=>{
        if(data.sectionExams.length > passage+1) {
            setPassage(passage+1)       
        }
    }
    const prevPassage =()=>{
        if(passage>0) {
            setPassage(passage-1)       
        }
    }

    const handleAnswer =(questionId,value)=>{        
        let stgAnswer = localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map();
        stgAnswer.set(
            questionId, 
            {
                questionId,answerContent:value
            }
        );
        setListAnsered(stgAnswer);
        localStorage.setItem('answer',JSON.stringify(Object.fromEntries(stgAnswer)))
    }

    const getData = async ()=>{
        const newdata =  JSON.parse(localStorage.getItem('exam'))?.questionContent
        // const newdata = JSON.parse(questionContent)
        let content = newdata.languageSkillExams.filter(e => e.languageSkillType ==='Reading');
        setData(content[0].partitionExams[0])

        let arrQuestion = [];
        content[0].partitionExams[0]?.sectionExams?.map((e)=>{
            e.groupQuestionExams?.map((i)=>{
                i.questionExams?.map((j)=>{
                    let newPoint = j.points ?? 1;
                    for (let index = 0; index < newPoint; index++) {
                        arrQuestion.push(j.id)                    
                    }
                })
            })
        })
        setTotalQuestion(arrQuestion)
    }
    useEffect(()=>{
          getData();
    },[])
    console.log('---',data)
    return(
        <>
            <div  id="reading" className="reading">
                <div className="justify-content-center align-items-center ">
                    <div className="container wrapper-content wrapper-content-reading">
                        <div className="row">
                            <div className="col-md-6 ">
                                <div className="box-passage d-flex justify-content-center align-items-conter ">
                                    <div className="item-passage">
                                        <div className={(passage) >= 1?"item-icon item-icon-active":"item-icon"}  onClick={()=>prevPassage()}>
                                            <i className="fa fa-angle-left" aria-hidden="true"></i>
                                        </div> 
                                    </div>
                                    <div className={`${darkMode == "light" ? "item-passage color-black" :" item-passage color-white"}`}>
                                        Passage {passage+1}
                                    </div>
                                    <div className="item-passage ">
                                        <div className={(passage+1) >= data?.sectionExams?.length?"item-icon":"item-icon item-icon-active"} onClick={()=>nextPassage()}>
                                            <i className="fa fa-angle-right" aria-hidden="true"></i>
                                        </div>                                        
                                    </div>
                                </div>
                                <div className={`${darkMode == "light" ? "box-read-text color-black" :" box-read-text color-white"}`}>
                                    <div className="ielts-wrapper-head">
                                        {/* <p className="title text-center m-0" >{data.SectionExams[passage]?.title}</p> */}
                                    </div>
                                    <div className={`ielts-wrapper-content ${darkMode == "dark" ? "dark-mode" :" light-mode"}`}>
                                        <p className="ielts-wrapper-content-text" dangerouslySetInnerHTML={{__html:data?.sectionExams[passage]?.description}}></p>
                                    </div>
                                </div>
                               
                            </div>
                            <div className={`col-md-6 box-read-question ${darkMode=="dark" ? "color-white" :"color-black" }`}>
                                <div className="ielts-wrapper-head">
                                    <p className="title" >Passage {passage+1}</p>
                                    {/* <p className="detail" ><i>{data[passage].request}</i></p> */}
                                </div>
                                {
                                    data?.sectionExams[passage]?.groupQuestionExams?.map((e)=>{
                                        
                                        return(
                                            <div key={e.id}>                                                
                                                <div className={`ielts-wrapper-content ${darkMode == "dark" ? "dark-mode" :" light-mode"}`}>
                                                    <div className="row d-flex justify-content-center">
                                                        <div className={`box-content-head ${darkMode=="dark"? " dark-mode":"light-mode"}`}>
                                                            <p className="title" dangerouslySetInnerHTML={{ __html: e.description}} ></p>
                                                            {/* <div className="detail" dangerouslySetInnerHTML={{ __html: e.request}} ></div>
                                                            <div className="detail" dangerouslySetInnerHTML={{ __html: e.guide}} ></div>
                                                            <div className="detail" dangerouslySetInnerHTML={{ __html: e.detail}} ></div> */}
                                                            
                                                            {/* {
                                                                e.content && <TableStriped data={e.content}/>
                                                            } */}
                                                        </div>
                                                        {
                                                            e.questionExams?.map((i)=>{
                                                                // console.log(i)
                                                                if(i.questionType ==='FillTheBlank'){
                                                                    return  <WriteAnswer data={i} key ={i.id}  handleAnswer={handleAnswer}/>
                                                                }
                                                                if(i.questionType ==='SingleChoice'){
                                                                    if(!i.displayType || i.displayType == "Dropdown"){
                                                                        return <QuestionSelectAnswer data={i} key={i.id}  handleAnswer={handleAnswer}/>                                   
                                                                    } else if( i.displayType === "List"){
                                                                        return  <SingleChoice key={i.id} data={i}  answerHandle={handleAnswer}/>
                                                                    } else {
                                                                        
                                                                    }
                                                                }
                                                                if(i.questionType ==='MultipleChoice'){
                                                                    return  <MultipleChoice data={i} key ={i.id}  handleAnswer={handleAnswer}/>
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                                
                            </div>
                        </div>                        
                    </div>
                    
                    {/* {
                        isDesktop &&
                        <div className="ielts-wrapper-footer">
                            <div className="container h-100">
                                <div className="row h-100">
                                    <div className="col-md-8 p-0 d-flex align-items-center h-100">
                                        <ul className="list_question p-0 m-0">
                                            {
                                                totalQuestion.map((e,i)=>{
                                                    return <li className={listAnswered.get(e)?"answered":""}  key={i}><a href={`#question-${e}`}>{i+1}</a></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row h-50">
                                            <div className="d-flex align-items-end justify-content-center btn-next-skill" onClick={()=>nextSkill(2)}>Go to next skill <i className="fa fa-angle-double-right" aria-hidden="true"></i></div> 
                                        </div>
                                        <div className="row h-50 guide-question ">
                                            <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                                                <li><div className="circle " ></div></li>                                 
                                                <li>Unanswered</li>                             
                                                <li><div className="circle circle-answered" ></div></li>                                 
                                                <li>Answered</li>                                 
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}
                    {
                        isDesktop &&
                        <div className={`ielts-wrapper-footer ${darkMode == "light" ? "background-white" :" background-footer-dark"}`}>
                            <div className="container h-100">
                                <div className="row h-100">
                                    <div className="col-md-8 p-0 d-flex align-items-center h-100">
                                        <ul className="list_question p-0 m-0">
                                            {
                                                totalQuestion.map((e,i)=>{
                                                    return <li className={listAnswered.get(e) ? darkMode =="light"? "answered border-3060CF background-3060CF" :"answered border-00F0FF background-00F0FF"  : darkMode =="light"? "border-black " :" border-white"}  key={i}><a className={darkMode =="light" ? listAnswered.get(e) ?"color-white" : "color-black":"color-white"} href={`#question-${e}`}>{i+1}</a></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <div className={darkMode =="light" ?"row h-50 color-black" : "row h-50 color-white"}>
                                            <div className={darkMode =="light" ? "d-flex align-items-end justify-content-center btn-next-skill-light"  : "d-flex align-items-end justify-content-center btn-next-skill"  }onClick={()=>nextSkill(2)}>Go to next skill <i className="fa fa-angle-double-right" aria-hidden="true"></i></div> 
                                        </div>
                                        <div className={darkMode == "light" ? "row h-50 guide-question color-black ":"row h-50 guide-question color-white"}>
                                            <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                                                <li><div className={darkMode=="light"?"circle border-black":"circle border-white"} ></div></li>                                 
                                                <li>Unanswered</li>                             
                                                <li><div className={darkMode=="light"?"circle circle-answered border-3060CF background-3060CF":"circle circle-answered border-00F0FF background-00F0FF"}></div></li>                                 
                                                <li>Answered</li>                                 
                                            </ul>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default Reading