import React, { useEffect, useRef, useState } from 'react'
import './welldoneMb.scss'
import { useNavigate } from 'react-router-dom'
import HeaderMB from '../Layout/Menu/HeaderMB/Header'
import VoucherModal from '../ComponentShare/Modal/VoucherModal'
import { getIeltsExamsAssessment } from '../../services/assessmentTestService'

const WelldoneMb = (props) => {
    const navigate = useNavigate()
    const [email,setEmail] = useState();
    const [phoneNumber,setPhoneNumber] = useState();
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    const subSkill = localStorage.getItem("subSkill");
    const skill = localStorage.getItem("skill");
    const [openVoucher,setOpenVoucher] = useState(false)

    const handleSubmit =(status)=>{
        if(!email || !phoneNumber) return alert("Vùi lòng điền đầy đủ thông tin email và số điện thoại!")
        const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {};
        userInfo.email = email;
        userInfo.phoneNumber = phoneNumber;
        localStorage.setItem("userInfo",JSON.stringify(userInfo));
        if(!status){
            if(skill === "/speakingai" && subSkill !="" ) return navigate("/result-analysis-ai")
            navigate("/result-analysis")
        }else{
            navigate("/auth/register")
        }
        
    }
    const handleViewRessult = ()=>{
        if(skill === "/speakingai" && subSkill !="" ) return navigate("/result-analysis-ai")
        navigate("/result-analysis")
        
    }

    const getHistory = async ()=>{
        if(!userInfo?.id) return
        const param = [
            `Page=1`,
            `PageSize=100`,
        ]
        const history = await getIeltsExamsAssessment(param)        
        if(!history || !history.isSuccess) return
        const filterAiSpeaing = history?.data?.items?.filter((e)=>e.testType =="AISpeakingBasic" || e.testType =="AISpeakingAdvanced")
        if(filterAiSpeaing.length ==1) setOpenVoucher(true)

    }
    useEffect(()=>{
        getHistory()
    },[])
    return (
        <div className='welldone-mb'>   
            {openVoucher && <VoucherModal setOpen={setOpenVoucher} /> }   
            <HeaderMB content={"AI Input Test"}/>
            <div className='wd-content'>
                <img src='/images/light/youdidit.png' />
                
                {
                    !userInfo?.id ? 
                    <>
                        <h2 className='mt-3'>Chúc mừng!</h2>
                        <p>Cảm ơn {userInfo.userName?? "bạn"}, Bài thi với 03 kỹ năng Nghe - Đọc - Viết của bạn đã được <b>ghi nhận thành công</b> trên hệ thống.</p>
                        <p>Kết quả phân tích bài thi đầy đủ của bạn sẽ được gửi đến bạn trong vòng <b>72h</b>, trong đó kết quả với kỹ năng Nghe & Đọc có thể được gửi đến sớm hơn kèm lời khuyên của cố vấn học tập từ Ant Edu. </p>
                        <p>Bạn hãy điền thật chính xác thông tin liên hệ nhé:</p>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Nhập email của bạn" aria-label="Nhập email của bạn"  onChange={event => setEmail(event.target.value)}/>                           
                        </div>
                        <div className="input-group">
                            <input type="number" className="form-control" placeholder="Nhập số điện thoại" aria-label="Nhập số điện thoại"  onChange={event => setPhoneNumber(event.target.value)}/>                           
                        </div>
                        
                        <p className='mt-3'><b>Gợi ý:</b> <i>Đăng ký tài khoản Ant Edu để trải nghiệm đầy đủ các tính năng mới miễn phí</i></p>
                    
                    </>
                    :
                    <>
                    <h2 className='mt-3 text-left'>Chúc mừng!</h2>
                    <p>Cảm ơn {userInfo.userName?? "bạn"},</p>
                    <p>Bài thi AI Speaking của bạn đã được ghi nhận thành công trên hệ thống.</p>
                    </>
                }
               
                
            </div>
            <div className='wd-footer'>
                <div className="input-group mt-2 ">
                    <button type='button' className='btn' onClick={()=>navigate("/")}>Trở về trang chủ</button>
                </div>
                {
                    props.complete?
                    <div className="input-group mt-2 ">
                        <button type='button' className='btn btn-result' onClick={()=>handleViewRessult()}>Xem kết quả ngay</button>
                    </div>   
                    :
                    <div className="input-group mt-2 ">
                        <button type='button' className='btn btn-register' disabled>
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Đang chấm bài...</span>
                            </div>                               
                            <b>Đang chấm bài...</b>
                        </button>
                    </div>
                }
                        
                            
            </div>
        </div>
    )
}
export default WelldoneMb