import React, { useState } from "react";
import './index.scss'
import ckeditor5Helpers from "../CKEditer/ckeditor5Helpers"
import CCKEditor5WithComments from "../../../untils/CCKEditor5WithComments";
import { useParams } from "react-router-dom";

const WritingReView = ({data,point,answer})=>{

    const params= useParams()
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
       
    const renderMarkAsCorrect =(point)=>{
        return <span className="correct-true">{`+${point?.points ?? 0}`}</span>
    }

    const testData = `<p>Task 2: <comment-start name="e4bcd2b5e20462a7ac5138cc6eeca88cb:edebf"></comment-start>You<comment-end name="e4bcd2b5e20462a7ac5138cc6eeca88cb:edebf"></comment-end> should spend <comment-start name="e73498931a1785364e226f924d3c356a9:b0c8f"></comment-start>about 40 minutes <comment-end name="e73498931a1785364e226f924d3c356a9:b0c8f"></comment-end>on this task. It is the responsibility of schools to teach children good behavior in addition to providing formal education. To what extent do you agree or disagree? You should write at least <comment-start name="ec7b20a841dc2824276b6c4fdd3a6e7d6:7f19b"></comment-start>250<comment-end name="ec7b20a841dc2824276b6c4fdd3a6e7d6:7f19b"></comment-end> <comment-start name="e02e87b8a67a5ca6415b50564460782a3:15273"></comment-start>words<comment-end name="e02e87b8a67a5ca6415b50564460782a3:15273"></comment-end>.</p>`

    return (
        <div className="writing-review">  
            <div className="question-name"></div>
            {
                renderMarkAsCorrect(point)
            }
            <div className="question-content" dangerouslySetInnerHTML={{__html:data?.content}}></div>

            <div className="view-answerExams" >
                <p className="your-answer">Your Answer</p>
                <div className="answer-detail">
                    <p dangerouslySetInnerHTML={{__html:answer?? ''}}></p>
                </div>
                <p className="recommend-answer">Recommend for you</p>
                <div className="recommend-detail">
                    {/* <p dangerouslySetInnerHTML={{__html:point?.note ?? ''}}></p>
                     */}

                 
                    <CCKEditor5WithComments
                        config={{
                            placeholder: 'Nhận xét của giảng viên',
                            extraPlugins: [
                            ckeditor5Helpers.createUserMePlugins({
                                id: String(userInfo?.id ?? 0), //Thay bằng ID thằng đang đăng nhập
                                name: userInfo?.userName ?? "Guest", // Thay bằng Name thằng đang đăng nhập // usernam hay displayname // cứ để username đi
                                //Còn trường hợp Anonymos nữa, chắc nếu là GUest thì cứ để Id là 0 / name là Guest
                                //Bắt buộc phải định nghĩa 1 User là Me (tôi) thì mới dùng đc cái comments này// ok a

                            }),
                            ],
                            collaboration: {
                            channelId: `${params?.id}|${data?.id}`, //fix chỗ này nhé format `{IdBaiLam}|{IdCauHoiWriting}`
                            // channelId: `08db8dbb-c072-44cd-8b6c-e1eb2938b10d|08db877b-0655-4cb0-8055-b3ded6e8f1f6`
                            },
                        }}
                        //Nếu có 2 cái như vậy thì bỏ || answer di để mỗi point?.note là đc
                        value={point?.note }
                    />
                </div>
            </div>
            
            
        </div>
    )
}
export default WritingReView