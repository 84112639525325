import React, { useEffect } from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import MenuUser from '../Layout/Menu/MenuUser'
import Sidebar from '../UserPage/Sidebar'
import { getUser } from '../../services/userService'

const AuthRoute = () => {
  const userInfo = localStorage.getItem("userInfo")

  const checkLogin = async () =>{
    try {
        const result = await getUser()
    } catch (error) {
        localStorage.removeItem("userInfo")
        localStorage.removeItem("token")
    }
   
};
useEffect(() => {
    checkLogin()
}, [])
  return (
    <Outlet />
  )
  
}

export default AuthRoute
