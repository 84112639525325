import React from "react";
import "./index.scss"
import { useNavigate } from "react-router-dom";
const MyCourse = ()=>{
    const navigate = useNavigate()
    const handleClick =(skill)=>{
        navigate(skill)
        localStorage.setItem('skill',skill)
    }
    return(
        <div className="row mycourse text-center">
            {/* <div className="col-md-8"> */}
                <p className="title" >RELAX, WE'RE WORKING ON IT FOR YOU!</p> 
                <p className="m-0 p-0">You are very special for us and we are making the best for you.</p>
                <p className="m-0 p-0">Please stay tuned and wait for its lastest version. Have a nice day!</p>
                <img src="/images/mycourse.gif"/>
                <p className="title" >Let’s take a test!</p> 
                <div className="row justify-content-md-center">
                    <div className="col-md-4" >
                        <div className="skill" onClick={()=>handleClick('/fulltest')} >Full Test</div>
                    </div>
                    <div className="col-md-4" >
                        <div className="skill" onClick={()=>handleClick('/shorttest')}>Quick Test</div>
                    </div>
                    {/* <div className="col-md-4" > */}
                        {/* <div className="skill" onClick={()=>handleClick('/speaking')}>AI Speaking Test</div> */}
                    {/* </div> */}
                </div>
            {/* </div> */}
        </div>
    )
}

export default MyCourse