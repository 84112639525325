import { toast } from "react-toastify";

const success =(message)=>{
    return toast.success(message)
}

const info =(message)=>{
    return toast.info(message)
}

const warn =(message)=>{
    return toast.warn(message)
}

const error =(message)=>{
    return toast.error(message)
}

const dark =(message)=>{
    return toast.dark(message)
}

const toastifyClient = {
    success,
    info,
    warn,
    error,
    dark
}

export default toastifyClient