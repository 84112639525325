import React, { useEffect } from "react"
import "./indexMb.scss"
import {Link} from "react-scroll";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { checkUser, fqaSystem, signOut } from "../../services/userService";
import { useForm } from "react-hook-form";
import AiSpeakingModal from "../ComponentShare/Modal/AiSpeakingModal";

const HomePageMb = () => {  
    const { t } = useTranslation();
    const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {};
    const navigate = useNavigate();
    const {register, handleSubmit, formState:{errors}} = useForm();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleclicktest = (skill)=>{
        localStorage.setItem("skill",skill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }
    const handleClickSpeaingTest = async (subSkill)=>{
        
        try {
            const result = await checkUser()
        } catch (error) {
            navigate("/auth/login")
            return
        }            
        localStorage.setItem("skill",'/speakingai')
        localStorage.setItem("subSkill",subSkill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }

    const handleRegistration= async (data) =>{
        const resultRegister = await fqaSystem({...data,source:process.env.REACT_APP_DOMAIN,title:""});
        if(resultRegister?.isSuccess) return alert("Gửi thông điệp thành công")
        return alert("Gửi thông điệp thất bại")
    }
    const registrationOptions = {
        fullname:{required: "FullName is required"},
        email:{required: "Email is required"},
        phone:{required: "SĐT is required"}
    }

    const logout= async ()=>{
        try {
            let result = await signOut()
            navigate('/auth/login',{replace:true})
        } catch (error) {
            alert("logout error!")
        }
    }

    return(
        <>
             {
               searchParams.get("test")=="speakingai" &&  <AiSpeakingModal handleClickTest={handleClickSpeaingTest} />
            }
            <div className='container-fluid homepage' id="homepage">
                <div className="header">
                    <div className="top">
                        <div className="logo">
                            <a className="navbar-brand " href="/">
                                <img
                                    src="/images/logo.png"
                                    className="mt-3"
                                    height="85"
                                    width="90"
                                    alt="Logo"
                                    loading="lazy"
                                />
                            </a>
                        </div>
                        {
                            userInfo?.id ?
                            <>
                                <div className="btn-group">
                                    <img className="avatar" src={`${process.env.REACT_APP_BE_HOST}/image/567b1aee-5751-4024-a734-b64bc47eb316.jpg`} data-bs-toggle="dropdown" aria-expanded="false"/>
                                    <ul className="dropdown-menu">
                                        <li className="dropdown-item" onClick={()=>logout()}>Đăng xuất</li>
                                    </ul>
                                </div>
                            </> 
                            :
                            <div className="auth">
                                <div className="btn-login" onClick={()=>window.location.href="/auth/login"}>Đăng nhập</div>
                            </div>
                        }
                       
                    </div>
                    <div className="content-header">
                        <p className="highlight-title">GLOBALIZE WITH YOUR FLUENT ENGLISH</p>
                        <p className="sub-title">Test your level with AI</p>
                    </div>
                </div>
                <div className="content">
                    <div className="ct-menu">
                        <div className="list-menu">
                            <div className="menu-item"><Link to="inputTest" duration={500} activeClass='active'smooth={true} spy={true}>Test đầu vào</Link></div>
                            <div className="menu-item"><a target="_blank" href={`${process.env.REACT_APP_ONLINE_DOMAIN}/menu`} >Khóa học Video</a></div>
                            <div className="menu-item"><Link to="contact" duration={500} activeClass='active' smooth={true} spy={true} >Liên hệ</Link></div>
                        </div>
                    </div>
                    <div className="about-us" id="aboutUs">
                        <div className="about-us-title"><b>Về Chúng Tôi</b></div>
                        <div className="list-card">
                            <div className="row-card">
                                <div className="box-card box-card1">
                                    <a href="https://therealielts.vn/ve-phuong-phap-hoc/" target="_blank" >    
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/1.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Công nghệ giảng dạy</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="box-card box-card2">
                                    <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">    
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/2.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Đội ngũ nhân sự</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                            <div className="row-card">
                                <div className="box-card box-card2">
                                    <a href="https://therealielts.vn/ve-phuong-phap-hoc/" target="_blank">
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/3.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Phương pháp độc quyền</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="box-card box-card1">
                                    <a href="https://ant-edu.vn/san-pham/" target="_blank">
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/4.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Cam kết văn bản</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div> 
                            <div className="row-card">
                                <div className="box-card box-card1">
                                    <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">    
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/5.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Xây dựng cộng đồng</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="box-card box-card2">
                                    <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/6.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Hợp tác sản phẩm</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div> 
                            <div className="row-card">
                                <div className="box-card box-card2">
                                    <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/7.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Sự kiện & truyền thông</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="box-card box-card1">
                                    <a href="https://khaothi.ant-edu.ai/" target="_blank">
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/mb/8.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Luyện thi & khảo thí</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div className="input-test" id="inputTest">
                        <div className="input-test-title"><b>AI SPEAKING TEST</b></div>
                        <div className="list-test">
                            {/* <div className="card">
                                <div className="card-body">
                                    <div className="card-top" >
                                        <div className="top-left">
                                            <img  src="/images/mb/test1.png"/>
                                        </div>
                                        <div className="top-right">
                                            <p className="top-right-title">
                                                Standard Test
                                            </p>
                                            <p className="top-right-guide">
                                                Bài kiểm tra đầu vào tiêu chuẩn với 3 kỹ năng: Nghe, Đọc, Viết
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-bottom" >
                                        <div className="btn-test" onClick={()=>handleclicktest('/shorttest')}>
                                            Short test (15’)
                                        </div>
                                        <div className="btn-test btn-full-test" onClick={()=>handleclicktest('/fulltest')}>
                                            Full Test (60’)
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-top" >
                                        <div className="top-left">
                                            <img  src="/images/mb/test2.png"/>
                                        </div>
                                        <div className="top-right">
                                            <p className="top-right-title">
                                                AI Speaking Test
                                            </p>
                                            <p className="top-right-guide">
                                                Bài kiểm tra năng lực nói tiếng Anh bằng công nghệ AI
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-bottom1" >
                                        <div className="btn-test btn-full-test"  data-bs-toggle="modal" data-bs-target="#speakingModal">
                                            Thi ngay
                                        </div>
                                        <div className="modal fade" id="speakingModal" tabIndex={-1} aria-labelledby="speakingModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className='row'>
                                                        <div className='col-11 '>
                                                            <h1 className="modal-title fs-5 text-center" id="speakingModalLabel" dangerouslySetInnerHTML={{ __html: t(`home.testlist.speakingtest.lable`)}}></h1>
                                                        </div>
                                                        <div className='col-1 p-0'>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-6'>
                                                            <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickSpeaingTest("basic")}>{t(`home.testlist.speakingtest.selectSkill.basic`)}</button>
                                                        </div>
                                                        <div className='col-6'>
                                                            <button type="button" className="btn btn-outline-primary btn-lg w-100 btn-advance" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickSpeaingTest("advance")} >{t(`home.testlist.speakingtest.selectSkill.advance`)}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card">
                                <div className="card-body">
                                    <div className="card-top" >
                                        <div className="top-left">
                                            <img  src="/images/mb/test1.png"/>
                                        </div>
                                        <div className="top-right">
                                            <p className="top-right-title">
                                                Thi thử
                                            </p>
                                            <p className="top-right-guide">
                                                Bài kiểm tra năng lực nói tiếng Anh bằng công nghệ AI
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-bottom1" >
                                        <div className="btn-test btn-full-test" onClick={()=>alert("Coming soon")}>
                                            Thi ngay
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

                    <div className="contact" id="contact">
                        <div className="box-contact">
                            <div className="contact-title"><b>Liên hệ với chúng tôi</b></div>
                            <div className="contact-title-sub">Điền vào biểu mẫu, nhóm của chúng tôi sẽ liên hệ lại với bạn trong vòng 24 giờ</div>
                            <form onSubmit={handleSubmit(handleRegistration)}>
                                <div className="input-row">
                                    <label htmlFor="fullName">Họ và tên</label><br/>
                                    <small className="text-danger">{errors?.fullname && errors.fullname.message}</small>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" id="fullName" name="fullname" {...register('fullname',registrationOptions.fullname)} placeholder="Nhập tên của bạn tại đây"  />
                                        
                                    </div>
                                </div>
                                <div className="input-row">
                                    <label htmlFor="phone">Số điện thoại</label><br/>
                                    <small className="text-danger">{errors?.phone && errors.phone.message}</small>
                                    <div className="input-group mb-3">
                                        <input type="number" className="form-control" id="phone" name="phone" {...register('phone',registrationOptions.phone)} placeholder="Nhập số điện thoại tại đây"/>
                                    </div>
                                </div>
                                
                                <div className="input-row">
                                    <label htmlFor="fullName">Email</label><br/>
                                    <small className="text-danger">{errors?.email && errors.email.message}</small>
                                    <div className="input-group mb-3">
                                        <input type="email" className="form-control" id="email" name="email" {...register('email',registrationOptions.email)} placeholder="Nhập email tại đây" />
                                    </div>
                                </div>
                                <div className="input-row">
                                    <label htmlFor="content">Thông điệp</label>
                                    <div className="input-group mb-3">
                                        <input type="text" className="form-control" id="content" name="content" {...register('content')} placeholder="Viết thông điệp của bạn tại đây" />
                                    </div>
                                </div>
                                <div className="input-row mt-3">
                                    <div className="input-group mb-3">
                                        <button className="btn-submit">Gửi Thông Điệp</button>
                                    </div>
                                </div>
                            </form>
                          
                        </div>
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default HomePageMb