export const scoreToAnalysis =
{
    reading:
    [
        {min:0,max:2.5,score:2.5,analysis:"scoreToAnalysis.reading.read25"},
        {min:2.6,max:3,score:3,analysis:"scoreToAnalysis.reading.read30"},
        {min:3.1,max:3.5,score:3.5,analysis:"scoreToAnalysis.reading.read35"},
        {min:3.6,max:4,score:4,analysis:"scoreToAnalysis.reading.read40"},
        {min:4.1,max:4.5,score:4.5,analysis:"scoreToAnalysis.reading.read45"},
        {min:4.6,max:5,score:5,analysis:"scoreToAnalysis.reading.read50"},
        {min:5.1,max:5.5,score:5.5,analysis:"scoreToAnalysis.reading.read55"},
        {min:5.1,max:6,score:6,analysis:"scoreToAnalysis.reading.read60"},
        {min:6.1,max:6.5,score:6.5,analysis:"scoreToAnalysis.reading.read65"},
        {min:6.6,max:7,score:7,analysis:"scoreToAnalysis.reading.read70"},
        {min:7.1,max:7.5,score:7.5,analysis:"scoreToAnalysis.reading.read75"},
        {min:7.6,max:8,score:8,analysis:"scoreToAnalysis.reading.read80"},
        {min:8.1,max:8.5,score:8.5,analysis:"scoreToAnalysis.reading.read85"},
        {min:8.6,max:9,score:9,analysis:"scoreToAnalysis.reading.read90"}
    ],
    listening:[
        {min:0,max:2.5,score:2.5,analysis:"scoreToAnalysis.listening.listen25"},
        {min:2.6,max:3,score:3,analysis:"scoreToAnalysis.listening.listen30"},
        {min:3.1,max:3.5,score:3.5,analysis:"scoreToAnalysis.listening.listen35"},
        {min:3.6,max:4,score:4,analysis:"scoreToAnalysis.listening.listen40"},
        {min:4.1,max:4.5,score:4.5,analysis:"scoreToAnalysis.listening.listen45"},
        {min:4.6,max:5,score:5,analysis:"scoreToAnalysis.listening.listen50"},
        {min:5.1,max:5.5,score:5.5,analysis:"scoreToAnalysis.listening.listen55"},
        {min:5.1,max:6,score:6,analysis:"scoreToAnalysis.listening.listen60"},
        {min:6.1,max:6.5,score:6.5,analysis:"scoreToAnalysis.listening.listen65"},
        {min:6.6,max:7,score:7,analysis:"scoreToAnalysis.listening.listen70"},
        {min:7.1,max:7.5,score:7.5,analysis:"scoreToAnalysis.listening.listen75"},
        {min:7.6,max:8,score:8,analysis:"scoreToAnalysis.listening.listen80"},
        {min:8.1,max:8.5,score:8.5,analysis:"scoreToAnalysis.listening.listen85"},
        {min:8.6,max:9,score:9,analysis:"scoreToAnalysis.listening.listen90"}
    ],
    speaking:[
        {min:0,max:1,score:1,analysis:"scoreToAnalysis.speaking.speak10"},
        {min:1.1,max:2,score:2,analysis:"scoreToAnalysis.speaking.speak20"},
        {min:2.1,max:3,score:3,analysis:"scoreToAnalysis.speaking.speak30"},
        {min:3.1,max:3.5,score:3.5,analysis:"scoreToAnalysis.speaking.speak35"},
        {min:3.6,max:4,score:4,analysis:"scoreToAnalysis.speaking.speak40"},
        {min:4.1,max:4.5,score:4.5,analysis:"scoreToAnalysis.speaking.speak45"},
        {min:4.6,max:5,score:5,analysis:"scoreToAnalysis.speaking.speak50"},
        {min:5.1,max:5.5,score:5.5,analysis:"scoreToAnalysis.speaking.speak55"},
        {min:5.1,max:6,score:6,analysis:"scoreToAnalysis.speaking.speak60"},
        {min:6.1,max:6.5,score:6.5,analysis:"scoreToAnalysis.speaking.speak65"},
        {min:6.1,max:7,score:7,analysis:"scoreToAnalysis.speaking.speak70"},
        {min:7.1,max:7.5,score:7.5,analysis:"scoreToAnalysis.speaking.speak75"},
        {min:7.6,max:8.0,score:8.0,analysis:"scoreToAnalysis.speaking.speak80"},
        {min:8.1,max:8.5,score:8.5,analysis:"scoreToAnalysis.speaking.speak85"},
        {min:8.6,max:9.0,score:9.0,analysis:"scoreToAnalysis.speaking.speak90"}
    ],
    overall:[
        {min:0,max:2.5,score:2.5,analysis:"scoreToAnalysis.overall.overall25"},
        {min:2.6,max:3,score:3,analysis:"scoreToAnalysis.overall.overall30"},
        {min:3.1,max:3.5,score:3.5,analysis:"scoreToAnalysis.overall.overall35"},        
        {min:3.6,max:4,score:4,analysis:"scoreToAnalysis.overall.overall40"},
        {min:4.1,max:4.5,score:4.5,analysis:"scoreToAnalysis.overall.overall45"},
        {min:4.6,max:5,score:5,analysis:"scoreToAnalysis.overall.overall50"},
        {min:5.1,max:5.5,score:5.5,analysis:"scoreToAnalysis.overall.overall55"},
        {min:5.1,max:6,score:6,analysis:"scoreToAnalysis.overall.overall60"},
        {min:6.1,max:6.5,score:6.5,analysis:"scoreToAnalysis.overall.overall"},
        {min:6.1,max:7,score:7,analysis:"scoreToAnalysis.overall.overall"},
        {min:7.1,max:7.5,score:7.5,analysis:"scoreToAnalysis.overall.overall"},
        {min:7.6,max:8.0,score:8.0,analysis:"scoreToAnalysis.overall.overall"},
        {min:8.1,max:8.5,score:8.5,analysis:"scoreToAnalysis.overall.overall"},
        {min:8.6,max:9.0,score:9.0,analysis:"scoreToAnalysis.overall.overall"}
    ]
} 

