import React, { useEffect, useRef, useState } from "react";
import { getRank } from "../../../services/assessmentTestService";
import ReactPaginate from "react-paginate";
import moment from "moment-timezone";
import "./index.scss"
const Rank=()=>{
    const [dataRank,setDataRank] = useState([])
    const statusGetdata = useRef(true)
    const [pageSize,setPageSize] = useState(10)
    const [page,setPage] = useState(1)

    const getData = async ()=>{
        const param = [
            `type=Full`,
            `Page=${page}`,
            `PageSize=${pageSize}`,
            // `Filters[0].operator`,
            // `Filters[0].value`,
            // `Filters[0].Field`
        ]
        const result = await getRank(param)
        if(result.isSuccess) setDataRank(result.data)
    }
    const handlePageSizeClick = (val) => {
        setPageSize(val)
        statusGetdata.current=true
    };
    const handlePageClick = (event) => {
        setPage(event.selected+1)
        statusGetdata.current=true
    };
    useEffect(()=>{
        if(statusGetdata.current){
            statusGetdata.current=false
            getData()
        }
    },[pageSize,page])
    return(
        <>
            <div className="row ranking">
                    <p className="title" >RANKING</p>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">TOP</th>
                                <th scope="col">Username</th>
                                <th scope="col">Total Score</th>
                                <th scope="col">Listening</th>
                                <th scope="col">Reading</th>
                                <th scope="col">Speaking</th>
                                <th scope="col">Writing</th>
                                <th scope="col">Overall</th>
                                <th scope="col">Date</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataRank?.items?.map((e,i)=>{
                                    return(
                                        <tr key={i}>
                                            <td>{((page-1)*pageSize)+i+1}</td>                               
                                            <td>{e.userDisplayName ?? e.userUserName}</td>
                                            <td className="color-ant"><b>IELTS {e.totalPoints}</b></td>
                                            {/* <td><b>{e.listeningPoints}</b><br/> <i>{e.listeningTriPoints}</i></td> */}
                                            {/* <td><b>{e.readingPoints}</b><br/> <i>{e.readingTriPoints}</i></td> */}
                                            {/* <td><b>{e.speakingPoints}</b><br/> <i>{e.speakingTriPoints}</i></td> */}
                                            {/* <td><b>{e.writingPoints}</b><br/> <i>{e.writingTriPoints}</i></td> */}
                                            <td>
                                                {
                                                    (e.testType =="Full" || e.testType =="Short") &&  <>
                                                        <b>{e.listeningPoints > 0 ? (e.listeningPoints)?.toFixed(1) : 0}</b><br/> <i>{e.listeningTriPoints}</i>
                                                    </>
                                                }
                                            </td>
                                            <td>
                                                {
                                                    (e.testType =="Full" || e.testType =="Short") && <>
                                                        <b>{e.readingPoints > 0 ? (e.readingPoints)?.toFixed(1) : 0}</b><br/> <i>{e.readingTriPoints}</i>
                                                    </>
                                                }
                                            </td>
                                            <td><b>{e.speakingPoints > 0 ? (e.speakingPoints)?.toFixed(1) : 0}</b><br/> <i>{e.speakingTriPoints>0 ?e.speakingTriPoints:"" }</i></td>
                                            <td>
                                                {e.writingPoints ?
                                                    <><b>{e.writingPoints > 0 ? (e.writingPoints)?.toFixed(1) : 0}</b><br/> <i>{e.writingTriPoints}</i></>
                                                :
                                                    <i className="color-ccc">"Updating"</i>
                                                }
                                                
                                            </td>
                                            <td>{e.overall > 0 ? (e.overall)?.toFixed(1) : 0}</td>
                                            <td>{moment.utc(e.createdAt).tz("Asia/Ho_Chi_Minh").format('HH:mm')}<br/> {moment(e.createdAt).format(' DD/MM/YYYY')}</td>
                                        </tr>
                                    )
                                })
                            }
                           
                        </tbody>
                    </table>
                </div>
                <div className="row performance-select d-flex justify-content-between m-0 p-0">
                <div className="row performance-select d-flex justify-content-between m-0 p-0">
                    <div className="col-md-4 box-select-row m-0 p-0">
                        <div className="select-row">
                            <div className="btn-select">
                                <select className="form-select" aria-label="select page" value={pageSize} onChange={(e)=>handlePageSizeClick(e.target.value)}>        
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </div>
                            <p>{`Showing rows ${page*pageSize-pageSize + 1} to ${page*pageSize} of ${dataRank?.total}`}</p>
                        </div>
                    </div>
                    {
                        dataRank?.items?.length >=1 &&  
                        <div className="col-md-6 box-pagination m-0 p-0">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                pageCount={Math.ceil(dataRank.total / pageSize)}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                containerClassName="paginate"
                                pageLinkClassName="page-num"
                                previousLinkClassName="page-num"
                                nextLinkClassName="page-num"
                                activeLinkClassName="active"
                            />
                        </div>
                    }                                       
                </div>
                   
                </div>
        </>
    )
}

export default Rank