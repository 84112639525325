import React, { useEffect, useState } from "react";
import "./index.scss"

const QuestionSelectAnswer = ({data,handleAnswer})=>{
    const [selected,setSelected] = useState()
    const handleOnclick = (id,key,value)=>{
        setSelected(value);
        handleAnswer(id,key)
    }
    // console.log("QuestionSelectAnswer rerender",selected)
    // useEffect(()=>{

    // },[selected])
    return(
        <>
        <div className="question question-select-answer"  id={`question-${data?.id}`}>
            <div className="row">
                <div className="col-md-6">
                    <div className="question-name" >
                        <p dangerouslySetInnerHTML={{ __html: `${data.content}`}}></p>
                    </div>
                    {
                        data.materials?.map((tj)=>{
                            if(tj.materialType ==='mp3') return (
                                    <div key={tj.id} className="question-audio" >
                                        <audio className="w-100" src={process.env.REACT_APP_BE_HOST+tj.url}  controls="controls"  ></audio>
                                    </div>
                            )
                        })
                    }
                </div>
                <div className="col-md-6">
                    <div className="list-answer">
                        <div className="dropdown">
                            <div className="btn-group">
                                <button type="button" className="btn btn-default btn-select" data-bs-toggle="dropdown" aria-expanded="false">
                                    {selected ? selected : "" }<i className="fa fa-angle-down" aria-hidden="true"></i>
                                </button>
                                <ul className="dropdown-menu">
                                    {
                                        data.answerExams?.map((e)=>{
                                            // console.log(e)
                                            return(
                                                <li className="dropdown-item" key={e.id} onClick={()=>handleOnclick(data?.id,e.id,e.content)}>{e.content}</li>
                                            )
                                        })
                                    }
                                </ul>
                            </div>

                        </div>

                    </div>
                </div>
            </div>
        </div>
        </>
    )
}
export default QuestionSelectAnswer