import React from "react"

// import '../styles/spinner.css'
const Spinner = () => {
  return (
    <>
    <div className="fade modal-backdrop show"></div>
    <div className="modal fade show" id="loading" data-backdrop="static" data-keyboard="false" style={{display:'block'}}>
      <div className="modal-dialog modal-dialog-centered justify-content-center" role="document">
        <div className="d-flex justify-content-center">
          <div className="spinner-border" style={{width:'5rem',height:'5rem',color:'#fff'}} role="status" >
          </div>
        </div>
      </div>
    </div>
    </>
  )
}

export default Spinner
