// Learn more about users at https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/users.html.

import CommentsAdapter from "./CommentsAdapter"
import MyUploadAdapter from "./MyUploadAdapter"

// interface IComment {
//   commentId: string,
//   authorId: string,
//   content: string,
//   createAt: Date,
//   attributes: object,
// }

// interface ICommentThreadContext {
//   type: string,
//   value: String,
// }

// interface ICommentThread {
//   threadId: string,
//   comments: IComment[],
//   context: ICommentThreadContext,
//   unlinkedAt: Date,
//   resolvedAt: Date,
//   resolvedBy: string,
//   attributes: object
// }

const createUserPlugins = ({ id, name }) => {
  /**
   * Không thể dùng arrow function thay cho pure function (Không rõ nguyên nhân)
   * @param {*} editor
   */
  return function (editor) {
    const users = editor.plugins.get('Users')
    // Provide user data from your database.
    users.addUser({ id, name })
  }
}

const createUserMePlugins = ({ id, name }) => {
  return function (editor) {
    //Load UserPlugin
    const users = editor.plugins.get('Users')
    // Provide user data from your database.
    //Thêm user vào UserPlugin
    users.addUser({ id, name })
    //Add thêm nếu cần
    //users.addUser({ id, name })
    //users.addUser({ id, name })

    // Define the local user. Bắt buộc pahir định nghĩa ít nhất 1 thằng là tôi (DefineMe)
    users.defineMe(id)
  }
}

const createImageUploadPlugins = (editor) => {
  return function (editor) {
    editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
      // Configure the URL to the upload script in your back-end here!
      return new MyUploadAdapter(loader)
    }
  }
}

const createCommentPlugins = () => {
  return function (editor) {
    editor.plugins.get('CommentsRepository').createUploadAdapter = (editor) => {
      // Configure the URL to the upload script in your back-end here!
      return new CommentsAdapter(editor)
    }
  }
}

const ckeditor5Helpers = {
  createUserPlugins,
  createImageUploadPlugins,
  createCommentPlugins,
  createUserMePlugins,
}

export default ckeditor5Helpers
