import { useReactMediaRecorder } from "react-media-recorder";
import React, { useEffect, useRef, useState } from "react";
import "./recordViewmb.scss"
import Recorder from "./Recoder"
import LcatApi from "../../../../services/Lcat";
import { uploadFile } from "../../../../services/ieltsService";

const RecordViewMb = (props) => {
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(false);
  const [counter, setCounter] = useState(props?.timeRecord?? 45);
  const canvasRef = useRef(null)

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        let computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        let computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);

        setCounter((counter) => counter - 1);
        if(isActive && counter <= 0 ){
          console.log("stop")
          stopTimer()
          stopRecording();
        }
      }, 1000);
    }

    return () => clearInterval(intervalId);
  }, [isActive, counter]);

  function stopTimer() {
    setIsActive(false);
    // setCounter(90);
    setSecond("00");
    setMinute("00");
  }

  const visualize = (analyser)=> {
    const canvas = canvasRef.current;
    const canvasCtx = canvas.getContext("2d");
    let WIDTH = canvas.width;
    let HEIGHT = canvas.height;

    if (!analyser) return;
      analyser.fftSize = 2048;
      let bufferLength = analyser.fftSize;
      let dataArray = new Uint8Array(bufferLength);
      canvasCtx.clearRect(0, 0, WIDTH, HEIGHT);
      let draw = function() {

          let drawVisual = requestAnimationFrame(draw);

          analyser.getByteTimeDomainData(dataArray);

          canvasCtx.fillStyle = 'rgba(0, 240, 255, 0.15)';
          canvasCtx.fillRect(0, 0, WIDTH, HEIGHT);

          canvasCtx.lineWidth = 2;
          canvasCtx.strokeStyle = '#FFFFFF';

          canvasCtx.beginPath();

          let sliceWidth = WIDTH * 1.0 / bufferLength;

          let x = 0;

          for(let i = 0; i < bufferLength; i++) {

          let v = dataArray[i] / 128.0;
          let y = v * HEIGHT/2;

          if(i === 0) {
              canvasCtx.moveTo(x, y);
          } else {
              canvasCtx.lineTo(x, y);
          }

          x += sliceWidth;
          }

          canvasCtx.lineTo(canvas.width, canvas.height/2);
          canvasCtx.stroke();
      };
      draw();
  }

  const startRecording = ()=>{    
    Recorder.start((event) => {
      console.log("start",event)
      if(event.stream && event.analyser  != null){
        console.log("is recording")
        visualize(event.analyser)
        setIsActive(!isActive);
      }else{
        alert("Không connect được micro, hãy kiểm tra lại và reload lại trang!");
      }
    });    
  }

  const stopRecording =()=>{
    Recorder.stop( async (event) => {
      console.log("recorder success: ", event);
      visualize(null)
      // const fileReader = new FileReader();
      // fileReader.readAsDataURL(event.blob);
      // fileReader.onload = async () => {   
      //   props.handleRecord(fileReader.result)                
      // };
      // fileReader.onerror = (error) => {
      //   console.log('convert base 64 error',error);
      // };

      let file = new File([event.blob],`${(Math.random() * 1000).toString()}.mp3`,{type: "audio/mp3"});
      try {
        const result = await  uploadFile(file,'media')
        // console.log('----result----',result)
        props.handleRecord({data:result.data,status:true})
      } catch (error) {
        console.log('Upload file error',error);
        props.handleRecord({status:false,data:null})
      }            
    });
  }

  return (
    <div className={`box-recorder ${props?.active ? "active" : ""}`}>
      <canvas ref={canvasRef} id="mycanvas" className="w-100 h-100"></canvas>
      <div className="recorder-footer">
        <ul>
          <li className="count-time">
          { isActive && 
            <>
            <span className="minute">{minute}</span>
            <span>:</span>
            <span className="second">{second}</span>
            </>
          }
          </li>
          <li>
            {
              isActive ? 
                <div className="btn-record btn-stop"
                  onClick={() => {
                    stopTimer()
                    stopRecording();
                    // pauseRecording();
                  }}
                >
                  <i className="fa fa-stop fa-2x" aria-hidden="true"></i>
                </div>
              :
                <div className="btn-record btn-play"
                  onClick={() => {
                    if (!isActive) {
                      startRecording();
                    } else {
                      // pauseRecording();
                    }    
                    // setIsActive(!isActive);
                  }}
                >
                  <i className="fa fa-play" aria-hidden="true"></i>
                </div>
            }
          </li>
          <li>
            {/* { 
              isActive && <div className="btn-pause"
                onClick={() => {
                  if (!isActive) {
                    startRecording();
                  } else {
                    // pauseRecording();
                  }  
                  setIsActive(!isActive);
                }}
              >
                <i className="fa fa-pause" aria-hidden="true"></i>
              </div>
            } */}
          </li>
        </ul>
      </div>
     
    </div>
  );
};
export default RecordViewMb;
