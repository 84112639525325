import React, { useState } from "react";
import "./index.scss"
import { useNavigate } from "react-router-dom";
import { changePassword, getUser, putUser, updateGoals } from "../../../services/userService";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const MyAccount = ()=>{
    const [editStatus,setEditStatus] = useState(false)
    const [editInputStatus,setEditInputStatus] = useState(false)
    const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {};
    const [firstName,setFirstName] = useState(userInfo?.firstName ? userInfo?.firstName : "")
    const [lastName,setLastName] = useState(userInfo?.lastName ? userInfo?.lastName : "")
    const [phoneNumber,setPhoneNumber] = useState(userInfo?.phoneNumber ? userInfo?.phoneNumber : "")
    const [dob,setDob] = useState()
    const [targetGoal,setTargetGoal] = useState()
    // const [lastName,setLastName] = useState()
    const [newPassword,setNewPassword] = useState()
    const [currentPassword,setCurrentPassword] = useState()
    const [startDate, setStartDate] = useState(new Date());

    const handleEditUser = async ()=>{        
        let statusUpdate = true
        if(firstName || lastName || phoneNumber ||dob){
            const parmUser = {
                firstName:firstName ? firstName : userInfo.userInfo,
                lastName:lastName ? lastName : userInfo.lastName,
                phoneNumber:phoneNumber ? phoneNumber : userInfo.phoneNumber,
                displayName: lastName +" "+ firstName,
                dob:dob ? moment(dob).format('YYYY/MM/DD') : userInfo.dob,
                avatar: userInfo.avatar,
            }
           const resultPutUser = await putUser(parmUser);
           if(!resultPutUser.isSuccess) statusUpdate = false
        }
        if(newPassword && currentPassword){
            const param = { currentPassword,newPassword}
            const result = await changePassword(param);
           if(!result.isSuccess) statusUpdate = false
        }
        if(statusUpdate){
            alert("Update Information Success")
            const userinfo = await getUser();
            localStorage.setItem('userInfo',JSON.stringify(userinfo.data))
            setEditStatus(false)
            setEditInputStatus(false)
        }else{
            alert("Update Information Error")
        }               
    }
    const handleEditGoal = async ()=>{        
        let statusUpdate = true
        if(firstName || lastName || phoneNumber ||dob){
            const parmGoal = {
                goalType:userInfo.goals[0]?.goalType,
                currentGoal: userInfo.goals[0]?.currentGoal,
                targetGoal: targetGoal ? targetGoal : userInfo.goals[0]?.targetGoal,
            }
           const resultUpdateGoals = await updateGoals(parmGoal);
           if(!resultUpdateGoals.isSuccess) statusUpdate = false
        }
        if(statusUpdate){
            alert("Update Goal Success")
            const userinfo = await getUser();
            localStorage.setItem('userInfo',JSON.stringify(userinfo.data))
            setEditStatus(false)
            setEditInputStatus(false)
        }else{
            alert("Update Goal Error")
        }               
    }
    
    return(
        <div className="row my-account d-flex justify-content-center w-100">
            <div className="mt-5">
                <div className="row box-information flex-column">
                    <div className="col-md-6 ">
                        <div className="form-group">
                            <p className="color-ant h3"><b>Personal Information</b> 
                            {
                                !editStatus && <span className="edit-btn" onClick={()=>setEditStatus(true)}><img src="/images/edit.png" width={30} /></span>
                            }                            
                            </p>
                        </div>
                        <div className="form-group mt-3">
                            <label >Last Name & Middle Name {editStatus?"*":""}</label>
                            <input type="text" className="form-control" defaultValue={userInfo?.lastName} disabled = {!editStatus} onChange={(e)=>setLastName(e.target.value)}/>
                        </div>
                        <div className="form-group mt-3">
                            <label >First Name {editStatus?"*":""}</label>
                            <input type="text" className="form-control" defaultValue={userInfo?.firstName} disabled = {!editStatus} onChange={(e)=>setFirstName(e.target.value)} />
                        </div>
                        <div className="form-group mt-3">
                            <label >Username/Email </label>
                            <input type="text" className="form-control cursor-not-allowed" defaultValue={userInfo?.email} disabled />
                        </div>
                        <div className="form-group mt-3">
                            <label >Date of Birth {editStatus?"*":""}</label>
                            {
                                editStatus?
                                <DatePicker 
                                    dateFormat="yyyy/MM/dd"
                                    selected={dob ? dob : userInfo.dob ? new Date(userInfo.dob) : null}
                                    onChange={(date) => setDob(date)} 
                                    openToDate={userInfo?.dob ? new Date(userInfo?.dob) : new Date()}
                                    className="form-control"
                                />
                                :
                                <input type="text" className="form-control" defaultValue={userInfo?.dob} disabled = {!editStatus} />
                            }
                            
                        </div>
                        <div className="form-group mt-3">
                            <label >Phone Number {editStatus?"*":""}</label>
                            <input type="number" className="form-control" defaultValue={userInfo?.phoneNumber} disabled = {!editStatus} onChange={(e)=>setPhoneNumber(e.target.value)}/>
                        </div>
                       
                        {
                            editStatus && <>
                                <div className="form-group mt-3">
                                    <label >Current Password {editStatus?"*":""}</label>
                                    <input type="password" className="form-control"  disabled = {!editStatus} onChange={(e)=>setCurrentPassword(e.target.value)} />
                                </div>
                                <div className="form-group mt-3">
                                    <label >New Password {editStatus?"*":""}</label>
                                    <input type="password" className="form-control"  disabled = {!editStatus} onChange={(e)=>setNewPassword(e.target.value)} />
                                </div>
                                <div className="form-group mt-3">
                                    <button className="btn btn-default" onClick={()=>setEditStatus(false)}>Cancell</button>
                                    <button className="btn btn-default bg-color-ant color-black" onClick={handleEditUser}>Save</button>
                                </div>
                            </>
                           
                        }
                        
                    </div>
                    <div className="col-md-6 mt-5">
                        <div className="form-group">
                            <p className="color-ant h3"><b>Input Information</b>
                            {
                                !editInputStatus && <span className="edit-btn" onClick={()=>setEditInputStatus(true)}><img src="/images/edit.png" width={30} /></span>
                            }  
                            </p>
                        </div>
                        <div className="form-group mt-3">
                            <label >Type of the test you’re practicing for IELTS</label>
                            <input type="text" className="form-control cursor-not-allowed" defaultValue={userInfo?.goals[0]?.goalType} disabled  />
                        </div>
                        <div className="form-group mt-3">
                            <label >Goal*</label>
                            <input type="text" className="form-control" defaultValue={userInfo?.goals[0]?.targetGoal} disabled = {!editInputStatus} onChange={(e)=>setTargetGoal(e.target.value)} />
                        </div>
                        <div className="form-group mt-3">
                            <label >Account Types</label>
                            <input type="text" className="form-control cursor-not-allowed"defaultValue={userInfo?.roles[0]}  disabled />
                        </div>     
                        {
                            editInputStatus && <>
                                <div className="form-group mt-3">
                                    <button className="btn btn-default" onClick={()=>setEditInputStatus(false)}>Cancell</button>
                                    <button className="btn btn-default bg-color-ant color-black" onClick={handleEditGoal}>Save</button>
                                </div>
                            </>
                           
                        }                   
                    </div>
                </div>
                
            </div>
        </div>
    )
}

export default MyAccount