import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
import HomeMenu from '../Layout/Menu/Home'
import "react-circular-progressbar/dist/styles.css";
import ShortTestResult from './shortTestResult';
import FullTestResult from './fullTestResult';

const ResultAnalysis = () => {
    // const token = localStorage.getItem('token')
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )

    return (
        <>   
            
            <div className='container-fluid result-analysis p-0' id="resultAnalysis">
                <HomeMenu/>
                <div className='intro '>
                    <div className='container d-flex align-items-center justify-content-center'>
                        <div className='col-md-10 mt-5' >
                        { 
                           userInfo?.id ? 
                                <FullTestResult
                                /> 
                           :  
                                <ShortTestResult
                                    
                                />
                           }

                        </div>
                                               
                    </div>
                </div>                
            </div>
            
        </>
    )
}
export default ResultAnalysis