import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { WritingQuestionShorttest } from "../../constants/quiz";
import { WritingQuestion } from "../../constants/fulltest";
import Writing from "../PresenTations/Writing";
import { isDesktop} from "react-device-detect";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";

const WritingPage=()=>{
    const navigate = useNavigate();
    const skill = localStorage.getItem("skill")
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const nextSkill =()=>{
        navigate("/speaking",{ replace: true })
    }
    useEffect(()=>{
        localStorage.removeItem('WritingAnswer')
    },[])
    return(
        <>            
            <div className='content-ielts-wrapper' style={{backgroundImage: isDesktop?  darkMode =="light" ? `url("/images/light/bg-test.png")` :"" : ""}}>
                {!isDesktop && <HeaderMB content={"Writing"} nextSkill={nextSkill} section={0} />}
                <Writing nextSkill={nextSkill} />
            </div> 
        </>
    )
}
export default WritingPage