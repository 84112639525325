import React, { useEffect, useState } from "react";
import SingleChoice from "../share/SingleChoice";
import { isDesktop} from "react-device-detect";

const GrammarAndReading = (props)=>{
    const [data,setData] = useState()
    const [totalQuestion,setTotalQuestion] = useState([])
    const [listAnswered,setListAnsered]= useState(new Map())
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const handleAnswer =(questionId,value)=>{        
        let stgAnswer = localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map();
        stgAnswer.set(
            questionId, 
            {
                questionId,answerContent:value
            }
        );
        setListAnsered(stgAnswer);
        localStorage.setItem('answer',JSON.stringify(Object.fromEntries(stgAnswer)))
    }
    const getData = async ()=>{
        const newdata =  JSON.parse(localStorage.getItem('exam'))?.questionContent
        // const newdata = JSON.parse(questionContent)
        let content = newdata.languageSkillExams.filter(e => e.languageSkillType ==='Reading');
        setData(content[0].partitionExams[0])

        let arrQuestion = [];
        content[0].partitionExams[0]?.sectionExams?.map((e)=>{
            e.groupQuestionExams?.map((i)=>{
                i.questionExams?.map((j)=>{
                    let newPoint = j.points ?? 1;
                    for (let index = 0; index < newPoint; index++) {
                        arrQuestion.push(j.id)                    
                    }
                })
            })
        })
        setTotalQuestion(arrQuestion)
    }
    useEffect(()=>{
          getData();
    },[])
    
    return(
        <>
            <div  id="pronunciation">
                <div className="justify-content-center align-items-center ">
                    <div className="container-fluid wrapper-content">
                        <div className="container">
                            <div className={`ielts-wrapper-content ${darkMode == "dark" ? "dark-mode" :" light-mode"}`}>
                                {
                                    data?.sectionExams?.map((e)=>{
                                        return e.groupQuestionExams?.map((i)=>{
                                            return  i.questionExams?.map((j)=>{
                                                return  <SingleChoice key={j.id} data={j}  answerHandle={handleAnswer}/>                                             
                                            })
                                        })
                                    })
                                }                                                        
                            </div>
                        </div>
                    </div>
                    {/* {
                        isDesktop &&
                        <div className="ielts-wrapper-footer">
                            <div className="container h-100">
                                <div className="row h-100">
                                    <div className="col-md-8 p-0 d-flex align-items-center h-100">
                                        <ul className="list_question p-0 m-0">
                                            {
                                                totalQuestion.map((e,i)=>{
                                                    return(
                                                        <li  className={listAnswered.get(e) ?"answered":""} key={i}><a href={`#question-${i+1}`}>{i+1}</a></li>
                                                    )
                                                })
                                                
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="row h-50">
                                            <div className="d-flex align-items-end justify-content-center btn-next-skill" onClick={()=>props.nextSkill(2)}>Go to next skill <i className="fa fa-angle-double-right" aria-hidden="true"></i></div> 
                                        </div>
                                        <div className="row h-50 guide-question ">
                                            <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                                                <li><div className="circle " ></div></li>                                 
                                                <li>Unanswered</li>                             
                                                <li><div className="circle circle-answered" ></div></li>                                 
                                                <li>Answered</li>                                 
                                            </ul>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    } */}

                    {
                        isDesktop &&
                        <div className={`ielts-wrapper-footer ${darkMode == "light" ? "background-white" :" background-footer-dark"}`}>
                            <div className="container h-100">
                                <div className="row h-100">
                                    <div className="col-md-8 p-0 d-flex align-items-center h-100">
                                        <ul className="list_question p-0 m-0">
                                            {
                                                totalQuestion.map((e,i)=>{
                                                    return <li className={listAnswered.get(e) ? darkMode =="light"? "answered border-3060CF background-3060CF" :"answered border-00F0FF background-00F0FF"  : darkMode =="light"? "border-black " :" border-white"}  key={i}><a className={darkMode =="light" ? listAnswered.get(e) ?"color-white" : "color-black":"color-white"} href={`#question-${e}`}>{i+1}</a></li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className="col-md-4">
                                        <div className={darkMode =="light" ?"row h-50 color-black" : "row h-50 color-white"}>
                                            <div className={darkMode =="light" ? "d-flex align-items-end justify-content-center btn-next-skill-light"  : "d-flex align-items-end justify-content-center btn-next-skill"  } onClick={()=>props.nextSkill(2)}>Go to next skill <i className="fa fa-angle-double-right" aria-hidden="true"></i></div> 
                                        </div>
                                        <div className={darkMode == "light" ? "row h-50 guide-question color-black ":"row h-50 guide-question color-white"}>
                                            <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                                                <li><div className={darkMode=="light"?"circle border-black":"circle border-white"} ></div></li>                                 
                                                <li>Unanswered</li>                             
                                                <li><div className={darkMode=="light"?"circle circle-answered border-3060CF background-3060CF":"circle circle-answered border-00F0FF background-00F0FF"}></div></li>                                 
                                                <li>Answered</li>                                 
                                            </ul>
                                        </div>                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </>
    )
}

export default GrammarAndReading