import React from "react";
import "./indexLight.scss"
import MenuLight from "../Layout/Menu/MenuLight";
import { useTranslation } from "react-i18next";
import { checkUser, fqaSystem } from "../../services/userService";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import Footer from "../Layout/Footer";
import AiSpeakingModal from "../ComponentShare/Modal/AiSpeakingModal";
import { useSearchParams } from 'react-router-dom';

const HomeLight = () => {  
    const { t } = useTranslation();
    const navigate = useNavigate();
    const {register, handleSubmit, formState:{errors}} = useForm();
    const [searchParams, setSearchParams] = useSearchParams();

    const handleclicktest = (skill)=>{
        localStorage.setItem("skill",skill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }

    const handleClickSpeaingTest = async (subSkill)=>{
        
        try {
            const result = await checkUser()
        } catch (error) {
            navigate("/auth/login")
            return
        }            
        localStorage.setItem("skill",'/speakingai')
        localStorage.setItem("subSkill",subSkill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }
    const handleRegistration= async (data) =>{
        const resultRegister = await fqaSystem({...data,source:process.env.REACT_APP_DOMAIN,title:""});
        if(resultRegister?.isSuccess) return alert("Gửi thông điệp thành công")
        return alert("Gửi thông điệp thất bại")
    }
    const registrationOptions = {
        fullname:{required: "FullName is required"},
        email:{required: "Email is required"},
        phone:{required: "SĐT is required"}
    }
    return(
        <div className='container-fluid home-light' id="homeLight">
            {
               searchParams.get("test")=="speakingai" &&  <AiSpeakingModal handleClickTest={handleClickSpeaingTest} />
            }
            <div className="header" >
                <MenuLight />
                <div className="head-content">  
                    <p className="main-content">VƯƠN XA HƠN VỚI</p>
                    <p className="main-content">HỘ CHIẾU TOÀN CẦU</p>
                    <p className="sub-content">Kiểm tra trình độ tiếng Anh của bạn cùng Trí tuệ Nhân tạo</p>
                </div>
            </div>
            <div className="content-light">

            
                <section className='about-us  '  id="about">
                    <h2 className="title text-center"> Về Chúng Tôi </h2>
                    <div className="list-card">
                        <div className="row-card">
                            <div className="box-card">
                                <a href="https://therealielts.vn/ve-phuong-phap-hoc/" target="_blank" >    
                                    <div className="card" >
                                        <img className="card-img-top" src="/images/light/1.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Công nghệ giảng dạy</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="box-card">
                                <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">    
                                    <div className="card card-height"  >
                                        <img className="card-img-top" src="/images/light/2.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Đội ngũ nhân sự</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="box-card">
                                <a href="https://therealielts.vn/ve-phuong-phap-hoc/" target="_blank">
                                    <div className="card" >
                                        <img className="card-img-top" src="/images/light/3.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Phương pháp độc quyền</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div>
                        <div className="row-card">
                            <div className="box-card">
                                <a href="https://ant-edu.vn/san-pham/" target="_blank">
                                    <div className="card card-height" >
                                        <img className="card-img-top" src="/images/light/4.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Cam kết văn bản</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            {/* <div className="box-card ">
                                <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">    
                                    <div className="card" >
                                        <img className="card-img-top" src="/images/light/5.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Xây dựng cộng đồng</p>
                                        </div>
                                    </div>
                                </a>
                            </div> */}
                            {/* <div className="box-card">
                                <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">
                                    <div className="card card-height" >
                                        <img className="card-img-top" src="/images/light/6.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Hợp tác sản phẩm</p>
                                        </div>
                                    </div>
                                </a>
                            </div> */}
                             <div className="box-card">
                                <a href="https://ant-edu.vn/tin-tuc-va-su-kien/" target="_blank">
                                    <div className="card" >
                                        <img className="card-img-top" src="/images/light/7.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Sự kiện & truyền thông</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="box-card ">
                                <a href="https://khaothi.ant-edu.ai/" target="_blank">
                                    <div className="card card-height" >
                                        <img className="card-img-top" src="/images/light/8.png" alt="Card image cap" />
                                        <div className="card-body">
                                            <p className="card-text">Luyện thi & khảo thí</p>
                                        </div>
                                    </div>
                                </a>
                            </div>
                        </div> 
                        {/* <div className="row-card">
                            <div className="row-card-2">
                                <div className="box-card">
                                    <a href="https://ant-edu.vn/ve-ant-edu/" target="_blank">
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/light/7.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Sự kiện & truyền thông</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                                <div className="box-card ">
                                    <a href="https://khaothi.ant-edu.ai/" target="_blank">
                                        <div className="card" >
                                            <img className="card-img-top" src="/images/light/8.png" alt="Card image cap" />
                                            <div className="card-body">
                                                <p className="card-text">Luyện thi & khảo thí</p>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            </div>
                        
                        </div>  */}
                    </div>
                </section>

                <section className="input-test-light" id="inputTest">
                    <div className="input-test-title"><b>AI SPEAKING TEST</b></div>
                    <div className="list-test">
                        <div className="card">
                            <div className="card-body">
                                <div className="card-top" >
                                    <div className="top-left">
                                        <img  src="/images/mb/test1.png"/>
                                    </div>
                                    <div className="top-right">
                                        <p className="top-right-title">
                                            Standard Test
                                        </p>
                                        <p className="top-right-guide">
                                            Bài kiểm tra đầu vào tiêu chuẩn với 3 kỹ năng: Nghe, Đọc, Viết
                                        </p>
                                    </div>
                                </div>
                                <div className="card-bottom" >
                                    <div className="btn-test" onClick={()=>handleclicktest('/shorttest')}>
                                        Short test (15’)
                                    </div>
                                    <div className="btn-test btn-full-test" onClick={()=>handleclicktest('/fulltest')}>
                                        Full Test (60’)
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-top" >
                                    <div className="top-left">
                                        <img  src="/images/mb/test2.png"/>
                                    </div>
                                    <div className="top-right">
                                        <p className="top-right-title">
                                            AI Speaking Test
                                        </p>
                                        <p className="top-right-guide">
                                            Bài kiểm tra năng lực nói tiếng Anh bằng công nghệ AI
                                        </p>
                                    </div>
                                </div>
                                <div className="card-bottom1" >
                                    <div className="btn-test btn-full-test"  data-bs-toggle="modal" data-bs-target="#speakingModal">
                                        Thi ngay
                                    </div>
                                    <div className="modal fade" id="speakingModal" tabIndex={-1} aria-labelledby="speakingModalLabel" aria-hidden="true">
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content">
                                                <div className='row'>
                                                    <div className='col-11 '>
                                                        <h1 className="modal-title fs-5 text-center" id="speakingModalLabel" dangerouslySetInnerHTML={{ __html: t(`home.testlist.speakingtest.lable`)}}></h1>
                                                    </div>
                                                    <div className='col-1 p-0'>
                                                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                    </div>
                                                </div>
                                                <div className='row mt-3'>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickSpeaingTest("basic")}>{t(`home.testlist.speakingtest.selectSkill.basic`)}</button>
                                                    </div>
                                                    <div className='col-6'>
                                                        <button type="button" className="btn btn-outline-primary btn-lg w-100 btn-advance" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickSpeaingTest("advance")} >{t(`home.testlist.speakingtest.selectSkill.advance`)}</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card">
                            <div className="card-body">
                                <div className="card-top" >
                                    <div className="top-left">
                                        <img  src="/images/mb/test1.png"/>
                                    </div>
                                    <div className="top-right">
                                        <p className="top-right-title">
                                            Thi thử
                                        </p>
                                        <p className="top-right-guide">
                                            Bài kiểm tra năng lực nói tiếng Anh bằng công nghệ AI
                                        </p>
                                    </div>
                                </div>
                                <div className="card-bottom1" >
                                    <div className="btn-test btn-full-test" onClick={()=>alert("Coming soon")}>
                                        Thi ngay
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <div className="contact-light" id="contact">
                    <div className="contact-map">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d4972.717486517816!2d105.84164405898206!3d20.97944803181506!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3135ac44766bec2f%3A0xf556d2ced52a3657!2sTrung%20t%C3%A2m%20n%C4%83ng%20khi%E1%BA%BFu%20LPG%20After%20School!5e0!3m2!1svi!2s!4v1716538602778!5m2!1svi!2s"  allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                    <div className="box-contact">
                        <div className="contact-title"><b>Liên hệ với chúng tôi</b></div>
                        <div className="contact-title-sub">Điền vào biểu mẫu, nhóm của chúng tôi sẽ liên hệ lại với bạn trong vòng 24 giờ</div>
                        <form onSubmit={handleSubmit(handleRegistration)}>
                            <div className="row mt-4">
                                <div className="col-md-6 ">
                                    <div className="input-row">
                                        <label htmlFor="fullName">Họ và tên</label><br/>
                                        <small className="text-danger">{errors?.fullname && errors.fullname.message}</small>
                                        <div className="input-group mb-3">
                                            <input type="text" className="form-control" id="fullName" name="fullname" {...register('fullname',registrationOptions.fullname)} placeholder="Nhập tên của bạn tại đây"  />
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="input-row">
                                        <label htmlFor="phone">Số điện thoại</label><br/>
                                        <small className="text-danger">{errors?.phone && errors.phone.message}</small>
                                        <div className="input-group mb-3">
                                            <input type="number" className="form-control" id="phone" name="phone" {...register('phone',registrationOptions.phone)} placeholder="Nhập số điện thoại tại đây"/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                           
                            
                            
                            <div className="input-row mt-4">
                                <label htmlFor="fullName">Email</label><br/>
                                <small className="text-danger">{errors?.email && errors.email.message}</small>
                                <div className="input-group mb-3">
                                    <input type="email" className="form-control" id="email" name="email" {...register('email',registrationOptions.email)} placeholder="Nhập email tại đây" />
                                </div>
                            </div>
                            <div className="input-row mt-4">
                                <label htmlFor="content">Thông điệp</label>
                                <div className="input-group mb-3">
                                    <textarea type="text" rows="4" className="form-control" id="content" name="content" {...register('content')} placeholder="Viết thông điệp của bạn tại đây" ></textarea>
                                </div>
                            </div>
                            <div className="input-row mt-4">
                                <div className="input-group mb-3">
                                    <button className="btn-submit">Gửi Thông Điệp</button>
                                </div>
                            </div>
                        </form>
                        
                    </div>                    
                </div>
            </div>

            <footer className="text-center text-white footer-light">
                <div className="container-fluid">                    
                    <div className='row head'>
                        <div className='col-md-2 box-progress p-0'>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: `100%`}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                        </div>
                        <div className='col-md-8 title'>
                            <p className='text-center'>{t(`home.footer.title`)}</p>
                        </div>
                        <div className='col-md-2 box-progress p-0'>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: `100%`}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                        </div>
                    </div>     

                    <div className='row justify-content-md-center'>
                        <div className='col-md-2 m-2'>   
                            <div className='row mt-3 justify-content-center'>
                                <button className='btn btn-default btn-info'><i className="fa-lg fa-regular fa-envelope"></i>info@ant-edu.vn</button>
                            </div>                                        
                        </div>
                        <div className='col-md-2 m-3 '>   
                            <div className='row mt-1 justify-content-center'>
                                <button className='btn btn-default btn-info'><i className="fa-lg fa-solid fa-phone-volume"></i>092 298 55 55</button>
                            </div>                                        
                        </div>                       
                    </div>
                    <div className='row p-0 info-company mt-5'> 
                        <p className='location text-center' ><i className="fa-solid fa-location-dot fa-lg" />{t(`home.footer.location`)}</p>
                        <p className='location text-center' >{t(`home.footer.address`)}</p>
                    </div>
                    <div className='row mt-3 row-list-icon justify-content-center'>
                        <ul>
                            <li><a target='_blank' href="https://www.facebook.com/realieltsvn" ><i className="fa-lg fab fa-facebook-f" /></a></li>
                            <li><a target='_blank'  href="https://www.instagram.com/english_not_crusty"><i className="fa-lg fab fa-instagram" /></a></li>
                            <li><a target='_blank'  href="https://vt.tiktok.com/ZSdsnqB6S/" ><i className="fa-lg fa-brands fa-tiktok" /></a></li>
                            
                            <li><a target='_blank'  href="mailto:info@ant-edu.vn"><i className="fa-sharp fa-regular fa-envelope"/></a></li>                                
                        </ul>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default HomeLight