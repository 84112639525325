import React, { useEffect, useState } from "react";
import HeaderMB from "../../Layout/Menu/HeaderMB/Header";
import "./indexMb.scss"
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { checkUser, getUser, signIn } from "../../../services/userService";
import { useSearchParams } from 'react-router-dom';

const LoginMB = (props)=>{
    const { t } = useTranslation();
    const [email,setEmail] = useState()
    const [password,setPassword] = useState()
    const [errorC,setErrorC] = useState()
    const [showPassword,setShowPassword] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()  

    const handleSubmit = async (event)=>{
        event.preventDefault();
        try {
            const param = {            
                userName:email,password
            }
            const result = await signIn(param);
            if(result?.data && result.isSuccess){
                localStorage.setItem('token',result.data.token)
                const userinfo = await getUser();
                if(userinfo?.data && result.isSuccess){
                    localStorage.setItem('userInfo',JSON.stringify(userinfo.data))
                    let r = searchParams.get("r")
                    if(r?.length>0) return window.location.replace(r)
                    navigate("/")
                }            
            }else{
                // alert(result.errors[0].message)
                setErrorC(result.errors[0].message)

            }
        } catch (error) {
            console.log(error)
            // alert(error.message)
            setErrorC(error.message)
            // alert(result.errors[0].message)
        }
       
    }
    const checkLogin = async () =>{
        try {
            const result = await checkUser()
            let r = searchParams.get("r")
            if(r?.length>0) return window.location.replace(r)
            return window.location.replace('/');
        } catch (error) {
            localStorage.removeItem("userInfo")
            localStorage.removeItem("token")
        }
       
    };
    useEffect(() => {
        checkLogin()
    }, [])
    return(
        <>
            <div className='container-fluid loginpageMB' id="loginpageMB">
                <HeaderMB content={"Đăng nhập"} />
                <div className="box-img">
                    <img src="/images/mb/haveaniceday.png" />
                    <img src="/images/img-have-a-nice-day.gif" />
                </div>
                <div className="form-login">
                    <form className="row g-3 needs-validation" novalidate onSubmit={handleSubmit}>                       
                        {
                            errorC && <div className="form-group">
                                        <p className="text-center text-danger">{errorC}</p>
                                    </div>
                        }
                        <div className="input-group ">
                            <span className="input-group-text" ><i className="fa-solid fa-envelope icon-prespend "></i></span>
                            <input type="text" className="form-control border-l-none" placeholder="Email" onChange={(e)=>setEmail(e.target.value)} required/>
                        </div>
                        <div className="input-group ">
                            <span className="input-group-text" ><i className="fa-solid fa-lock icon-prespend "></i></span>
                            <input type={showPassword ? "text" : "password"} className="form-control form-control-show border-r-none border-l-none" placeholder= {t(`authPage.loginPage.password`)} onChange={(e)=>setPassword(e.target.value)} required/>
                            <span className="input-group-text" onClick={()=>setShowPassword(!showPassword)}><i className={showPassword ? "fa fa-eye " : "fa fa-eye-slash fa-fw "}></i></span>
                        </div>
                        <div className="input-group">
                            <input className="form-check-input" type="checkbox" id="gridCheck" value="option1" aria-label="..." />
                            <label className="form-check-label" htmlFor="gridCheck">
                                {t(`authPage.loginPage.savePassword`)}
                            </label>
                        </div>
                        <div className="input-group">
                            <button className="btn btn-default btn-auth">
                                {t(`authPage.loginPage.btnLogin`)}
                            </button>
                        </div>
                        <div className="register">                                
                            <p className="form-check-label text-center">
                            <p>{t(`authPage.loginPage.guide`)}<a href={`/auth/register?r=${searchParams.get("r")?? ""}`} className="color-anttest"> <b>{t(`authPage.register.btnRegis`)}</b> </a></p>
                            </p>                               
                        </div>
                        <div className="continue">
                            <div className="progress continue-item">
                                <div className="progress-bar" style={{"width":`100%`}} role="progressbar" aria-valuenow={100} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                            <div className="continue-item2">
                                Hoặc tiếp tục với
                            </div>
                            <div className="progress continue-item">
                                <div className="progress-bar" style={{"width":`100%`}} role="progressbar" aria-valuenow={100} aria-valuemin="0" aria-valuemax="100"></div>
                            </div>
                        </div>
                        <div className="continue-social">
                            <div className="social social-gg" onClick={()=>props.onGoogleClick()}><i className="fa-brands fa-google-plus fa-2xl p-1" ></i> Google</div>
                            <div className="social social-fb" onClick={()=>props.onFacebookClick()}><i className="fa-brands fa-square-facebook fa-2xl p-1" ></i>Facebook</div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}
export default  LoginMB