import httpClient from './httpClient.service';

export const getStartExam = (data)=>{
    return new Promise((resolve, reject) => {
      httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/start-exam`, data)
      .then(res => {        
        if (res.status === 200) {
          resolve(res.data);
        } else {              
          reject(res);
        }
      })
      .catch((e) => {
        reject(e);
      });
    });
}
export const submitExam = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/submit-exam`, data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
export const uploadFile = (file,uri)=>{
  var data = new FormData()
  data.append('file', file)
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/${uri}`, data, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }})
    .then(res => {
      if (res.status === 200) {
          resolve(res.data);
      } else {              
          reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
export const autoCalculatePoint = (id)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/auto-calculate-point/${id}`)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const reviewExamId = (id)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/review-exam/${id}`)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const externalGradingResults = (id)=>{
  return new Promise((resolve, reject) => {
    httpClient.get(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/external-grading-results/${id}`)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const getStartExamByCode = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/start-exam-by-testcode`, data)
    .then(res => {        
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
const ieltsService = {
  getStartExam,submitExam,uploadFile,getStartExamByCode
}
export default ieltsService