import React from "react";
import "./index.scss"
import { useTranslation } from "react-i18next";
import Countdownclock from "../../../PresenTations/Countdownclock";
import { useNavigate } from "react-router-dom";
import { signOut } from "../../../../services/userService";

const MenuUser = (props)=>{
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {};
    const token = localStorage.getItem("token")
    const logout= async ()=>{
        try {
            let result = await signOut()
            navigate('/auth/login',{replace:true})
        } catch (error) {
            alert("logout error!")
        }
          
    }
    return(
        <>
            <nav className="navbar navbar-expand-lg navbar-scroll menuUser" id='menuUser'>
                <div className="container-fluid d-flex justify-content-between">
                    <div className='main-logo d-flex justify-content-center' >
                        {/* <a className="navbar-brand " href="/">
                            <img
                                src="/images/logo.png"
                                className="me-2"
                                height="63"
                                width="73"
                                alt="Logo"
                                loading="lazy"
                            />
                        </a> */}
                    </div>
                    {
                        userInfo && 
                        <div className='box-info' >
                            <div className="dropdown">
                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuUser" data-bs-toggle="dropdown" aria-expanded="false">
                                    <img src={userInfo.avatar? `${process.env.REACT_APP_BE_HOST}${userInfo.avatar}` : `${process.env.REACT_APP_DOMAIN}/images/user.png`} />
                                    {/* { userInfo?.displayName ? userInfo?.displayName : userInfo?.userName} */}
                                    { userInfo?.firstName ? userInfo.firstName : userInfo?.displayName? userInfo.displayName : userInfo?.userName }
                                </button>
                                <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenuUser">
                                    <li><a className="dropdown-item" href="#"><img src="/images/user-icon-blue.png" /> My Account</a></li>
                                    <li onClick={()=>logout()} ><a className="dropdown-item" href="#"><img src="/images/logout.png" /> Log Out</a></li>
                                </ul>
                            </div>
                            <div className="vertical">
                                {/* <div className="vertical-line">

                                </div> */}
                            </div>
                            <div className="notify">
                                <img src="/images/notify.png" />
                            </div>
                        </div>
                    }
                </div>
            </nav>
        </>
    )
}
export default MenuUser