import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { useTranslation } from 'react-i18next';
import { externalGradingResults, reviewExamId } from '../../services/ieltsService';
import { ElsaJson } from '../../constants/elsaJson';
import { scoreToAnalysis } from '../../constants/scoreToAnalysis';
import ErrorResult from '../ComponentShare/ErrorResult';
import WaitingResult from '../ComponentShare/WaitingResult';
import { isDesktop} from "react-device-detect";

const FullTestResult = (props) => {
    const [reviewExam,setReviewExam] = useState()
    const [status,setStatus] = useState(false)
    const [examId,setExamId] = useState()
    const [countGetData,setCountGetData] = useState(0)
    const refcount = useRef(0);

    const { t } = useTranslation();
    const date = new Date();
    const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const refGetDaTa = useRef(false);

    const [skillReport,setSkillReport] = useState("Listening")
    // const [speakingPerformance,setSpeakingPerformance] = useState(1)
    const [speakingData,setSpeakingData] = useState()
    const [speakingQuestionData,setSpeakingQuestionData] = useState([])
    const [speakingAnswer,setSpeakingAnswer] = useState([])

    const analysisContentWithSkill = (skill)=>{
        let score = 0;
        let triScore = 0;
        let detailText;
        // let skillText = 0;
        switch (skill) {
            case "Listening":
                score =reviewExam?.listeningPoints?? 0;
                triScore = reviewExam?.listeningTriPoints?? 0;
                detailText =scoreToAnalysis?.listening?.find(e=>e?.max ==parseFloat(score) ) ?? scoreToAnalysis?.listening[0]

                break;
            case "Reading":
                score = reviewExam?.readingPoints?? 0;
                triScore = reviewExam?.readingTriPoints?? 0;
                detailText = scoreToAnalysis?.reading?.find(e=>e?.max ==parseFloat(score) ) ?? scoreToAnalysis?.reading[0]

                break;
            case "Speaking":
                score = reviewExam?.speakingPoints?? 0;
                triScore = `${reviewExam?.speakingTriPoints ?? 0}/100`;
                detailText =scoreToAnalysis?.speaking?.find(e=>e?.max ==parseFloat(score) ) ?? scoreToAnalysis?.speaking[0]
                break;
            default:
                break;
        }

        return(
            <div className='analysis-content'>
                <div className='score-skill'>
                    <div className='score-skill-title'>
                        {t(`resultAnalysis.resultAnalysisDetail.score`,{skill})} 
                    </div>
                    <div className='score-skill-circle'>
                        <CircularProgressbar
                            value={score}
                            text={`${score}`}
                            background
                            strokeWidth={8}                                
                            styles={buildStyles({
                                backgroundColor: `none`,
                                textColor: "#FFFFFF",
                                pathColor: "#FFFFFF",
                                trailColor: "#060B24",                                                  
                            })}
                        />
                    </div>
                    
                </div>
                <div className='detail-score-skill'>
                    <div className='score-skill-head'>
                        <div className='title me-auto'>
                            {t(`resultAnalysis.resultAnalysisDetail.totalCorect`)}
                        </div>
                        <div className='score'>
                            {triScore}
                        </div>
                    </div>
                    
                    <div className="progress">
                        <div className="progress-bar" role="progressbar" style={{width: `${triScore}%`}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                    </div>
                    <div className="score-analysis">
                        <p className="score-analysis-title">{t(`resultAnalysis.resultAnalysisDetail.analysisScore`,{skill})} </p> 
                        <p className="score-analysis-detail" dangerouslySetInnerHTML={{__html:t(`${detailText?.analysis}`)}}></p> 
                    </div>
                </div>
            </div>
        )
    }
    const getlevel = (value)=>{
        let dataLevel = [
            {
                min:0,
                max:37,
                level:"Beginner"
            },
            {
                min:38,
                max:50,
                level:"Lower Intermediate"
            },
            {
                min:51,
                max:58,
                level:"Intermediate"
            },
            {
                min:59,
                max:69,
                level:"Upper Intermediate"
            },
            {
                min:70,
                max:80,
                level:"Advanced"
            },
            {
                min:81,
                max:100,
                level:"Native"
            }
        ]
        let findLevel = dataLevel.find((e ) => e.max >= value);
        return findLevel ?? dataLevel[0]
    }
    const performancePro= (score,words,feedback)=>{
        let feedbacks = []
        feedback?.map(e=>{
            feedbacks = feedbacks?.concat(e)
        })
        let level = getlevel(score ?? 1)
        let listFeedback = feedbacks?.map((e)=>{
            return e?.phoneme
        })
        return(
            <>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='box-performancePro'>
                            <div className='circle-point'>
                                <CircularProgressbar
                                    value={score ?? 0}
                                    text={`${score?? 0} `}
                                    background
                                    strokeWidth={8}                                
                                    styles={buildStyles({
                                        backgroundColor: `none`,
                                        textColor: "#FFFFFF",
                                        pathColor: "#FE9400",
                                        // trailColor: "#060B24",                                                  
                                    })}
                                />
                            </div>
                            <div className='performancePro-right'>
                                <div className='title'>Your Level</div>
                                <div className='content'>{level?.level}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='words-list'>
                    {
                        words.map((e,i)=>{
                            let textColor = "#fff"
                            if(e?.decision==="correct"){
                                textColor = "#00F043"
                            }else if(e?.decision==="almost_correct" || e?.decision ==="warning"){
                                textColor = "#FE9400"
                            }else{
                                textColor = "#FB545C"
                            }
                            return(
                                <span key={i} className='word' style={{color:textColor}}>
                                    {`${e?.text_orig?? e?.text} `}
                                </span>
                            )
                        })
                    }
                </div>              
                {listFeedback.length >0 &&
                    <div className='box-performancePro-skill '>
                        <div className='title'>Pronunciation by Skill</div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='skill-box'>
                                    <div className='skill-title'>                        
                                        <div className='title-text'>
                                            {`Skill: /${listFeedback?.join("/ , /")}/`}
                                        </div>
                                        <div className='title-note'>
                                            Needs Improvement 
                                        </div>
                                    </div>
                                    <div className='tutorial'>
                                        <div className='tutorial-video'>
                                            <img src='/images/face-video.png' />
                                        </div>
                                        <div className='tutorial-right'>
                                        {`Tutorial for /${listFeedback?.join("/ , /")}/`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className='col-md-6'>
                                <div className='skill-box'>
                                    <div className='skill-title'>                        
                                        <div className='title-text'>
                                            {`Skill: /${listFeedback?.join("/, /")}/`}
                                        </div>
                                        <div className='title-note'>
                                            Needs Improvement 
                                        </div>
                                    </div>
                                    <div className='tutorial'>
                                        <div className='tutorial-video'>
                                            <img src='/images/face-video.png' />
                                        </div>
                                        <div className='tutorial-right'>
                                        {`Tutorial for /${listFeedback?.join("/, /")}/`}
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>                   
                    </div>
                }
                
            </>
        )
    }

    const performanceFluency = (score,value)=>{
        // const stgSpeakingAnswer = localStorage.getItem("SpeakingAnswer") ? JSON.parse(localStorage.getItem('SpeakingAnswer')) : [];
        // return(
        //     <>
        //         <div className='mt-3'>
        //             Part 1
        //         </div>
        //         <div className='performance-gluency'>
        //             <div className='row list-detail' >
        //                 <div className="accordion">
        //                     <div className="accordion-item">
        //                         <div className="accordion-header" id= {`panelsStayOpen-headingOne-1`}>
        //                             <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapseOne-1`} aria-expanded="false" aria-controls="panelsStayOpen-collapseOne">
        //                                 <ul>
        //                                     <li><img src='/images/stopwatch.png' alt='...' width={20} /></li>
        //                                     <li>Pause</li>
        //                                     <li>
        //                                         <div className="progress">
        //                                             <div className="progress-bar" role="progressbar" style={{width: '100%'}} aria-valuemax={100} />
        //                                         </div>
        //                                     </li>
        //                                     <li className='color-primary'>1 Times</li>
        //                                 </ul>
        //                             </div>
        //                         </div>
        //                         <div id={`panelsStayOpen-collapseOne-1`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-headingOne-1`}>
        //                             <div className="accordion-body">
        //                                 <div className='pause-body'>
        //                                     <p className='title'> Actual Speaking Time </p>
        //                                     <p className='detail'> 00:23 </p>
        //                                 </div>
        //                                 <div className='pause-body'>
        //                                     <p className='title'> Number of Pauses </p>
        //                                     <p className='detail'> 0 </p>
        //                                 </div>
        //                                 <div className='pause-body'>
        //                                     <p className='title'> Total Stop Time</p>
        //                                     <p className='detail'> 00:23 </p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     <div className="accordion-item">
        //                         <div className="accordion-header" id={`panelsStayOpen-headingTwo-1`}>
        //                             <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapseTwo-1`} aria-expanded="false" aria-controls="panelsStayOpen-collapseTwo">
        //                                 <ul>
        //                                     <li><img src='/images/weakness.png' alt='...' width={20} /></li>
        //                                     <li>Speech rate</li>
        //                                     <li>
        //                                         <div className="progress">
        //                                             <div className="progress-bar" role="progressbar" style={{width: '100%'}}aria-valuemax={100} />
        //                                         </div>
        //                                     </li>
        //                                     <li className='color-primary'>1</li>
        //                                 </ul>
        //                             </div>
        //                         </div>
        //                         <div id={`panelsStayOpen-collapseTwo-1`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-headingTwo-1`}>
        //                             <div className="accordion-body">
        //                                 <p className='title'>You didn’t use any fillers at all and spoke fluently.</p>
        //                             </div>
        //                         </div>
        //                     </div>

        //                     <div className="accordion-item">
        //                         <div className="accordion-header" id={`panelsStayOpen-headingThree-1`}>
        //                             <div className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#panelsStayOpen-collapseThree-1`} aria-expanded="false" aria-controls="panelsStayOpen-collapseThree">
        //                                 <ul>
        //                                     <li><img src='/images/hyperlink.png' alt='...' width={20} /></li>
        //                                     <li>Filler words</li>
        //                                     <li>
        //                                         <div className="progress">
        //                                             <div className="progress-bar" role="progressbar" style={{width: '100%'}}aria-valuemax={100} />
        //                                         </div>
        //                                     </li>
        //                                     <li className='color-primary'>1</li>
        //                                 </ul>
        //                             </div>
        //                         </div>
        //                         <div id={`panelsStayOpen-collapseThree-1`} className="accordion-collapse collapse" aria-labelledby={`panelsStayOpen-headingThree-1`}>
        //                             <div className="accordion-body">
        //                                 <p className='title'>You didn’t use any fillers at all and spoke fluently.</p>
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>  
        //         </div>
        //     </>
        // )

        // let level = getlevel(advanced_pronunciation_score)
        return(
            <div className='performanceGluency'>
                <div className='performanceGluency-box'>
                    <div className="title">Overview Comment</div>
                    <div className='content'>
                        <p>
                            Look how far you’ve come! You sound like a Native speaker!

                            Heads up! From time to time, you might have to adjust the speed of your delivery to facilitate the comprehension of others. Slowing down can help you be clear in phone conversations or when chatting with someone whose English might not be as advanced as yours!
                        </p>
                    </div>
                </div>
               
                <div className='performanceGluency-box'>
                    <div className="title">Fluency Score</div>
                    <div className='content'>
                       <ul>
                        <li>Pausing Score: <span className='h3'>{score ?? 0}% </span>Native</li>
                        <li>Hesitation: <span>Natural</span></li>
                       </ul>
                    </div>
                </div>
                <div className='performanceGluency-box'>
                    <div className="title">How to improve</div>
                    <div className='content'>
                        <p>
                            Fluency' means being able to control your delivery in a way that feels natural to the setting of a conversation. To improve:

                            Take time to pause at the end of complete sentences or after key ideas.

                            Make sure your speech is constantly moving forward during phrases. Your pace will naturally slow down at the next punctuation mark or logical stop.

                            Never interrupt the flow! It’s common for English learners to stop speaking when searching for a specific word. To avoid interrupting the flow of a conversation, use the language you know to describe a missing concept or idea!
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    const performanceLexical =()=>{
        let vocabulary_score = speakingData?.speakers[0]?.metrics.general_scores.elsa.vocabulary_score
        let level = getlevel(vocabulary_score)
        let cefr_distribution = speakingData?.speakers[0]?.metrics.other_metrics.vocabulary.cefr_distribution
        let feedback = speakingData?.speakers[0]?.feedbacks.vocabulary.top_cefr_words
        return(
            <div className='performanceLexical'>
                <div className='box-performancePro'>
                    <div className='circle-point'>
                        <CircularProgressbar
                            value={vocabulary_score ?? 0}
                            text={`${vocabulary_score ?? 0} `}
                            background
                            strokeWidth={10}                                
                            styles={buildStyles({
                                backgroundColor: `none`,
                                textColor: "#FFFFFF",
                                pathColor: "#00B7F0",
                                // trailColor: "#060B24",                                                  
                            })}
                        />
                    </div>
                    <div className='performancePro-right'>
                        <div className='title'>Your Level</div>
                        <div className='content content-lexical'>{level?.level}</div>
                    </div>
                </div>
                <div className='lexical-box'>
                    <div className="title">High-frequency Words</div>
                    <div className='content'>
                        <ul>
                            {
                                feedback?.map((e,i)=>{
                                    return (
                                        <li key={i}>{e.word} ({e.cefr_level})
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8" cy="7.5" r="7.5" fill="#00F0FF"/>
                                                <path d="M12.5 7.79998L6.2 11.4373L6.2 4.16267L12.5 7.79998Z" fill="#0E2A47"/>
                                            </svg>
                                        </li>
                                    )
                                })
                            }                                                       
                        </ul>
                    </div>

                </div>
                <div className='lexical-box'>
                    <div className="title">Vocabulary Distribution</div>
                    <div className='content'>
                        <i>Using advanced Vocabulary (CEFR levels B2, C1 and C2) adds colour and flair to your delivery. </i>
                        <div className='lists-cefr'>
                            {
                                cefr_distribution?.map((e,i)=>{
                                    return(
                                        <div className='ceft-items' key={i}>
                                            <div className={`item item${e.cefr_level}`} >{e.cefr_level}</div>
                                            <div className="progress">
                                                <div className={`progress-bar progress-bar-${e.cefr_level}`} role="progressbar" style={{width:`${e.percentage}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                <p className='percent'>{e.percentage}%</p>
                                            </div>
                                        </div>
                                    )

                                })
                            }
                            
                            {/* <div className='ceft-items'>
                                <div className='item itemC1' >C1</div>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-c1" role="progressbar" style={{width:"20%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <p className='percent'>20%</p>
                                </div>
                            </div>
                            <div className='ceft-items'>
                                <div className='item itemB2' >B2</div>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-b2" role="progressbar" style={{width:"20%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <p className='percent'>20%</p>
                                </div>
                            </div>
                            <div className='ceft-items'>
                                <div className='item itemB1' >B1</div>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-b1" role="progressbar" style={{width:"20%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <p className='percent'>20%</p>
                                </div>
                            </div>
                            <div className='ceft-items'>
                                <div className='item itemA2' >A2</div>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-a2" role="progressbar" style={{width:"20%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <p className='percent'>20%</p>
                                </div>
                            </div>
                            <div className='ceft-items'>
                                <div className='item itemA1' >A1</div>
                                <div className="progress">
                                    <div className="progress-bar progress-bar-a1" role="progressbar" style={{width:"20%"}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                    <p className='percent'>20%</p>
                                </div>
                            </div> */}
                        </div>
                    </div>                    
                </div>
            </div>
        )
    }
    const performanGrammar =(feedback)=>{
        return(
            <div className='performanceLexical'>               
                <div className='lexical-box'>
                    <div className="title">Your Top Grammatical Error(s)</div>
                    <div className='content'>
                        <ul>
                            {
                                feedback?.map((e,i)=>{
                                    return(
                                        <li key={i}>
                                            {e.original}                          
                                        </li>
                                    )
                                })
                            }                                                      
                        </ul>
                    </div>

                </div>
               
            </div>
        )
    }
    const performanceDelivery =(delivery)=>{
        console.log(delivery)
        let words_per_minute = delivery?.words_per_minute?? 0
        let percen = words_per_minute*180/(delivery?.words_per_minute_max ?? delivery?.articulated_words_per_minute)
        console.log(percen)

        return(
            <div className='performanceDelivery'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-6'>
                        <div className='speed'>
                            <div className='speed-volume'>
                                <img src='/images/speed.png' className='speed-volume-png' width={150}/>
                                <img src='/images/speed-retate.png' className='speed-retate-png' width={50} style={{transform:`rotate(${percen}deg)`}} />
                            </div>
                            <div className='m-3'>
                                <span className='h3'>{words_per_minute.toFixed(0) ?? 0}</span> Words/Minutes
                            </div>
                            <div className='m-2 detai-speed'>
                                <ul>
                                    <li><span className='circle'></span>Slurred Speech</li>
                                    <li><span className='circle circle-2'></span>Casual Communication</li>
                                    <li><span className='circle circle-3'></span>Fast Speech</li>
                                </ul>
                            </div>
                        </div>
                        <p className='speaking-shows mt-3'>
                            The speed of speaking shows that you are quite relaxed and confident
                        </p>
                        <img className='chart mt-3' src='/images/chart.png'/>
                        
                        <p className='speaking-shows mt-3'>
                            Adjusting the speaking speed flexibly will make listeners more interested in your speech. Try to practice speaking parts where your voice is faint or too low!
                        </p>                        
                    </div>
                </div>               
            </div>
        )
    }
    const performanceIntonation =(intonation)=>{        
        return(
            <div className='performanceIntonation'>
                {
                    intonation.map((e,i)=>{
                        let textColor = "#FB545C"
                        if(e?.decision==="correct" || e?.result?.decision==="correct"){
                            textColor = "#00F043"
                        }else if(e?.decision==="almost_correct" || e?.decision ==="warning" || e?.result?.decision==="almost_correct" || e?.result?.decision==="warning"){
                            textColor = "#FE9400"
                        }else{
                            textColor = "#FB545C"
                        }
                        return(
                            <div className='row' key={i}>
                                <div className='intonation-box'>
                                    <div className='intonation-title' style={{color:textColor}}>
                                       {e?.text?? e?.sentence}
                                    </div>
                                    <div className='pronounce'>
                                       <i>/ {e?.result?.ipa ?? e?.ipa } /</i> 
                                    </div>
                                    {/* <div className='media'>
                                        <ul>
                                            <li>1</li>
                                            <li>2</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    const getSpeakingContentVsAnswer= (exam)=>{
        const newQuesion =  exam?.questionContent
        // const newQuesion = JSON.parse(questionContent)
        let speakingContent = newQuesion?.languageSkillExams?.filter(e => e?.languageSkillType ==='Speaking');
        let speakingQuestion = []
        speakingContent[0].partitionExams[0]?.sectionExams?.map((e)=>{
            e?.groupQuestionExams?.map((i)=>{
                i?.questionExams?.map((j)=>{
                    speakingQuestion.push(j)
                })
            })
        })
        
        const listSpeakingAnswer = exam?.answerContent.filter(e=>speakingQuestion.map(j=>j.id).indexOf(e?.questionId) != -1 )
    
        return {
            speakingQuestion,listSpeakingAnswer
        }
    }
    const getReviewExamId = async ()=>{
        let exam = localStorage.getItem("exam")
        if(!exam) return;
        exam = JSON.parse(exam)
        const result = await reviewExamId(exam?.id);
        
        if(result?.isSuccess){
            setReviewExam(result.data)
            setExamId(exam?.id ?? null)
            if(result?.data?.pointContent.length>0) setStatus(true) ///check poincontent có thì là đã chấm bài xong
            let speakingContentVsAnswer = getSpeakingContentVsAnswer(result?.data)
            const resultSpeaking = await externalGradingResults(exam?.id)//08dbba44-9ae1-4459-8117-16a0bf858b1d
            
            setSpeakingData({
                "question":speakingContentVsAnswer.speakingQuestion,
                "answer":speakingContentVsAnswer.listSpeakingAnswer,
                "detail":resultSpeaking?? null,
            })
            
        }else{
            if(refcount.current >=2) {
                setStatus(true)
                return
            }
            refcount.current ++
            setTimeout(getReviewExamId, 10000);
        }
    }
    useEffect(()=>{
        if(!refGetDaTa.current) {
            refGetDaTa.current=true
            getReviewExamId();
          }
    },[])

    return (
        <>
           {
            status && <>   
                <div className='row'>
                    <div className='box-result-overview'>
                        <p className='title'>
                            {t(`resultAnalysis.boxResultAnalysis.title`)}
                        </p>
                        <p className='guide'>
                            Your score indicates that your level of English is in the range of A1 BEGINNER to A2 ELEMENTARY, according to the guidelines set by the Common European Framework of Reference (CEFR).
                        </p>
                        <p className='guide'>
                            You are on your way to comprehending written and spoken vocabulary and sentences in English on topics from your daily life.
                        </p>
                        <div className='row p-2'>
                            <div className='col-md-6 box-score'>
                                <div className='score-circle'>
                                    <CircularProgressbar
                                        value={(reviewExam?.listeningPoints ?? 0 )}
                                        text={`${reviewExam?.listeningPoints ?? 0 }`}
                                        background
                                        strokeWidth={8}
                                        // backgroundPadding={3}
                                        
                                        styles={buildStyles({
                                            backgroundColor: `none`,
                                            textColor: "#FFFFFF",
                                            pathColor: "#00F0FF",
                                            trailColor: "#060B24",   
                                            // strokeLinecap: "butt"                                                     
                                        })}
                                    />
                                </div>
                                <div className='score-skill'>
                                    <div className='skill-name'>
                                        <p>Listening</p> 
                                    </div>
                                    <div className='skill-score-detail'>
                                        <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswer`)} {reviewExam?.listeningTriPoints ?? 0 }</p> 
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 box-score'>
                                <div className='score-circle'>
                                    <CircularProgressbar
                                        value={(reviewExam?.readingPoints?? 0 )}
                                        text={`${reviewExam?.readingPoints ?? 0}`}
                                        background
                                        strokeWidth={8}
                                        // backgroundPadding={3}
                                        
                                        styles={buildStyles({
                                            backgroundColor: `none`,
                                            textColor: "#FFFFFF",
                                            pathColor: "#00F0FF",
                                            trailColor: "#060B24",   
                                            // strokeLinecap: "butt"                                                     
                                        })}
                                    />
                                </div>
                                <div className='score-skill'>
                                    <div className='skill-name'>
                                        <p>Reading</p> 
                                    </div>
                                    <div className='skill-score-detail'>
                                        <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswer`)}  {reviewExam?.readingTriPoints ?? 0}</p> 
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 box-score'>
                                <div className='score-circle'>
                                    <CircularProgressbar
                                        value={(reviewExam?.speakingPoints?? 0 )}
                                        text={`${reviewExam?.speakingPoints?? 0 }`}
                                        background
                                        strokeWidth={8}
                                        // backgroundPadding={3}
                                        
                                        styles={buildStyles({
                                            backgroundColor: `none`,
                                            textColor: "#FFFFFF",
                                            pathColor: "#00F0FF",
                                            trailColor: "#060B24",   
                                            // strokeLinecap: "butt"                                                     
                                        })}
                                    />
                                </div>
                                <div className='score-skill'>
                                    <div className='skill-name'>
                                        <p>Speaking</p> 
                                    </div>
                                    <div className='skill-score-detail'>
                                        <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswerWriting`)}  {reviewExam?.speakingTriPoints ?? 0}/100</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>

                <div className='row box-result-analysis mt-4'>
                    <div className='analysis-header'>
                        <div className='header-title'>
                            <p className='title'>
                                {t(`resultAnalysis.resultAnalysisDetail.title`)}
                            </p>
                        </div>
                        <div className='header-list-skill'>
                            <div className='list-skill'>
                                <ul>
                                    <li className= { skillReport ==="Listening" ?'skill-active':""} onClick={()=>setSkillReport("Listening")}><p>Listening Report</p></li>
                                    <li className= { skillReport ==="Reading" ?'skill-active':""} onClick={()=>setSkillReport("Reading")}><p>Reading Report</p></li>
                                    <li className= { skillReport ==="Speaking" ?'skill-active':""} onClick={()=>setSkillReport("Speaking")}><p>Speaking Report</p></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    {
                        analysisContentWithSkill(skillReport)
                    }
                </div>


                <div className='row performance-analysis'>
                    <div className='performance-analysis-header'>
                        <div className='progess-custom'>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '100%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                        </div>
                        <div className='performance-analysis-title'>
                            {t(`resultAnalysis.performanceAnalysis.title`)}
                        </div>
                        <div className='progess-custom'>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: '100%'}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                        </div>
                    </div>
                    <p className='text-center mt-1'><i>
                        {
                            t(`resultAnalysis.performanceAnalysis.time`,{
                                dayofweek:weekday[date.getDay()],
                                month:month[date.getMonth()],
                                date:date.getDate(),
                                year:date.getFullYear(),
                                time:date.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })
                            })
                        }</i></p>
                    {
                        speakingData?.answer?.map(e=>{
                            let question = speakingData?.question?.find(j=>j.id === e?.questionId)
                            let speakingdetail = speakingData?.detail?.data?.find(k=>k?.questionExamId === e?.questionId)
                            speakingdetail = speakingdetail?.reponseData ?? null
                            speakingdetail = JSON.parse(speakingdetail)
                            let pronunciationScore = 0,fluencyScore =0
                            let words = [],feedbacks = [],intonation=[],grammar=[],delivery
                            if(question.questionType ==='TextSpeaking'){
                                pronunciationScore = speakingdetail?.utterance[0]?.nativeness_score ?? 0
                                fluencyScore = speakingdetail?.utterance[0]?.fluency_score ?? 0
                                words = speakingdetail?.utterance[0]?.words ?? []
                                intonation.push(speakingdetail?.utterance[0])
                                delivery = speakingdetail?.utterance[0]?.speed_metrics?? null
                            }else{
                                pronunciationScore = speakingdetail?.speakers[0]?.metrics?.general_scores?.elsa?.pronunciation_score ?? 0   
                                fluencyScore = speakingdetail?.speakers[0]?.metrics?.general_scores?.elsa?.fluency_score ?? 0   
                                speakingdetail?.speakers[0]?.utterances.map(i=>{
                                    words = words.concat(i?.result?.words)
                                    feedbacks = speakingdetail?.speakers[0]?.feedbacks?.pronunciation?.top_errors   ?? []                   
                                })
                                intonation = speakingdetail?.speakers[0]?.utterances?? []
                                grammar = speakingdetail?.speakers[0]?.feedbacks?.grammar?.items ?? []
                                delivery = speakingdetail?.speakers[0]?.metrics?.other_metrics?.fluency ?? null
                            }
                            // console.log(speakingdetail)
                            return(
                                <div className='row m-0 mt-5' key={e?.questionId}>
                                    <div className='performance-left'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <div className='audio'>
                                                    <audio src={`${process.env.REACT_APP_BE_HOST+e?.answerContent}`} controls></audio>
                                                    <p>Watch your video</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='box-performance-left mt-3'>
                                            {/* <p dangerouslySetInnerHTML={{__html:question?.content}}></p> */}
                                            <div className='box-performance '>
                                            <p dangerouslySetInnerHTML={{__html:question?.content}}></p>
                                            </div>
                                        </div>
                                    
                                    </div>
                                    <div className='row accordion-box'>
                                        <div className="accordion accordion-flush" id={e?.questionId}>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={`${e?.questionId}-headingPro`}>
                                                    <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#${e?.questionId}-collapsePro`} aria-expanded="false" aria-controls={`${e?.questionId}-collapsePro`}>
                                                        <span>Pronunciation</span>
                                                    </div>
                                                </h2>
                                                <div id={`${e?.questionId}-collapsePro`} className="accordion-collapse collapse" aria-labelledby={`${e?.questionId}-headingPro`} data-bs-parent={`#${e?.questionId}`}>
                                                    <div className="accordion-body">
                                                        { performancePro(pronunciationScore?.toFixed(0),words,feedbacks)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={`${e?.questionId}-headingFlu`}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e?.questionId}-collapseFlu`} aria-expanded="false" aria-controls={`${e?.questionId}-collapseFlu`}>
                                                        Fluency & Coherence
                                                    </button>
                                                </h2>
                                                <div id={`${e?.questionId}-collapseFlu`} className="accordion-collapse collapse" aria-labelledby={`${e?.questionId}-headingFlu`} data-bs-parent={`#${e?.questionId}`}>
                                                    <div className="accordion-body">
                                                        {performanceFluency(fluencyScore.toFixed(0),1)}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={`${e?.questionId}-headingInt`}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e?.questionId}-collapseInt`} aria-expanded="false" aria-controls={`${e?.questionId}-collapseInt`}>
                                                    Intonation
                                                </button>
                                                </h2>
                                                <div id={`${e?.questionId}-collapseInt`} className="accordion-collapse collapse" aria-labelledby={`${e?.questionId}-headingInt`} data-bs-parent={`#${e?.questionId}`}>
                                                    <div className="accordion-body">
                                                        {performanceIntonation(intonation)}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                question.questionType ==='TopicSpeaking' &&  
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id={`${e?.questionId}-headingGra`}>
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e?.questionId}-collapseGra`} aria-expanded="false" aria-controls={`${e?.questionId}-collapseGra`}>
                                                        Grammar
                                                    </button>
                                                    </h2>
                                                    <div id={`${e?.questionId}-collapseGra`} className="accordion-collapse collapse" aria-labelledby={`${e?.questionId}-headingGra`} data-bs-parent={`#${e?.questionId}`}>
                                                        <div className="accordion-body">
                                                            {performanGrammar(grammar)}
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        
                                            <div className="accordion-item">
                                                <h2 className="accordion-header" id={`${e?.questionId}-headingDel`}>
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e?.questionId}-collapseDel`} aria-expanded="false" aria-controls={`${e?.questionId}-collapseDel`}>
                                                    Delivery
                                                </button>
                                                </h2>
                                                <div id={`${e?.questionId}-collapseDel`} className="accordion-collapse collapse" aria-labelledby={`${e?.questionId}-headingDel`} data-bs-parent={`#${e?.questionId}`}>
                                                    <div className="accordion-body">
                                                        {performanceDelivery(delivery)}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                
                                </div>
                            )
                        })
                    }
                </div>
                {
                    examId && <p className="text-center mt-4" ><i className={isDesktop? "color-white" : 'color-black'} dangerouslySetInnerHTML={{__html:t(`overview.detailLink1`,{id:examId}) }}></i></p>
                }
            </>
        }
        {
            !status && <WaitingResult />
        }
        </>
    )
}
export default FullTestResult