import React, { useEffect, useState } from 'react'
import './welcomeMb.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeaderMB from '../Layout/Menu/HeaderMB/Header'

const WelcomeMb = () => {
    const { t } = useTranslation();
    const [username,setUsername] = useState()
    const [input,setInput] = useState()
    const navigate = useNavigate()
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    const skill = localStorage.getItem("skill")
    const subSkill = localStorage.getItem("subSkill")
    
    const handleClick = ()=>{
        console.log(userInfo)
      
        if(userInfo.userName){
            if(skill==='/speaking' && subSkill !=='' ) return navigate('/speakingai')
            if(skill==='/speaking') return navigate(skill)
            return navigate('/listening')
        }
        if(!username) return alert("Username Requirements")
        userInfo.userName = username;
        console.log(username)
        console.log(username)
        localStorage.setItem("userInfo",JSON.stringify(userInfo))
        
        if(skill==='/speaking') return navigate(skill)
        return navigate('/listening')
    }
    // console.log(userInfo,username)
    useEffect(()=>{
        if(skill==='/speakingai' && subSkill !=='' ) return navigate('/speakingai')
    },[])
    return (
        <div className={(userInfo.userName || username) ? "welcome-mb1" : "welcome-mb"}>   
           <HeaderMB content={"AI Input Test"}/>
           <div className='wc-content'>
                
                {
                    (userInfo.userName || username) ?
                    <>
                        <p className='wc-title'>Hello <br/>{userInfo?.displayName ?? userInfo.userName ?? username} </p>
                        <p className='detail1'>Bạn sẽ có <b>{skill=="/shorttest" ? "30":"90"}</b> phút để hoàn thành bài kiểm tra đầu vào cho cả <b>3</b> Kỹ năng theo thứ tự: <b>Nghe, Đọc, Viết</b></p>
                        <div className='list-skill'>
                            <div className="card">
                                <img className="card-img-top" src="/images/mb/listen.png" alt="Card image cap"/>
                                <div className="card-body">
                                    <h2 className="card-title">1. Listening</h2>
                                    <p className="card-text">Bài thi nghe sẽ bao gồm 36 câu.</p>                                
                                </div>
                            </div>
                            <div className="card">
                                <img className="card-img-top" src="/images/mb/reading.png" alt="Card image cap"/>
                                <div className="card-body">
                                    <h2 className="card-title">2. Reading</h2>
                                    <p className="card-text">Bài thi nghe sẽ bao gồm 36 câu.</p>                                
                                </div>
                            </div>
                            <div className="card">
                                <img className="card-img-top" src="/images/mb/writing.png" alt="Card image cap"/>
                                <div className="card-body">
                                    <h2 className="card-title">3. Writing</h2>
                                    <p className="card-text">Bài thi nghe sẽ bao gồm 2 câu.</p>                                
                                </div>
                            </div>
                        </div>
                        <div className="input-group mt-5 ">
                            <button type='button' className='btn' onClick={()=>handleClick()}>Bắt đầu ngay</button>
                        </div>
                    </>
                    :
                    <>
                        <img className='img-wc' src='/images/mb/welcom-img.png'/>
                        <p className='h2 title' dangerouslySetInnerHTML={{__html:t(`welcome.hello`) }}></p>
                        <p className='detail'>{t(`welcome.detail`)}</p>
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Enter your name here" aria-label="Enter your name here"  onChange={event => setInput(event.target.value)}/>                           
                        </div>
                        <div className="input-group mt-2 ">
                            <button type='button' className='btn' onClick={()=>setUsername(input)}>Vào phòng thi</button>
                        </div>
                    </>
                }
           </div>
        </div>
    )
}
export default WelcomeMb