import React from "react";
import { useTranslation } from "react-i18next";
import { isDesktop} from "react-device-detect";
const Antedu =()=>{
    const { t } = useTranslation();
    const  i18nextLng = localStorage.getItem("i18nextLng")?localStorage.getItem("i18nextLng"):"vi"

    return(
        <section className='antedu '  id="antedu">
            <div className='container'>
                <div className='header-antedu d-flex align-items-center justify-content-center'>
                    <h3 className='text-center ' dangerouslySetInnerHTML={{ __html: t(`home.antedu.title`)}}></h3>
                </div>
                <div className='content-antedu'>
                    <img src="/images/antedu-bg.png" className="card-img-bg" alt="antedu" />
                    <div className="antedu-box">
                        <div className="row row-list row-list-first">
                            <div className="col-md-4 box-list">
                                <div className="box-content">
                                    <div className="box-content-title">
                                    {t(`home.antedu.communicationEnglish.title`)}
                                    </div>
                                    <div className="box-content-content">
                                        <ul>
                                            <li>{t(`home.antedu.communicationEnglish.listItem.a`)}</li>
                                            <li>{t(`home.antedu.communicationEnglish.listItem.b`)}</li>
                                            <li>{t(`home.antedu.communicationEnglish.listItem.c`)}</li>
                                            <li>{t(`home.antedu.communicationEnglish.listItem.d`)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-icon"><img src="/images/lingo.png" /></div>
                            </div>
                        </div>
                        <div className="row row-list row-list-two">
                            
                            <div className="col-md-4 box-list">
                                <div className="box-content">
                                    <div className="box-content-title">
                                        {t(`home.antedu.languageAssessment.title`)}
                                    </div>
                                    <div className="box-content-content">
                                        <ul>
                                            <li>{t(`home.antedu.languageAssessment.listItem.a`)}</li>
                                            <li>{t(`home.antedu.languageAssessment.listItem.b`)}</li>
                                            <li>{t(`home.antedu.languageAssessment.listItem.c`)}</li>
                                            <li>{t(`home.antedu.languageAssessment.listItem.d`)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-icon"><img src="/images/khaothi.png" /></div>
                            </div>
                            <div className="col-md-4 box-list">
                                <div className="box-content">
                                    <div className="box-content-title">
                                        {t(`home.antedu.inputTest.title`)}
                                    </div>
                                    <div className="box-content-content">
                                        <ul>
                                            <li>{t(`home.antedu.inputTest.listItem.a`)}</li>
                                            <li>{t(`home.antedu.inputTest.listItem.b`)}</li>
                                            <li>{t(`home.antedu.inputTest.listItem.c`)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-icon"><img src="/images/danhgia.png" /></div>
                            </div>
                        </div>
                        <div className="row row-list row-list-three">
                            <div className="col-md-4 box-list">
                                <div className="box-content">
                                    <div className="box-content-title">
                                        {t(`home.antedu.academicTraining.title`)}
                                    </div>
                                    <div className="box-content-content">
                                        <ul>
                                            <li>{t(`home.antedu.academicTraining.listItem.a`)}</li>
                                            <li>{t(`home.antedu.academicTraining.listItem.b`)}</li>
                                            <li>{t(`home.antedu.academicTraining.listItem.c`)}</li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="box-icon"><img src="/images/hocthuat.png" /></div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </section>
    )
}
export default Antedu