import React, { useEffect, useRef, useState } from 'react'
import './index.scss'
import HomeMenu from '../Layout/Menu/Home'
import "react-circular-progressbar/dist/styles.css";
import FullResultAi from './fullTestResult';
import { isDesktop} from "react-device-detect";
import HeaderMB from '../Layout/Menu/HeaderMB/Header';

const ResultAnalysisAi = () => {
    const darkMode = localStorage.getItem('darkMode') ?? "dark"
    const token = localStorage.getItem('token')

    return (
        <>   
            {
                isDesktop ?
                <>
                    <div className='container-fluid result-analysis-ai p-0' id="resultAnalysis">
                        <HomeMenu/>
                        <div className='intro '>
                            <div className='container d-flex align-items-center justify-content-center'>
                                <div className='col-md-10 mt-5' >
                                    <FullResultAi/>                           
                                </div>
                                                    
                            </div>
                        </div>                
                    </div>
                </>
                :
                <>
                    <div className='container-fluid result-analysis-ai p-0' id="resultAnalysis">
                        <HeaderMB content={"Kết quả"}/>
                        <div className='intro '>
                            <div className='container d-flex align-items-center justify-content-center'>
                                <div className='col-md-10 mt-5' >
                                    <FullResultAi/>                           
                                </div>
                                                    
                            </div>
                        </div>                
                    </div>
                </>
            }
            
            
        </>
    )
}
export default ResultAnalysisAi