import React, { useEffect, useState } from 'react'
import "./index.scss"
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { isDesktop} from "react-device-detect";
import { checkUser, getUser, signOut } from '../../../../services/userService';
import ToggleDarkMode from '../../../ComponentShare/ToggleDarkMode';

const MenuLight = (props) => {
    // console.log("pops",props)
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const [language,setLanguage] = useState(localStorage.getItem('i18nextLng')? localStorage.getItem('i18nextLng') : "vi" )
    const [userInfo,setUserInfo] = useState({})
    const token = localStorage.getItem('token')
    const changeLanguage = () => {
        const newLanguage = language==='en' ? 'vi':'en';
        setLanguage(newLanguage)
        i18n.changeLanguage(newLanguage);    
        localStorage.setItem('i18nextLng',newLanguage)

    };
    const logout= async ()=>{
        try {
            let result = await signOut()
            navigate('/auth/login',{replace:true})
        } catch (error) {
            alert("logout error!")
        }
    }

    // const handleVideoCourse = async ()=>{
    //     return window.open(`${process.env.REACT_APP_ONLINE_DOMAIN}/menu`,'_blank')        
    // }

    const checkTokenExpried = async () =>{
        try {
            const result = await checkUser()
            setUserInfo(result.data)
        } catch (error) {
            console.log('error',error)
            localStorage.removeItem("userInfo")
            localStorage.removeItem("token")
        }
       
    };
    useEffect(() => {
        checkTokenExpried()
    }, [])
    return (
    <>
        <nav className="navbar navbar-expand-lg navbar-scroll" id='menuLight'>
            <div className="container-fluid">
                <div className='main-logo d-flex justify-content-center' >
                    <a className="navbar-brand " href="/">
                        <img
                            src="/images/logo.png"
                            alt="Logo"
                            loading="lazy"
                            className='img-logo'
                        />
                    </a>
                </div>
                <ul className="list-item-menu">
                    <li className="nav-item">
                        <a className="nav-link" href="#inputTest">Test Đầu Vào</a>
                    </li>   
                    <li className="nav-item">
                        <a className="nav-link" target="__blank" href={`${process.env.REACT_APP_ONLINE_DOMAIN}/${isDesktop ? "study-route" : "menu"}`}>{t(`home.header.menu.videoCourse`)}</a>
                    </li>
                    
                    <li className="nav-item">
                        <a className="nav-link" target='__blank' href={`${process.env.REACT_APP_ONLINE_DOMAIN}/live`}  >Livestream</a>
                    </li>                          
                </ul>
                <div className='language' >
                    {
                        userInfo.userName ? 
                            <div className="box-info info-dropdown  justify-content-end ">
                                <div className="dropdown">
                                    <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img
                                            src={userInfo.avatar? `${process.env.REACT_APP_BE_HOST}${userInfo.avatar}` : `${process.env.REACT_APP_DOMAIN}/images/user.png`} 
                                            className="me-2"
                                            height="29"
                                            width="29"
                                            alt="flag"
                                            loading="lazy"
                                        />
                                     
                                         { userInfo?.firstName ? userInfo.firstName : userInfo?.displayName? userInfo.displayName : userInfo?.userName }
                                    </button>
                                    <ul className="dropdown-menu dropdown-menu-end">
                                        <li><a className="dropdown-item" href="/user/profile"><img src="/images/user-icon-blue.png" width={20} /> Profile</a></li>
                                        <li onClick={()=>logout()}><a className="dropdown-item" href="#"><img src="/images/logout.png" width={20} /> Log out</a></li>
                                    </ul>
                                </div>


                            </div>
                        :
                            <div className="box-info justify-content-md-center ">
                                <ul className="navbar-nav">
                                    {
                                        isDesktop? <>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/auth/login">{t(`home.header.menu.signIn`)}</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" href="/auth/register">{t(`home.header.menu.signUp`)}</a>
                                            </li>
                                        </>
                                        :
                                        <li className="nav-item">
                                            <a className="nav-link" href="/auth/login">{t(`home.header.menu.signIn`)}</a>
                                        </li>
                                    }
                                    
                                </ul>                        
                            </div>

                    }                                        
                    {/* <div className="dropdown box-language">
                        <button className="btn dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                            <img
                                src={language==='vi'? "/images/flag-vi.png" : "/images/flag-us.png" }
                                className="me-2"
                                height="29"
                                width="29"
                                alt="flag"
                                loading="lazy"
                            />
                        </button>
                        <ul className="dropdown-menu dropdown-menu-start">
                            <li onClick={()=>changeLanguage()}> 
                                <img
                                src={language==='vi'? "/images/flag-us.png" : "/images/flag-vi.png" }
                                className="me-2"
                                height="29"
                                width="29"
                                alt="flag"
                                loading="lazy"                                            
                                />
                                { isDesktop? language==='vi'? "English" : "Việt Nam" : ""}
                                
                            </li>     
                        </ul>
                    </div>                     */}
                   
                    <ToggleDarkMode />
                </div>
            
            </div>
        </nav>
                    
    </>
    )
}
export default MenuLight;