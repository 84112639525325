import React, { useEffect, useRef, useState } from 'react'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import MenuTest from '../Layout/Menu/MenuTest'
import { getStartExam } from '../../services/ieltsService'
import { isDesktop} from "react-device-detect";
import HeaderMB from '../Layout/Menu/HeaderMB/Header';
import toastifyClient from '../../untils/toastifyClient';

const GuestIELTSRoute = () => {
  const skill = localStorage.getItem("skill")
  const token = localStorage.getItem("token")
  const [data,setData] = useState();
  const refData = useRef(false);

  if(token) window.location.href = skill

  const menuItem = [
      {item:"listening",link:"/listening"},
      {item:skill === "/shorttest" ? "grammar & reading" :"reading",link:"/reading"},
      {item:"writing",link:"/writing"}
      // {item:"speaking",link:"/speaking"}
  ]
  const navigate = useNavigate()
  const location = useLocation()
  const uri = location.pathname

  const getData = async ()=>{
    const stgExam = localStorage.getItem('exam')
    if(stgExam){
      setData(stgExam)
      return
    }
    try {
      const result = await getStartExam({
        "examType":skill==="/shorttest" ? "Short" : "Full",
        "browserId":localStorage.getItem("browserId")
      });
      if(result.isSuccess && result.data) {
        setData(result.data)
        localStorage.setItem('exam',JSON.stringify(result.data))
      }else{
        if(result?.errors[0]?.code == 1){
          toastifyClient.error(result?.errors[0]?.message ?? "error auth");
          navigate("/auth/login")
        }
        if(result?.errors[0]?.code == 2){
          toastifyClient.error(result?.errors[0]?.message ?? "error coin");
          navigate("/")
        }
      }
      
    } catch (error) {
      console.log(error)
    }    
  }
  useEffect(()=>{
    // localStorage.removeItem('answer')
    if(!refData.current) {
      refData.current=true
      getData();
    }
  },[])
  return (
    <>
      <div className='container-fluid p-0 content-ielts'>
        {
        isDesktop && <MenuTest active={uri} menuItem={menuItem} time={uri==="/speaking"? 480000  : skill==="/shorttest" ? 900000  : 3000000}/>   
        }
        {
          data && <Outlet />
        }
      </div>
    </>
  )
  
}

export default GuestIELTSRoute
