import React from "react";
import "./index.scss"
import Spinner from "../../PresenTations/share/Sprinner";
const WaitingResult = ()=>{
    return(
        <div className='container-fluid waiting-result d-flex align-items-center'>
            <div className="container">
                <div className="content-wrapper">
                    <section className="content">
                        <div className="result-page">
                            {/* <Spinner/> */}
                            <div className="result-content d-flex flex-column justify-content-center">
                                <img className='wd-img' src='/images/light/youdidit.png' style={{maxWidth:"400px", margin:"auto"}} />
                                <h3 className="text-center color-white">The system is currently grading, please wait a moment! <div className="spinner-border" style={{width:'1rem',height:'1rem',color:'#fff'}} role="status" ></div></h3>
                                <p className="text-center ">You can go back to<a href="/"> Home page </a> or try <a href="#" onClick={()=>window.location.reload()} >refreshing</a> the page.</p>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default WaitingResult