import axios from 'axios';
import httpClient from './httpClient.service';

export const addUser = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/registerAccount`,data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const checkUser=()=>{
  return new Promise((resolve, reject) => {
    const config = {
      method: 'get',
      url: process.env.REACT_APP_BE_DOMAIN + '/user/info',
      headers: { 
        'Content-Type': 'application/json',
      },
      withCredentials: true
    };
    axios(config)
    .then(function (response) {
      localStorage.setItem('userInfo',JSON.stringify(response.data.data))
      resolve(response.data)
    })
    .catch(function (error) {
      reject(error)
    });
  }) 
}

export const getUser = ()=>{
  return new Promise((resolve, reject) => {
    httpClient.get(`${process.env.REACT_APP_BE_DOMAIN}/user/info`)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}


export const signIn = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/signin`,data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
export const signOut = ()=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/signout`)
    .then(res => {
      if (res.status === 200) {
        localStorage.removeItem('userInfo')
        localStorage.removeItem('token')       
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}


export const putUser = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.put(`${process.env.REACT_APP_BE_DOMAIN}/user/info`, data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}


export const changePassword = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.put(`${process.env.REACT_APP_BE_DOMAIN}/user/change-password`, data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
export const updateGoals = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/user/goals`, data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const fqaSystem = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/fqa-systems`, data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);

    });
  });
}

export const getPopup = ()=>{
  return new Promise((resolve, reject) => {
    httpClient.get(`${process.env.REACT_APP_BE_DOMAIN}/popups`)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const registerFacebook = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/signup/facebook`,data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
export const signInFacebook = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/signin/facebook`,data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
export const registerGoogle = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/signup/google`,data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

export const signInGoogle = (data)=>{
  return new Promise((resolve, reject) => {
    httpClient.post(`${process.env.REACT_APP_BE_DOMAIN}/signin/google`,data)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
const userService = {
  changePassword,getUser,putUser,signIn,checkUser,getPopup,registerFacebook,registerGoogle,signInFacebook
}
export default userService