import React, { useState } from "react";
import { useTranslation } from "react-i18next";
// import "./index.scss"
const ForgotPassword =()=>{
    const { t } = useTranslation();
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const [status,setStatus] = useState(false)
    const handleSubmit = async (event)=>{
        event.preventDefault();
        setStatus(true)
    }
    return(
        <>        
            <a className="auth-logo" href="/"><img className={darkMode ==="dark" ? "":"background-3060CF"}  src="/images/logo.png" /></a>
            <div className='container-fluid loginpage' id="loginpage" style={{backgroundImage: darkMode =="dark" ? `url("/images/auth-bg.png")` : `url("/images/light/bg-auth.png")`}}>
                <div className="container h-100 d-flex justify-content-center align-items-center">
                    <div className="col-md-6 col-left">
                        <img src={darkMode =="dark"  ? "/images/haveaniceday.png" : "/images/light/hava-a-nice-day.png" } />
                        <img src="/images/img-have-a-nice-day.gif" />
                    </div>
                    <div className="col-md-6 col-right">                        
                        <div className="box-form-group">
                            <form className="row g-3 needs-validation" novalidate onSubmit={handleSubmit}>
                                <div className="form-group">
                                    <p className={`h3 text-center form-group-title ${darkMode=="dark" ? "color-white" : " color-3060CF"}`}>{t(`authPage.forgotPassword.title`)}</p>
                                </div>                                
                                <div className="form-group">
                                    <p className={`text-center form-group-sub-title ${darkMode=="dark" ? "":"color-black" }`}>{t(`authPage.forgotPassword.guide`)}</p>
                                </div>                            
                                {
                                    !status && 
                                    <>
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-envelope icon-prespend fa-lg"></i>
                                            </div>
                                            <input type="email" className="form-control" placeholder="Email" required/>
                                        </div>
                                    </div>
                                    <div class="row justify-content-md-center">
                                        {/* <button className="btn btn-default btn-auth" > */}
                                        <button className={`btn btn-primary btn-auth ${darkMode=="dark"? "background-00F0FF color-black":"background-3060CF color-white"}`}>
                                            <p>{t(`authPage.forgotPassword.btnSend`)}</p>
                                        </button>
                                    </div>
                                    </>
                                }
                                {
                                    status && <div class="row justify-content-md-center">
                                                <div className="form-group">
                                                    <p className="text-center form-group-success">We’ve sent the password reset instructions to your email if it has been registered with us.</p>
                                                </div>
                                            </div>
                                }                                                                
                            </form>    
                        </div>             
                    </div>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword