import React, { useEffect, useRef } from "react";
import "./index.scss"
import { getAssessmentById, getIeltsExamsAssessment, getMetrics } from "../../../services/assessmentTestService";
import { useState } from "react";
import moment from 'moment'
import ReactPaginate from 'react-paginate';
import Rank from "../../PresenTations/Rank";
import { useNavigate } from "react-router-dom";
import DocumentCreator  from "./DocumentCreator";
import { Packer } from "docx";
import { saveAs } from "file-saver";
import { convert } from "html-to-text";
import pdfHelpers from "../../../services/pdfHelpers";


const AssesmentHistory =()=>{        
    const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {};
    const [dataHistory,setDataHistory] = useState([])
    const [metrics,setMetrics] = useState({})
    const statusGetMetrics = useRef(true)
    // const [datasize,setDatasize] = useState([])
    const statusGetdata = useRef(true)
    const [pageSize,setPageSize] = useState(10)
    const [page,setPage] = useState(1)
    const navigate = useNavigate()

    const getmetric = async ()=>{

        const result = await getMetrics()
        if(result.isSuccess) setMetrics(result.data)
    }
    
    const getHistory = async ()=>{
        const param = [
            `Page=${page}`,
            `PageSize=${pageSize}`,
            // `Filters[0].operator`,
            // `Filters[0].value`,
            // `Filters[0].Field`
        ]
        const history = await getIeltsExamsAssessment(param)
        if(history.isSuccess) setDataHistory(history.data)
    }

    const handlePageClick = (event) => {
        setPage(event.selected+1)
        statusGetdata.current=true
    };
    const handlePageSizeClick = (val) => {
        setPageSize(val)
        statusGetdata.current=true
    };
    const getAnswerExamsByID = (answers)=>{
        const answer = answers.answerExams?.filter((l)=>l.isCorrect === true )
        return answer?.map(x=>x.content)
    }
    const getPointContentByID = (pointContent,id)=>{
        const point = pointContent?.find((e)=>e.questionId === id)
        return point
    }
    const getAnswerContentByID = (answerContent,id,answer,questionType)=>{
        // console.log(answerContent)
        const resultAnswer = answerContent?.find((e)=>e.questionId === id)
        if(questionType==='SingleChoice'){
            const resullt =answer.find((e)=>e.id === resultAnswer?.answerContent )
            return resullt?.content ?? '';
        }else if(questionType==='MultipleChoice'){
            const resullt =answer.filter((e)=>JSON.parse(resultAnswer?.answerContent).indexOf(e.id) != -1 )
            return resullt.map(x => x.content)
        }else if(questionType==='TopicSpeaking' || questionType==='TextSpeaking'){
            // console.log(resultAnswer)
            return `${process.env.REACT_APP_BE_HOST}${resultAnswer?.answerContent ?? ''}`
        }

        return resultAnswer?.answerContent ?? ''
    }

    const handleDownloadClick = async (id) => {
       
        const result = await getAssessmentById(id)
        if(result && result.isSuccess){
            var doc = pdfHelpers.createIeltsResultPdfDocument(result?.data)
            doc.save(`a4_${result?.data?.user?.displayName || result?.data?.user?.email || "Guest"}.pdf` )
        }else{
            alert("Lỗi Không tải được bài thi")
        }
        // if(result && result.isSuccess){
        //     const answerContent = JSON.parse(result.data.answerContent)
        //     const pointContent = JSON.parse(result.data.pointContent)
        //     const questionContent = JSON.parse(result.data.questionContent)
           
        //     let dataSkill=[]
        //     questionContent?.languageSkillExams?.map((skill)=>{
        //         let dataSession=[]  
        //         skill?.partitionExams?.map((e)=>{                                                     
        //             e.sectionExams?.map((i)=>{
        //                 const sectionName = convert(i.description, {});
        //                 let groupQuestionExams = []
        //                 i.groupQuestionExams?.map((j)=>{
        //                     const description = convert(j.description, {});
        //                     // console.log('description---',description)
        //                     // console.log('j---',j)
        //                     let questionExams=[]
        //                     j.questionExams?.map((k)=>{
        //                         const content = convert(k.content, {});
        //                         // console.log('content---',content)
        //                         // console.log('k---',k)
        //                         let question = {
        //                             questionName:content,
        //                             answerExams:getAnswerExamsByID(k),
        //                             answerContent:getAnswerContentByID(answerContent,k.id,k.answerExams,k.questionType),
        //                             point:getPointContentByID(pointContent,k.id)
        //                         }
        //                         // console.log('question---',question)
        //                         questionExams.push(question)
        //                     })
        //                     // console.log('questionExams---',questionExams)
        //                     groupQuestionExams.push(
        //                         {
        //                             description:description.replace('\n'," "),
        //                             questionExams
        //                         }
        //                     )
        //                     // console.log('groupQuestionExams---',groupQuestionExams)
        //                 })
        //                 dataSession.push({
        //                     sectionName,
        //                     groupQuestionExams
        //                 })                        
        //             })
                    
        //         })
        //         dataSkill.push({skillName:skill.languageSkillType,dataSession})
        //     })

        //     // console.log("---------result",result.data)
        //     // console.log("---------dataSkill",dataSkill)
        //     const documentCreator = new DocumentCreator();
        //     const doc = documentCreator.create([dataSkill,result?.data]);
    
        //     Packer.toBlob(doc).then(blob => {
        //         saveAs(blob, `${userInfo?.userName}-FullTest.docx`);
        //         console.log("Document created successfully");
        //     });
        // }else{
        //     alert("Lỗi Không tải được bài thi")
        // }
    };

    useEffect(()=>{
        if(statusGetdata.current){
            statusGetdata.current=false
            getHistory()
        }
    },[pageSize,page])

    useEffect(()=>{
        if(statusGetMetrics.current){
            statusGetMetrics.current=false
            getmetric()
        }
    },[metrics])
    
    return(
        // <div className="col-md-11"> 
            <div className="assesment-history">
                <p className="title" >Welcome back, {userInfo?.userName}</p>
                <div className="row list-box m-0 p-0">
                    <div className="box-detail box-ave">
                        <p className="box-title">Average <br/>Performancep</p>
                        <p className="box-score">{metrics?.averagePoints?.toFixed(2) && <span>IELTS</span>} </p>
                    </div>
                    <div className="box-detail box-att">
                        <p className="box-title">Attemp <br/> Numbers</p>
                        <p className="box-score">{metrics?.totalAttempts}</p>
                    </div>
                    <div className="box-detail box-bes">
                        <p className="box-title">Best  <br/>Performance</p>
                        <p className="box-score">{metrics?.highestPoints && <span>IELTS</span>}</p>
                    </div>
                    <div className="box-detail box-cur">
                        <p className="box-title">Current  <br/>Ranking</p>
                        <p className="box-score"> {`${metrics?.currentRank ?? 0}/${metrics?.totalPersons ?? 0}`} </p>
                    </div>
                    <div className="box-detail box-week">
                        <p className="box-title">Weekly Average Score per Skill</p>
                        <div className="list-skill">
                            <div className="box-detail-small">
                                <p className="box-title">Listening</p>
                                <p className="box-score">{metrics?.listeningAveragePoints ?? 0}</p>
                            </div>
                            <div className="box-detail-small">
                                <p className="box-title">Reading</p>
                                <p className="box-score">{metrics?.readingAveragePoints ?? 0}</p>
                            </div>
                            <div className="box-detail-small">
                                <p className="box-title">Speaking</p>
                                <p className="box-score">{metrics?.speakingAveragePoints ?? 0}</p>
                            </div>
                            <div className="box-detail-small">
                                <p className="box-title">Writing</p>
                                <p className="box-score">{metrics?.writingAveragePoints ?? 0}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row performance-history">
                    <p className="title" >PERFORMANCE HISTORY</p>
                    <table className="table table-striped">
                        <thead>
                            <tr>
                                <th scope="col">No.</th>
                                <th scope="col">Date</th>
                                <th scope="col">Type</th>
                                <th scope="col">Total Score</th>
                                <th scope="col">Listening</th>
                                <th scope="col">Reading</th>
                                <th scope="col">Speaking</th>
                                <th scope="col">Writing</th>
                                <th scope="col">Overall</th>
                                <th scope="col">Ranking</th>
                                <th scope="col"></th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                dataHistory?.items?.map((e,i)=>{
                                    // moment.tz.setDefault("Asia/Ho_Chi_Minh");
                                    // console.log(moment().tz(e.createdAt, "Asia/Ho_Chi_Minh"))
                                    let newDate = e.submitTime ? moment.utc(e.submitTime).tz("Asia/Ho_Chi_Minh").format('HH:mm DD/MM/YYYY') : '' 
                                    console.log("---e",e.testType)
                                    return(
                                        <tr key={i}>
                                            <td>{ page*pageSize-pageSize + 1 +i}</td>
                                            <td style={{width:'50px'}}>{newDate}</td>
                                            <td>{e.testType}</td>
                                            <td className="color-ant"><b>IELTS {e.totalPoints}</b></td>
                                            <td>
                                                {
                                                    (e.testType =="Full" || e.testType =="Short") &&  <>
                                                        <b>{e.listeningPoints > 0 ? (e.listeningPoints)?.toFixed(1) : 0}</b><br/> <i>{e.listeningTriPoints}</i>
                                                    </>
                                                }

                                            </td>
                                            <td>
                                                {
                                                    (e.testType =="Full" || e.testType =="Short") && <>
                                                        <b>{e.readingPoints > 0 ? (e.readingPoints)?.toFixed(1) : 0}</b><br/> <i>{e.readingTriPoints}</i>
                                                    </>
                                                }
                                            </td>
                                            <td><b>{e.speakingPoints > 0 ? (e.speakingPoints)?.toFixed(1) : 0}</b><br/> <i>{e.speakingTriPoints>0 ?e.speakingTriPoints:"" }</i></td>
                                            <td>
                                                {e.writingPoints ?
                                                    <><b>{e.writingPoints > 0 ? (e.writingPoints)?.toFixed(1) : 0}</b><br/> <i>{e.writingTriPoints}</i></>
                                                :
                                                    <i className="color-ccc">"Updating"</i>
                                                }
                                                
                                            </td>
                                            <td><i className="color-ccc">{e.overall ?? "Updating"}</i></td>
                                            <td className="color-ant"><b>N/A</b></td>
                                            <td>
                                                <i className="fa  fa-eye ml-11" onClick={()=>window.open(`/user/assesment/overview/${e.id}`)}></i>
                                                <i className="fa  fa-download ml-1" onClick={()=>handleDownloadClick(e.id)} />
                                                {/* <i className="fa  fa-trash" onClick={()=>alert("Coming soon")}/> */}
                                            </td>
                                        </tr>
                                    )
                                })
                            }
                            {
                                (dataHistory?.items?.length < 1 )&& <tr><td colSpan="11">No data...</td></tr>
                            }
                        </tbody>
                    </table>
                </div>
                <div className="row performance-select d-flex justify-content-between m-0 p-0">
                    <div className="col-md-4 box-select-row m-0 p-0">
                        <div className="select-row">
                            <div className="btn-select">
                                <select className="form-select" aria-label="select page" value={pageSize} onChange={(e)=>handlePageSizeClick(e.target.value)}>        
                                    <option value="10">10</option>
                                    <option value="15">15</option>
                                    <option value="20">20</option>
                                </select>
                            </div>
                            <p>{`Showing rows ${page*pageSize-pageSize + 1} to ${page*pageSize} of ${dataHistory?.total}`}</p>
                        </div>
                    </div>
                    {
                        dataHistory?.items?.length >=1 &&  
                        <div className="col-md-6 box-pagination m-0 p-0">
                            <ReactPaginate
                                breakLabel="..."
                                nextLabel=">"
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={2}
                                pageCount={Math.ceil(dataHistory.total / pageSize)}
                                previousLabel="<"
                                renderOnZeroPageCount={null}
                                containerClassName="paginate"
                                pageLinkClassName="page-num"
                                previousLinkClassName="page-num"
                                nextLinkClassName="page-num"
                                activeLinkClassName="active"
                            />
                        </div>
                    }
                    
                   
                </div>

                <Rank/>
            </div>
        // </div>
    )
}

export default AssesmentHistory