import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Listening from "../PresenTations/Listening";
import { ListenQuestionShorttest } from "../../constants/quiz";
import { ListenQuestion } from "../../constants/fulltest";
import { isDesktop} from "react-device-detect";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";

const ListeningPage=()=>{
    const navigate = useNavigate();
    const skill = localStorage.getItem("skill")
    const darkMode = localStorage.getItem('darkMode') ?? "dark"
    const  referrer = document.referrer
    const nextSkill =()=>{
        navigate("/reading",{ replace: true })
    }
    useEffect(()=>{        
        if(!referrer.indexOf(process.env.REACT_APP_DOMAIN)) localStorage.setItem("referrer",document.referrer)
    },[])
    return(
        <>
        {!isDesktop && <HeaderMB content={"Listening"} nextSkill={nextSkill} section={0} />}
            {/* <div className='content-ielts-wrapper' style={{backgroundImage: `url("/images/listening-bg.png")`}}>  */}
            <div className='content-ielts-wrapper' style={{backgroundImage: isDesktop ? darkMode =="light" ? `url("/images/light/bg-test.png")` : `url("/images/listening-bg.png")` : "none"}}> 
                <Listening nextSkill={nextSkill} />
            </div>
        </>
    )
}
export default ListeningPage