import React, { useEffect, useRef, useState } from 'react'
import "./index.scss"
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import { getStartExam } from '../../services/ieltsService';
import toastifyClient from '../../untils/toastifyClient';

const RealIELTSRoute = () => {
  const [data,setData] = useState();
  const refData = useRef(false);
  const token = localStorage.getItem("token");
  const skill = localStorage.getItem("skill")
  const navigate = useNavigate()

  if(!token) window.location.href = '/listening';

  const getData = async ()=>{
    const stgExam = localStorage.getItem('exam')
    if(stgExam){
      setData(stgExam)
      return
    }
    try {
      const result = await getStartExam({
        "examType":skill==="/shorttest" ? "Short" : "Full",
        "browserId":localStorage.getItem("browserId")
      });
      if(result.isSuccess && result.data) {
        setData(result.data)
        localStorage.setItem('exam',JSON.stringify(result.data))
      }else{
        if(result?.errors[0]?.code == 1){
          toastifyClient.error(result?.errors[0]?.message ?? "error auth");
          navigate("/auth/login")
        }
        if(result?.errors[0]?.code == 2){
          toastifyClient.error(result?.errors[0]?.message ?? "error coin");
          navigate("/")
        }        
      }     
    } catch (error) {
      console.log(error)
    }    
  }
  useEffect(()=>{
    if(!refData.current) {
      refData.current=true
      getData();
    }
  },[])
  return (
    <>
      <div className='container-fluid p-0 content-ielts'>
          {
            data && <Outlet />
          }
      </div>      
    </>
  )
  
}

export default RealIELTSRoute
