import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { SpeQuestionShorttest,  } from "../../constants/quiz";
import { SpeQuestion } from "../../constants/fulltest";
import Speaking from "../PresenTations/Speaking";
import MenuTest from "../Layout/Menu/MenuTest";
import { isDesktop} from "react-device-detect";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";
import SpeakingMb from "../PresenTations/Speaking/speakingMb";

const SpeakingPage=()=>{
    const navigate = useNavigate();
    const skill = localStorage.getItem("skill")
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const menuItem = [
        {item:"listening",link:"/listening"},
        {item:skill === "/shorttest" ? "grammar & reading" :"reading",link:"/reading"},
        {item:"writing",link:"/writing"},
        {item:"speaking",link:"/speaking"}
    ]
    const nextSkill =()=>{
        navigate("/speaking")
    }
    return(
        <>
            <div className='container-fluid p-0 content-ielts'>
                {
                    isDesktop ? <MenuTest active={'/speaking'} menuItem={menuItem} time={480000}/> : <HeaderMB content={"Speaing"} nextSkill={nextSkill} section={0} />
                }                
                <div className='content-ielts-wrapper' style={{backgroundImage: isDesktop ?darkMode =="light" ? `url("/images/light/bg-test.png")` : `url("/images/speaking-bg.png")` : ''}} >
                    { 
                        isDesktop ? <Speaking nextSkill={nextSkill} /> : <SpeakingMb nextSkill={nextSkill} />
                    }
                </div>
            </div>
        </>
    )
}
export default SpeakingPage