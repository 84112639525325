import React, { useEffect, useState } from "react";
import "./index.scss"
import { useParams } from "react-router-dom";
import { getAssessmentById } from "../../../../services/assessmentTestService";
import BoxOverView from "../OverView/BoxOverView";
import HomeMenu from "../../../Layout/Menu/Home";
import SingleChoiceReview from "../../../ComponentShare/SingleChoiceReView";
import WritingReView from "../../../ComponentShare/WritingReView";
import SpeakingReView from "../../../ComponentShare/SpeakingReView";
import FillTheBlankReView from "../../../ComponentShare/FillTheBlankReView";
import MultipleChoiceReview from "../../../ComponentShare/MultipleChoiceReview";
import { externalGradingResults, reviewExamId } from "../../../../services/ieltsService";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";

const AssesmentByIdNew = ()=>{
    const {id} = useParams();
    const [data,setDate] = useState()
    const [answerContent,setAnswerContent] = useState()
    const [pointContent,setPointContent] = useState()
    const [questionContent,setQuestionContent] = useState()
    const [speakingData,setSpeakingData] = useState()

    const getSpeakingContentVsAnswer= (exam)=>{
        const newQuesion =  exam?.questionContent
        // const newQuesion = JSON.parse(questionContent)
        let speakingContent = newQuesion?.languageSkillExams?.filter(e => e.languageSkillType ==='Speaking');
        let speakingQuestion = []
        speakingContent[0]?.partitionExams[0]?.sectionExams?.map((e)=>{
            e.groupQuestionExams?.map((i)=>{
                i.questionExams?.map((j)=>{
                    speakingQuestion.push(j)
                })
            })
        })

        const listSpeakingAnswer = exam?.answerContent?.filter(e=>speakingQuestion.map(j=>j.id).indexOf(e.questionId) != -1 )
    
        return {
            speakingQuestion,listSpeakingAnswer
        }
    }
    const getlevel = (value)=>{
        let dataLevel = [
            {
                min:0,
                max:37,
                level:"Beginner"
            },
            {
                min:38,
                max:50,
                level:"Lower Intermediate"
            },
            {
                min:51,
                max:58,
                level:"Intermediate"
            },
            {
                min:59,
                max:69,
                level:"Upper Intermediate"
            },
            {
                min:70,
                max:80,
                level:"Advanced"
            },
            {
                min:81,
                max:100,
                level:"Native"
            }
        ]
        let findLevel = dataLevel.find((e ) => e.max >= value);
        return findLevel ?? dataLevel[0]
    }
    const performancePro= (score,words,feedback)=>{
        let feedbacks = []
        feedback?.map(e=>{
            feedbacks = feedbacks?.concat(e)
        })
        let level = getlevel(score ?? 1)
        let listFeedback = feedbacks?.map((e)=>{
            return e?.phoneme
        })
        return(
            <>
                <div className='row'>
                    <div className='col-md-6'>
                        <div className='box-performancePro'>
                            <div className='circle-point'>
                                <CircularProgressbar
                                    value={score ?? 0}
                                    text={`${score?? 0}`}
                                    background
                                    strokeWidth={8}                                
                                    styles={buildStyles({
                                        backgroundColor: `none`,
                                        textColor: "#FFFFFF",
                                        pathColor: "#FE9400",
                                        // trailColor: "#060B24",                                                  
                                    })}
                                />
                            </div>
                            <div className='performancePro-right'>
                                <div className='title'>Your Level</div>
                                <div className='content'>{level?.level}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='words-list'>
                    {
                        words.map((e,i)=>{
                            let textColor = "#fff"
                            if(e?.decision==="correct"){
                                textColor = "#00F043"
                            }else if(e?.decision==="almost_correct" || e?.decision ==="warning"){
                                textColor = "#FE9400"
                            }else{
                                textColor = "#FB545C"
                            }
                            return(
                                <span key={i} className='word' style={{color:textColor}}>
                                    {`${e?.text_orig?? e?.text} `}
                                </span>
                            )
                        })
                    }
                </div>              
                {listFeedback?.length >0 &&
                    <div className='box-performancePro-skill '>
                        <div className='title'>Pronunciation by Skill</div>
                        <div className='row'>
                            <div className='col-md-6'>
                                <div className='skill-box'>
                                    <div className='skill-title'>                        
                                        <div className='title-text'>
                                            {`Skill: /${listFeedback?.join("/ , /")}/`}
                                        </div>
                                        <div className='title-note'>
                                            Needs Improvement 
                                        </div>
                                    </div>
                                    <div className='tutorial'>
                                        <div className='tutorial-video'>
                                            <img src='/images/face-video.png' />
                                        </div>
                                        <div className='tutorial-right'>
                                        {`Tutorial for /${listFeedback?.join("/ , /")}/`}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                   
                    </div>
                }
                
            </>
        )
    }

    const performanceFluency = (score,value)=>{
        return(
            <div className='performanceGluency'>
                <div className='performanceGluency-box'>
                    <div className="title">Overview Comment</div>
                    <div className='content'>
                        <p>
                            Look how far you’ve come! You sound like a Native speaker!

                            Heads up! From time to time, you might have to adjust the speed of your delivery to facilitate the comprehension of others. Slowing down can help you be clear in phone conversations or when chatting with someone whose English might not be as advanced as yours!
                        </p>
                    </div>
                </div>
               
                <div className='performanceGluency-box'>
                    <div className="title">Fluency Score</div>
                    <div className='content'>
                       <ul>
                        <li>Pausing Score: <span className='h3'>{score ?? 0}% </span>Native</li>
                        <li>Hesitation: <span>Natural</span></li>
                       </ul>
                    </div>
                </div>
                <div className='performanceGluency-box'>
                    <div className="title">How to improve</div>
                    <div className='content'>
                        <p>
                            Fluency' means being able to control your delivery in a way that feels natural to the setting of a conversation. To improve:

                            Take time to pause at the end of complete sentences or after key ideas.

                            Make sure your speech is constantly moving forward during phrases. Your pace will naturally slow down at the next punctuation mark or logical stop.

                            Never interrupt the flow! It’s common for English learners to stop speaking when searching for a specific word. To avoid interrupting the flow of a conversation, use the language you know to describe a missing concept or idea!
                        </p>
                    </div>
                </div>
            </div>
        )
    }

    const performanceLexical =()=>{
        let vocabulary_score = speakingData?.speakers[0]?.metrics?.general_scores?.elsa?.vocabulary_score
        let level = getlevel(vocabulary_score)
        let cefr_distribution = speakingData?.speakers[0]?.metrics?.other_metrics?.vocabulary?.cefr_distribution
        let feedback = speakingData?.speakers[0]?.feedbacks?.vocabulary?.top_cefr_words
        return(
            <div className='performanceLexical'>
                <div className='box-performancePro'>
                    <div className='circle-point'>
                        <CircularProgressbar
                            value={vocabulary_score ?? 0}
                            text={`${vocabulary_score ?? 0}`}
                            background
                            strokeWidth={10}                                
                            styles={buildStyles({
                                backgroundColor: `none`,
                                textColor: "#FFFFFF",
                                pathColor: "#00B7F0",
                                // trailColor: "#060B24",                                                  
                            })}
                        />
                    </div>
                    <div className='performancePro-right'>
                        <div className='title'>Your Level</div>
                        <div className='content content-lexical'>{level?.level}</div>
                    </div>
                </div>
                <div className='lexical-box'>
                    <div className="title">High-frequency Words</div>
                    <div className='content'>
                        <ul>
                            {
                                feedback?.map((e,i)=>{
                                    return (
                                        <li key={i}>{e?.word ?? ""} ({e?.cefr_level?? 0})
                                            <svg width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <circle cx="8" cy="7.5" r="7.5" fill="#00F0FF"/>
                                                <path d="M12.5 7.79998L6.2 11.4373L6.2 4.16267L12.5 7.79998Z" fill="#0E2A47"/>
                                            </svg>
                                        </li>
                                    )
                                })
                            }                                                       
                        </ul>
                    </div>

                </div>
                <div className='lexical-box'>
                    <div className="title">Vocabulary Distribution</div>
                    <div className='content'>
                        <i>Using advanced Vocabulary (CEFR levels B2, C1 and C2) adds colour and flair to your delivery. </i>
                        <div className='lists-cefr'>
                            {
                                cefr_distribution?.map((e,i)=>{
                                    return(
                                        <div className='ceft-items' key={i}>
                                            <div className={`item item${e?.cefr_level ?? 0}`} >{e?.cefr_level ?? 0}</div>
                                            <div className="progress">
                                                <div className={`progress-bar progress-bar-${e?.cefr_level ?? 0}`} role="progressbar" style={{width:`${e?.percentage ?? 0}%`}} aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                <p className='percent'>{e?.percentage ?? 0}%</p>
                                            </div>
                                        </div>
                                    )

                                })
                            }
                            
                           
                        </div>
                    </div>                    
                </div>
            </div>
        )
    }
    const performanGrammar =(feedback)=>{
        return(
            <div className='performanceLexical'>               
                <div className='lexical-box'>
                    <div className="title">Your Top Grammatical Error(s)</div>
                    <div className='content'>
                        <ul>
                            {
                                feedback?.map((e,i)=>{
                                    return(
                                        <li key={i}>
                                            {e?.original}                          
                                        </li>
                                    )
                                })
                            }                                                      
                        </ul>
                    </div>

                </div>
               
            </div>
        )
    }
    const performanceDelivery =(delivery)=>{
        // console.log(delivery)
        let words_per_minute = delivery?.words_per_minute?? 0
        let percen = words_per_minute*180/(delivery?.words_per_minute_max ?? delivery?.articulated_words_per_minute)
        // console.log(percen)

        return(
            <div className='performanceDelivery'>
                <div className='row justify-content-md-center'>
                    <div className='col-md-6'>
                        <div className='speed'>
                            <div className='speed-volume'>
                                <img src='/images/speed.png' className='speed-volume-png' width={150}/>
                                <img src='/images/speed-retate.png' className='speed-retate-png' width={50} style={{transform:`rotate(${percen}deg)`}} />
                            </div>
                            <div className='m-3'>
                                <span className='h3'>{words_per_minute.toFixed(0) ?? 0}</span> Words/Minutes
                            </div>
                            <div className='m-2 detai-speed'>
                                <ul>
                                    <li><span className='circle'></span>Slurred Speech</li>
                                    <li><span className='circle circle-2'></span>Casual Communication</li>
                                    <li><span className='circle circle-3'></span>Fast Speech</li>
                                </ul>
                            </div>
                        </div>
                        <p className='speaking-shows mt-3'>
                            The speed of speaking shows that you are quite relaxed and confident
                        </p>
                        <img className='chart mt-3' src='/images/chart.png'/>
                        
                        <p className='speaking-shows mt-3'>
                            Adjusting the speaking speed flexibly will make listeners more interested in your speech. Try to practice speaking parts where your voice is faint or too low!
                        </p>                        
                    </div>
                </div>               
            </div>
        )
    }
    const performanceIntonation =(intonation)=>{        
        return(
            <div className='performanceIntonation'>
                {
                    intonation?.map((e,i)=>{
                        let textColor = "#FB545C"
                        if(e?.decision==="correct" || e?.result?.decision==="correct"){
                            textColor = "#00F043"
                        }else if(e?.decision==="almost_correct" || e?.decision ==="warning" || e?.result?.decision==="almost_correct" || e?.result?.decision==="warning"){
                            textColor = "#FE9400"
                        }else{
                            textColor = "#FB545C"
                        }
                        return(
                            <div className='row' key={i}>
                                <div className='intonation-box'>
                                    <div className='intonation-title' style={{color:textColor}}>
                                       {e?.text?? e?.sentence}
                                    </div>
                                    <div className='pronounce'>
                                       <i>/ {e?.result?.ipa ?? e?.ipa } /</i> 
                                    </div>
                                    {/* <div className='media'>
                                        <ul>
                                            <li>1</li>
                                            <li>2</li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        )
    }
    const getDataByID = async (id)=> {
        if(!id) return
        const result = await getAssessmentById(id)
        console.log(result)
        if(result && result.isSuccess){
            setAnswerContent(result.data.answerContent)
            setPointContent(result.data.pointContent)
            setQuestionContent(result.data.questionContent)
            setDate(result.data)


            //// speaking data
            const resultReview = await reviewExamId(id);
            if(resultReview.isSuccess){
                let speakingContentVsAnswer = getSpeakingContentVsAnswer(resultReview?.data)
                const resultSpeaking = await externalGradingResults(id)
                
                setSpeakingData({
                    "question":speakingContentVsAnswer?.speakingQuestion,
                    "answer":speakingContentVsAnswer?.listSpeakingAnswer,
                    "detail":resultSpeaking?? null,
                })
                
            }
        }else{
            
        }
    }

    const getPointContentByID = (id)=>{
        const point = pointContent?.find((e)=>e.questionId === id)
        return point ? Number(point)?.toFixed(1) : 0
    }
    const getAnswerContentByID = (id,answer,questionType)=>{
        
        const resultAnswer = answerContent?.find((e)=>e.questionId === id)
        if(questionType==='SingleChoice'){
            const resullt =answer.find((e)=>e.id === resultAnswer?.answerContent )
            return resullt
        }else if(questionType==='MultipleChoice' && resultAnswer?.answerContent){
            const resullt =answer.filter((e)=>JSON.parse(resultAnswer?.answerContent).indexOf(e.id) != -1 )
            return resullt.map(x => x.content).join('<br/>')
        }
        else if(questionType==='TopicSpeaking' || questionType==='TextSpeaking'){
            return `<audio className="w-100" src="${resultAnswer?.answerContent ? process.env.REACT_APP_BE_HOST+resultAnswer?.answerContent : ''}" controls="controls"  ></audio>`
        } 

        return resultAnswer?.answerContent ?? ''
    }
    useEffect(()=>{
        getDataByID(id)
    },[])
    return(
        <div className="container-fluid p-0 assesment-by-id">

            <HomeMenu/>
            <div className="row justify-content-center">
                <div className="col-md-6">
                    {data && <BoxOverView data={data}/>}
                    <div className="row skill-detail mt-4 p-0">
                        {
                            questionContent?.languageSkillExams?.map((e)=>{
                                if(e?.languageSkillType =="Speaking"){        
                                    return(
                                        <div className="skill-type" key={e.id}>
                                            <div className=" skill-header">
                                                <div className="boder-bottom">
                                                    <div className="progress" >
                                                        <div className="progress-bar" role="progressbar" style={{"width": "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="header-title">
                                                    <p>{e.languageSkillType} core details </p>
                                                    <i>Chi tiết điểm {e.languageSkillType}</i>                                                
                                                </div>
                                                <div className="boder-bottom">
                                                    <div className="progress" >
                                                        <div className="progress-bar" role="progressbar" style={{"width": "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="skill-content">
                                            {
                                                speakingData?.answer?.map(e=>{
                                                    let question = speakingData?.question?.find(j=>j.id === e.questionId)
                                                    let speakingdetail = speakingData?.detail?.data?.find(k=>k.questionExamId === e.questionId)
                                                    speakingdetail = speakingdetail?.responseData ?? null
                                                    speakingdetail = JSON.parse(speakingdetail)
                                                    let pronunciationScore = 0,fluencyScore =0
                                                    let words = [],feedbacks = [],intonation=[],grammar=[],delivery
                                                    if(question?.questionType ==='TextSpeaking'){
                                                        pronunciationScore = speakingdetail?.utterance[0]?.nativeness_score ?? 0
                                                        fluencyScore = speakingdetail?.utterance[0]?.fluency_score ?? 0
                                                        words = speakingdetail?.utterance[0]?.words ?? []
                                                        intonation.push(speakingdetail?.utterance[0])
                                                        delivery = speakingdetail?.utterance[0]?.speed_metrics?? null
                                                    }else{
                                                        pronunciationScore = speakingdetail?.speakers[0]?.metrics?.general_scores?.elsa?.pronunciation_score ?? 0   
                                                        fluencyScore = speakingdetail?.speakers[0]?.metrics?.general_scores?.elsa?.fluency_score ?? 0   
                                                        speakingdetail?.speakers[0]?.utterances?.map(i=>{
                                                            words = words?.concat(i.result?.words)
                                                            feedbacks = speakingdetail?.speakers[0]?.feedbacks?.pronunciation?.top_errors                      
                                                        })
                                                        intonation = speakingdetail?.speakers[0]?.utterances
                                                        grammar = speakingdetail?.speakers[0]?.feedbacks?.grammar?.items ?? []
                                                        delivery = speakingdetail?.speakers[0]?.metrics?.other_metrics?.fluency ?? null
                                                    }
                                                    return(
                                                        <div className='row m-0 mt-5' key={e.questionId}>
                                                            <div className='performance-left'>
                                                                <div className='row'>
                                                                    <div className='col-md-6'>
                                                                        <div className='audio'>
                                                                            <audio src={`${process.env.REACT_APP_BE_HOST+e.answerContent}`} controls></audio>
                                                                            <p>Watch your video</p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className='box-performance-left mt-3'>
                                                                    {/* <p dangerouslySetInnerHTML={{__html:question?.content}}></p> */}
                                                                    <div className='box-performance '>
                                                                    <p dangerouslySetInnerHTML={{__html:question?.content}}></p>
                                                                    </div>
                                                                </div>
                                                            
                                                            </div>
                                                            <div className='row accordion-box'>
                                                                <div className="accordion accordion-flush" id={e.questionId}>
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header" id={`${e.questionId}-headingPro`}>
                                                                            <div className="accordion-button collapsed" data-bs-toggle="collapse" data-bs-target={`#${e.questionId}-collapsePro`} aria-expanded="false" aria-controls={`${e.questionId}-collapsePro`}>
                                                                                <span>Pronunciation</span>
                                                                            </div>
                                                                        </h2>
                                                                        <div id={`${e.questionId}-collapsePro`} className="accordion-collapse collapse" aria-labelledby={`${e.questionId}-headingPro`} data-bs-parent={`#${e.questionId}`}>
                                                                            <div className="accordion-body">
                                                                                { performancePro(pronunciationScore.toFixed(0),words,feedbacks)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header" id={`${e.questionId}-headingFlu`}>
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e.questionId}-collapseFlu`} aria-expanded="false" aria-controls={`${e.questionId}-collapseFlu`}>
                                                                                Fluency & Coherence
                                                                            </button>
                                                                        </h2>
                                                                        <div id={`${e.questionId}-collapseFlu`} className="accordion-collapse collapse" aria-labelledby={`${e.questionId}-headingFlu`} data-bs-parent={`#${e.questionId}`}>
                                                                            <div className="accordion-body">
                                                                                {performanceFluency(fluencyScore.toFixed(0),1)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header" id={`${e.questionId}-headingInt`}>
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e.questionId}-collapseInt`} aria-expanded="false" aria-controls={`${e.questionId}-collapseInt`}>
                                                                            Intonation
                                                                        </button>
                                                                        </h2>
                                                                        <div id={`${e.questionId}-collapseInt`} className="accordion-collapse collapse" aria-labelledby={`${e.questionId}-headingInt`} data-bs-parent={`#${e.questionId}`}>
                                                                            <div className="accordion-body">
                                                                                {performanceIntonation(intonation)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    {
                                                                        question.questionType ==='TopicSpeaking' &&  
                                                                        <div className="accordion-item">
                                                                            <h2 className="accordion-header" id={`${e.questionId}-headingGra`}>
                                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e.questionId}-collapseGra`} aria-expanded="false" aria-controls={`${e.questionId}-collapseGra`}>
                                                                                Grammar
                                                                            </button>
                                                                            </h2>
                                                                            <div id={`${e.questionId}-collapseGra`} className="accordion-collapse collapse" aria-labelledby={`${e.questionId}-headingGra`} data-bs-parent={`#${e.questionId}`}>
                                                                                <div className="accordion-body">
                                                                                    {performanGrammar(grammar)}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                
                                                                    <div className="accordion-item">
                                                                        <h2 className="accordion-header" id={`${e.questionId}-headingDel`}>
                                                                        <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={`#${e.questionId}-collapseDel`} aria-expanded="false" aria-controls={`${e.questionId}-collapseDel`}>
                                                                            Delivery
                                                                        </button>
                                                                        </h2>
                                                                        <div id={`${e.questionId}-collapseDel`} className="accordion-collapse collapse" aria-labelledby={`${e.questionId}-headingDel`} data-bs-parent={`#${e.questionId}`}>
                                                                            <div className="accordion-body">
                                                                                {performanceDelivery(delivery)}
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        
                                                        </div>
                                                    )
                                                })
                                            }
                                            </div>
                                        </div>
                                    )
                                }else{
                                    return(
                                        <div className="skill-type" key={e.id}>
                                            <div className=" skill-header">
                                                <div className="boder-bottom">
                                                    <div className="progress" >
                                                        <div className="progress-bar" role="progressbar" style={{"width": "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                                <div className="header-title">
                                                    <p>{e.languageSkillType} core details </p>
                                                    <i>Chi tiết điểm {e.languageSkillType}</i>                                                
                                                </div>
                                                <div className="boder-bottom">
                                                    <div className="progress" >
                                                        <div className="progress-bar" role="progressbar" style={{"width": "100%"}} aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="skill-content">
                                                {
                                                    e.partitionExams?.map((i)=>{
                                                        return(
                                                            <div key={i?.id}>
                                                            {
                                                                i.sectionExams?.map((j)=>{
                                                                    
                                                                    return(
                                                                        <div className="session mt-5" key={j?.id}>
                                                                            <p className="session-title" dangerouslySetInnerHTML={{__html:j.description}}></p>
                                                                            {
                                                                                j.groupQuestionExams?.map((t)=>{
                                                                                    
                                                                                    return(
                                                                                        <div className="group-question mt-5" key={t?.id}>
                                                                                        <div className="description" dangerouslySetInnerHTML={{__html:t.description}}></div>
                                                                                        {
                                                                                            t.questionExams?.map((k)=>{
                                                                                                let point = getPointContentByID(k.id)
                                                                                                let answerUser = getAnswerContentByID(k.id,k.answerExams,k.questionType);
                                                                                                if(k?.questionType == "SingleChoice" || k?.questionType == "MultipleChoice") return <SingleChoiceReview point={point} data={k} answer={answerUser} key={k?.id}/>
                                                                                                if(k?.questionType == "Writing") return <WritingReView point={point} data={k} answer={answerUser} key={k?.id}/>
                                                                                                if(k?.questionType == "TextSpeaking" || k?.questionType == "TopicSpeaking" ) return <SpeakingReView point={point} data={k} answer={answerUser} key={k?.id} speakingData={speakingData}/>
                                                                                                if(k?.questionType == "FillTheBlank" ) return <FillTheBlankReView point={point} data={k} answer={answerUser} key={k?.id}/>                                                                                            
                                                                                                
                                                                                            })
                                                                                        }
                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    )
                                                                })
                                                            }
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>   
                                    )
                                }
                               
                            })
                        }
                    </div>   
                </div>
                         
            </div> 
        </div>
    )
}

export default AssesmentByIdNew