import React from "react";
import "./index.scss"
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar';
import { useTranslation } from "react-i18next";
import { scoreToAnalysis } from "../../../../../constants/scoreToAnalysis";

const BoxOverView = ({data})=>{
    const { t } = useTranslation();

    const returnOverall = (score)=>{
        
        let detailText =scoreToAnalysis.overall.find(e=>e.max == parseFloat(Math.round(score)) ) ?? scoreToAnalysis.overall[0]

        return t(`${detailText.analysis}`)
    }
    return(                      
        <div className="row box-overview">
            <p className="overview-title">{t(`overview.title`)}</p>
            <p className="overview-note" dangerouslySetInnerHTML={{__html:returnOverall(data?.overall ?? 0)}}></p>
            <div className='row mt-5 p-2'>
                { (data?.testType =="AISpeakingAdvanced" || data?.testType =="AISpeakingBasic")  ?
                    <div className='col-md-6 box-score'>
                        <div className='score-circle'>
                            <CircularProgressbar
                                value={(data?.speakingPoints*10 ?? 0 )}
                                text={`${data?.speakingPoints ?? 0 }`}
                                background
                                strokeWidth={8}
                                // backgroundPadding={3}
                                
                                styles={buildStyles({
                                    backgroundColor: `none`,
                                    textColor: "#FFFFFF",
                                    pathColor: "#00F0FF",
                                    trailColor: "#FFFFFF",   
                                    // strokeLinecap: "butt"                                                     
                                })}
                            />
                        </div>
                        <div className='score-skill'>
                            <div className='skill-name'>
                                <p>Speaking</p> 
                            </div>
                            <div className='skill-score-detail'>
                                <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswerWriting`)}<span>{ data?.speakingTriPoints ?? 0 }</span> </p> 
                            </div>
                        </div>
                    </div> 
                    :
                    <>                    
                        <div className='col-md-6 box-score'>
                            <div className='score-circle'>
                                <CircularProgressbar
                                    value={(data?.listeningPoints*10 ?? 0 )}
                                    text={`${data?.listeningPoints ?? 0 }`}
                                    background
                                    strokeWidth={8}
                                    // backgroundPadding={3}
                                    
                                    styles={buildStyles({
                                        backgroundColor: `none`,
                                        textColor: "#FFFFFF",
                                        pathColor: "#00F0FF",
                                        trailColor: "#FFFFFF",   
                                        // strokeLinecap: "butt"                                                     
                                    })}
                                />
                            </div>
                            <div className='score-skill'>
                                <div className='skill-name'>
                                    <p>Listening</p> 
                                </div>
                                <div className='skill-score-detail'>
                                    <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswer`)} <span> { data?.listeningTriPoints ?? 0 }</span></p> 
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 box-score'>
                            <div className='score-circle'>
                                <CircularProgressbar
                                    value={(data?.readingPoints*10 ?? 0 )}
                                    text={`${data?.readingPoints ?? 0 }`}
                                    background
                                    strokeWidth={8}
                                    // backgroundPadding={3}
                                    
                                    styles={buildStyles({
                                        backgroundColor: `none`,
                                        textColor: "#FFFFFF",
                                        pathColor: "#00F0FF",
                                        trailColor: "#FFFFFF",   
                                        // strokeLinecap: "butt"                                                     
                                    })}
                                />
                            </div>
                            <div className='score-skill'>
                                <div className='skill-name'>
                                    <p>Reading</p> 
                                </div>
                                <div className='skill-score-detail'>
                                    <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswer`)} <span>{ data?.readingTriPoints ?? 0 }</span></p> 
                                </div>
                            </div>
                        </div>                          
                        <div className='col-md-6 box-score'>
                            <div className='score-circle'>
                                <CircularProgressbar
                                    value={(data?.writingPoints*10 ?? 0)}
                                    text={`${data?.writingPoints ?? 0}`}
                                    background
                                    strokeWidth={8}
                                    // backgroundPadding={3}
                                    
                                    styles={buildStyles({
                                        backgroundColor: `none`,
                                        textColor: "#FFFFFF",
                                        pathColor: "#00F0FF",
                                        trailColor: "#FFFFFF",                                                  
                                    })}
                                />
                            </div>
                            <div className='score-skill'>
                                <div className='skill-name'>
                                    <p>Writing</p> 
                                </div>
                                <div className='skill-score-detail'>
                                    <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswerWriting`)}<span>{ data?.writingTriPoints ?? 0 }</span></p> 
                                </div>
                            </div>
                        </div> 
                        <div className='col-md-6 box-score'>
                            <div className='score-circle'>
                                <CircularProgressbar
                                    value={(data?.speakingPoints*10 ?? 0 )}
                                    text={`${data?.speakingPoints ?? 0 }`}
                                    background
                                    strokeWidth={8}
                                    // backgroundPadding={3}
                                    
                                    styles={buildStyles({
                                        backgroundColor: `none`,
                                        textColor: "#FFFFFF",
                                        pathColor: "#00F0FF",
                                        trailColor: "#FFFFFF",   
                                        // strokeLinecap: "butt"                                                     
                                    })}
                                />
                            </div>
                            <div className='score-skill'>
                                <div className='skill-name'>
                                    <p>Speaking</p> 
                                </div>
                                <div className='skill-score-detail'>
                                    <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswerWriting`)}<span>{ data?.speakingTriPoints ?? 0 }</span> </p> 
                                </div>
                            </div>
                        </div> 
                    </>
                }
               
            </div>
        </div>
    )
}

export default BoxOverView
