export const ListenQuestion = [
    {   
        audio:"/fulltest/audio/section1.mp3",
        content:[
            {
                title:"Questions 1-5",
                request:"Complete the sentences.",
                guide:"Write <b> NO MORE THAN TWO WORDS for each answer.</b>",
                example:"Peter consumes far too much <u><b><i>soft drink</i></b></u> ",
                type:"ListenWriteAnswer",
                question: [
                    {
                        id:"1",
                        name:"<u>Question 1:</u> Peter and Mary’s diet will be both sensible and ..........",
                        correct:"practical",
                        score:1
                    },
                    {
                        id:"2",
                        name:"<u>Question 2:</u> Every two months, they can eat ..........",
                        correct:"pizza",
                        score:1

                    },
                    {
                        id:"3",
                        name:"<u>Question 3:</u> On Saturdays, they will go ..........",
                        correct:"walking",
                        score:1

                    },
                    {
                        id:"4",
                        name:"<u>Question 4:</u> This coming Saturday, they will go to ..........",
                        correct:"Pine Park",
                        score:1

                    },
                    {
                        id:"5",
                        name:"<u>Question 5:</u> In every meal, there will be ..........",
                        correct:"fruit juice",
                        score:1

                    }
                ]
            },
            {
                title:"Questions 6-10",
                request:"Choose the correct letter, C, I, or B.",
                content:[
                    ["","Peter’s opinion","Mary’s opinion"],
                    ["Tuesdays","B","6……………"],
                    ["Thursdays","7……………","8……………"],
                    ["Sundays","9……………","10……………"]
                ],               
                listAnswer:[
                    {
                        key:"C",
                        value:"Chocolate",
                    },
                    {
                        key:"I",
                        value:"Ice cream",
                    },
                    {
                        key:"B",
                        value:"Biscuits",
                    },
                ],
                type:"QuestionSelectAnswer",
                question: [
                    {
                        id:"6",
                        name:"<u>Question 6:",            
                        correct:"B",
                        score:1

                    },
                    {
                        id:"7",
                        name:"<u>Question 7:",
                        correct:"I",
                        score:1

                    },
                    {
                        id:"8",
                        name:"<u>Question 8:",
                        correct:"C",
                        score:1

                    },
                    {
                        id:"9",
                        name:"<u>Question 9:",
                        correct:"B",
                        score:1

                    },
                    {
                        id:"10",
                        name:"<u>Question 10:",
                        correct:"I",
                        score:1

                    }
                ]
            }
        ]
    },
    {   
        audio:"/fulltest/audio/section2.mp3",
        content:[
            {                
                title:"Questions 11 – 16",
                request:"Complete the sentences.",
                guide:"Write <b> NO MORE THAN TWO WORDS for each answer.</b>",
                content:[
                    ["","Detail","Another Detail"],
                    ["1","Walls are 11…………….","includes the 12……………."],
                    ["2","Teachers are able to teach a 13…………….","There are many special 14……………"],
                    ["3","They 15……………. Surfaces daily.","procedures to limit the spread of 16……………"]
                ],
                type:"ListenWriteAnswer",
                question: [
                    {
                        id:"11",
                        name:"<u>Question 11:</u> ",
                        correct:"padded",
                        score:1

                    },
                    {
                        id:"12",
                        name:"<u>Question 12:</u>",
                        correct:"special skill",
                        score:1

                    },
                    {
                        id:"13",
                        name:"<u>Question 13:</u> ",
                        correct:"disinfect",
                        score:1

                    },
                    {
                        id:"14",
                        name:"<u>Question 14:</u>",
                        correct:"corners",
                        score:1

                    },
                    {
                        id:"15",
                        name:"<u>Question 15:</u> ",
                        correct:"learning toys",
                        score:1

                    },
                    {
                        id:"16",
                        name:"<u>Question 16:</u> ",
                        correct:"germs",
                        score:1

                    }
                ]
            },
            {
                title:"Questions 17 – 20",
                request:"Choose the correct letter, A, B, or C.",
                guide:"<b>NB</b> <i>You may use a letter more than once.</i>",
                example:"<b>Which childcare worker:</b>",
                type:"QuestionSelectAnswer",
                listAnswer:[
                    {
                        key:"A",
                        value:"Andrea",
                    },
                    {
                        key:"B",
                        value:"Bella",
                    },
                    {
                        key:"C",
                        value:"Cathy",
                    },
                ],
                question: [
                    {
                        id:"17",
                        name:"<u>Question 17:</u> has her own children?",
                        correct:"C",
                        score:1

                    },
                    {
                        id:"18",
                        name:"<u>Question 18:</u> often sleeps at the center?",
                        correct:"A",
                        score:1

                    },
                    {
                        id:"19",
                        name:"<u>Question 19:</u> is good with shy children?",
                        correct:"C",
                        score:1

                    },
                    {
                        id:"20",
                        name:"<u>Question 20:</u>  is a good cook?",
                        correct:"B",
                        score:1

                    },                   
                ]
            }          
        ]
    },
    {
        audio:"/fulltest/audio/section3.mp3",
        content:[
            {
                title:"Questions 21 – 25",  
                request:"Complete the flowchart.", 
                guide:"Write ONE WORD ONLY for each answer",
                example:"<b>Foundation for Essay Writing</b>",
                type:"ListenWriteAnswer",
                question:[
                    {
                        id:"21",
                        name:"<u>Question 21:</u> Decide on ……………. you like.",
                        correct:"topic",
                        score:1

                    },
                    {
                        id:"22",
                        name:"<u>Question 22:</u>Focus on ……………. area of interest.",
                        correct:"current",
                        score:1

                    },
                    {
                        id:"23",
                        name:"<u>Question 23:</u> Write ……………. statement.",
                        correct:"thesis",
                        score:1

                    },
                    {
                        id:"24",
                        name:"<u>Question 24:</u> Create …………….",
                        correct:"outline",
                        score:1

                    },
                    {
                        id:"25",
                        name:"<u>Question 25:</u> Ensure this ……………. clearly.",
                        correct:"progress",
                        score:1

                    },
                ]
            },
            {
                title:"Questions 26 – 30",  
                request:"Complete the summary.", 
                guide:"Write <b>NO MORE THAN TWO WORDS OR A NUMBER</b> for each answer.",
                content:[["There are several <b>26</b>……………. involved in producing a good essay. The writer must think independently and give <b>27</b>……………. examples as support, each one with a reference (which is a <b>28</b>…………….). The formatting must follow the <b>29</b>……………. issued by the university, as well as the word count decided by the lecturer, although it can vary by <b>30</b>……………."]],
                type:"ListenWriteAnswer",
                question:[
                    {
                        id:"26",
                        name:"<u>Question 26:</u>",
                        correct:"aspects",
                        score:1

                    },
                    {
                        id:"27",
                        name:"<u>Question 27:</u>",
                        correct:"real-life",
                        score:1

                    },
                    {
                        id:"28",
                        name:"<u>Question 28:</u>",
                        correct:"necessity",
                        score:1

                    },
                    {
                        id:"29",
                        name:"<u>Question 29:</u>",
                        correct:"style guide",
                        score:1

                    },
                    {
                        id:"30",
                        name:"<u>Question 30:</u> ",
                        correct:"10%",
                        score:1

                    },
                ]
            }
        ]
    },
    {
        audio:"/fulltest/audio/section4.mp3",
        content:[
            {
                title:"Questions 31 – 33",  
                request:"Choose the correct letter, A, B, or C",
                type:"ListenChoiceAnswer",
                question:[
                    {
                        id:"31",
                        name:"Question 31: Geocentrism",
                        option:[
                            'A. has a long history',
                            'B. is similar to heliocentrism.',
                            'C. took some time to be deduced.',
                        ],
                        correct:"A. has a long history",
                        score:1

                    },
                    {
                        id:"32",
                        name:"Question 32: Heliocentrism",
                        option:[
                            'A. was realised only recently.',
                            'B. was not generally accepted.',
                            'C. fitted the views of the church.',
                        ],
                        correct:"B. was not generally accepted.",
                        score:1

                    },
                    {
                        id:"33",
                        name:"Question 33: The night sky",
                        option:[
                            'A. is relatively simple.',
                            'B. remains basically the same.',
                            'C. was once used for navigation.',
                        ],
                        correct:"C. was once used for navigation.",
                        score:1

                    },
                ]
            },
            {
                title:"Questions 34 – 40",  
                request:"Complete the notes.",
                guide:"Write ONE WORD OR A NUMBER for each answer.",
                content:[
                    ["Nicolaus Copernicus","started the Copernican 34…………….his book published the year he was 35……………. and then passed awayThis book generated little 36…………….opinion"],
                    ["Other scientists","Galileo was persecuted.37……………. was killed.even the sun believed to be in 38……………."],
                    ["Modern geocentrism","based on the 39…………….Believers also support creationism. 40……………. of Americans believe in this."],
                ],
                type:"ListenWriteAnswer",
                question:[
                    {
                        id:"34",
                        name:"<u>Question 34:</u>",
                        correct:"revolution",
                        score:1

                    },
                    {
                        id:"35",
                        name:"<u>Question 35:</u>",
                        correct:"sick",
                        score:1

                    },
                    {
                        id:"36",
                        name:"<u>Question 36:</u>",
                        correct:"attention",
                        score:1

                    },
                    {
                        id:"37",
                        name:"<u>Question 37:</u>",
                        correct:"bruno",
                        score:1

                    },
                    {
                        id:"38",
                        name:"<u>Question 38:</u>",
                        correct:"motion",
                        score:1

                    },
                    {
                        id:"39",
                        name:"<u>Question 39:</u>",
                        correct:"Bible",
                        score:1

                    },
                    {
                        id:"40",
                        name:"<u>Question 40:</u>",
                        correct:"20%",
                        score:1

                    },
                ]
            }
        ]
    }
]

export const ReadingQuestion = [
    {   
        title:`BRITAIN'S CANALS - THE SOLUTION TO OVERCROWDED ROADS?`,
        text:`<b>A.</b> It's hard to imagine that only a decade or so ago many of the nation's canals were little more than the last resting place for abandoned shopping trolleys. There's still work to be done, but their transformation has been remarkable. Projects such as Castlefield in Manchester and Brindley Place in Birmingham have transformed city-centre canals from stagnant reminders of a fading industrial past to the epitome of urban cool. However, 21st-century priorities dictate that the rehabilitation of this 18th-century motorway system cannot stop there. Canals and navigable rivers form a major transport network, in need of only piecemeal investment, and with the spare capacity to take away the need for hundreds of thousands of lorry journeys. In the second half of the 18th century, canals drove the industrial revolution. Today, authorities want them to drive congestion off the roads. Last month, for example, the European Commission proposed a seven-year plan to shift large amounts of freight from roads to inland waterways. <br><br>
        <b>B.</b> Europe's enthusiasm comes as no surprise. Freight traffic is expected to grow by a third in the next decade. The cost of pollution and congestion is set to swallow one per cent of Europe's entire GDP by 2010. "With a fleet of 11000 vessels and a capacity equalling 10000 trains or 440000 trucks, inland waterways can make transport in Europe more efficient, reliable and environmental friendly," says Jacques Barrot, vice-president of the European Commission in charge of transport. "Europe cannot afford to leave that potential untapped." <br><br>
        <b>C.</b> Mainland Europe has never, in fairness, left it completely untapped. The canals of the low countries and the rivers of central and eastern Europe buzzed with the sound of freight barges long after British industry had thrown in its lot with railways and roads. Attempts to revive freight on British canals have been hampered by the fact that their heyday lasted barely 60 years, and they were first considered obsolete 150 years ago. For much of the intervening period, many have simply been left to rot. "Our network was in decline for a long time compared to much of Europe," says Eugene Baston of British Waterways. "Whereas other countries developed road and rail transport but carried on using their waterways as well, our canals were neglected. In fact many European countries actually enlarged their canals 100 years ago." <br><br>
        <b>D.</b> That decline in Britain has been reversed, first by leisure seekers and more recently by industry. Boaters, anglers, walkers and cyclists now benefit from around 4000 miles of navigable waterways and the paths and trails that run alongside them. Waterside living is fashionable, and city-centre canals have been a focus for urban renewal, And, despite our obsession with road transport, environmental considerations are forcing government and business to mm the clock back 200 years and--at least in a minor way--get our waterways working again. <br><br>
        <b>E.</b> In fact, industrial goods such as coal, steel, aggregates and petroleum have never completely disappeared from large rivers and designated commercial waterways. Barges on the river Severn have recently started carrying the equivalent of 34000 lorry loads of aggregates each year, the first freight traffic on the river for a decade. British Waterways, which owns about half of the country's navigable inland waterways, carded the equivalent of 64000 25-ton lorry loads of freight in 2004. The organisation says these figures are certain to increase as new schemes start, and environmentalists hope they will. Carrying freight by water uses about a quarter of the energy of an equivalent road journey. In comparison to lorries, barges produce low emissions, low noise and are visually unobtrusive. "We think that anything that can take freight off the roads needs to be fully explored," says Tony Bosworth, transport campaigner for Friends of the Earth. "Canals can do that. They can help cut the pollution that causes climate change." <br><br>
        <b>F.</b> There is a limit to what canals can carry. The slow pace of water travel does not fit well with the limited shelf-life of fresh produce. If supermarkets won't trust their cherry tomatoes to water, they might trust the waste paper and plastic that protects them. Many of the proposals to utilise Britain's waterways are based around waste management and recycling schemes. For example, a pilot scheme in Hackney, east London, has seen municipal waste collected by truck and transferred to barge for transportation to a reprocessing plant. In the future, the scheme could remove 300000 dustcart miles from the borough's streets every year. Current arrangements could be just the tip of the iceberg. <br><br>
        <b>G.</b> Planning permission has been given for a Powerday recycling plant at Willesden Junction, a site that sits on the intersection of road, rail and canal networks. "The plant will have the capacity to handle a million and a half tons of waste every year, but the amount carried by road will be capped at 500000 tons," says Ed Fox of British Waterways London. "If they want to grow the business, they will have to work with us." Fox says getting freight back on the canals has been "a nice idea" for 50 years, but until recently little more than an idea. "The Powerday project, on the other hand, is proof of what really can be done." <br><br>
        <b>H.</b> Though details have yet to be decided, British Waterways believes the most appropriate way to transport some of the building materials destined for London's giant Olympic construction project is by the network of waterways that links the Thames and east London. The Olympic Delivery Authority says: "It's being looked at and the final solution could well involve some transportation by water. What exactly we do will be based on a range of factors, but one of those will be sustainability." Their gentle pace will always make canals a niche player in a busy world, but after 200 years of neglect, the tide is starting to turn. <br><br>
        `,
        request:"You should spend about 20 minutes on Questions 1-13, which are based on Reading Passage 1 below.",
        content:[
            {
                title:"Question 1-4",
                guide:`<i>The text has 8 paragraphs (A-H).<br>
                        Which paragraph does each of the following headings best fit? </i>`,
                type:"WriteAnswer",
                question:[
                    {
                        id:"1",
                        name:"<u>Question 1:</u> __________ Olympic transport",
                        correct:"h",
                        score:1
                    },
                    {
                        id:"2",
                        name:"<u>Question 2:</u> __________ The decline of British canals",
                        correct:"c",
                        score:1
                    },
                    {
                        id:"3",
                        name:"<u>Question 3:</u> __________ Modern leisure uses",
                        correct:"d",
                        score:1
                    },
                    {
                        id:"4",
                        name:"<u>Question 4:</u>  __________ Energy efficient",
                        correct:"e",
                        score:1
                    }
                ]   
            },
            {
                title:"Question 5-9", 
                guide:"In boxes 5 – 9, write <b>TRUE</b> if the statement agrees with the information. ",
                request:"According to the text, FIVE of the following statements are true.",
                content:[
                    ["A","Canals were important in the industrial revolution in Britain."],
                    ["B","The use of canals in Europe is expected to grow by a third over the next ten years."],
                    ["C","Britain was the only European country to let its canals decline."],
                    ["D","Canals in Britain have become a focus for city-centre renewal."],
                    ["E","Barges are less polluting than lorries."],
                    ["F","In London, most waste is transported on canals."],
                    ["G","The amount of waste that can be taken to the Powerday plant by road is limited."],
                    ["H","Canals will not become a major form of transport in the world."],
                ],
                type:"SelectChoiceAnswer",
                listAnswer:[
                    {
                        key:"A",
                        value:"A",
                    },
                    {
                        key:"B",
                        value:"B",
                    },
                    {
                        key:"C",
                        value:"C",
                    },                    
                    {
                        key:"D",
                        value:"D",
                    },
                    {
                        key:"E",
                        value:"E",
                    },
                    {
                        key:"F",
                        value:"F",
                    },
                    {
                        key:"G",
                        value:"G",
                    },
                    {
                        key:"H",
                        value:"H",
                    }
                ],
                question:[
                    {
                        id:"5",
                        name:"Question 5:",
                        correct:"A",
                        score:1
                    },
                    {
                        id:"6",
                        name:"Question 6:",
                        correct:"D",
                        score:1
                    },
                    {
                        id:"7",
                        name:"Question 7:",
                        correct:"E",
                        score:1
                    },
                    {
                        id:"8",
                        name:"Question 8:",
                        correct:"G",
                        score:1
                    },
                    {
                        id:"9",
                        name:"Question 9:",
                        correct:"H",
                        score:1
                    }
                ]
            },
            {
                title:"Question 10-13", 
                guide:"Choose the correct letter, A. B or D",
                request:"Write the correct letter in boxes 10 – 13 on your answer sheet",
                type:"ChoiceAnswer",
                question:[
                    {
                        id:"10",
                        name:"Question 10: Geocentrism",
                        option:[
                            'A. relatively little investment.',
                            'B. considerable investment.',
                            `C. investment equal to 1% of Europe's transport budget.`,
                        ],
                        correct:"A. relatively little investment.",
                        score:1
                    },
                    {
                        id:"11",
                        name:"Question 11:The European canal network",
                        option:[
                            `A. transported most of Europe's goods for 60 years.`,
                            `B. was built mostly about 150 years ago.`,
                            `C. has actually been expanded in some countries over the last hundred years.`,
                        ],
                        correct:"C. has actually been expanded in some countries over the last hundred years.",
                        score:1
                    },
                    {
                        id:"12",
                        name:"Question 12:Suitable products for transportation by canal include",
                        option:[
                            `A. fresh fruit and vegetables`,
                            `B. fuel`,
                            `C. cereal`,
                        ],
                        correct:"B. fuel",
                        score:1
                    },
                    {
                        id:"13",
                        name:"Question 13:How much waste can Powerday recycling plant treat in one year?",
                        option:[
                            `A. 1.5 million tons`,
                            `B. 500 tons`,
                            `C. 15 million tons`,
                        ],
                        correct:"A. 1.5 million tons",
                        score:1
                    }
                ]
            }
            
        ]
    },
    {
        title:"THE TRUTH ABOUT LYING",
        text:`Over the years Richard Wiseman has tried to unravel the truth about deception - investigating the signs that give away a liar.
            A - Do only humans lie?
            In the 1970s, as part of a large-scale research programme exploring the area of Interspecies communication, Dr Francine Patterson from Stanford University attempted to teach two lowland gorillas called Michael and Koko a simplified version of Sign Language. According to Patterson, the great apes were capable of holding meaningful conversations, and could even reflect upon profound topics, such as love and death. During the project, their trainers believe they uncovered instances where the two gorillas' linguistic skills seemed to provide reliable evidence of intentional deceit. In one example, Koko broke a toy cat, and then signed to indicate that the breakage had been caused by one of her trainers.
            In another episode, Michael ripped a jacket belonging to a trainer and, when asked who was responsible for the incident, signed ‘Koko’. When the trainer expressed some scepticism, Michael appeared to change his mind, and indicated that Dr Patterson was actually responsible, before finally confessing.
            B - When do we begin to lie?
            Other researchers have explored the development of deception in children. Some of the most interesting experiments have involved asking youngsters not to take a peek at their favourite toys. During these studies, a child is led into a laboratory and asked to face one of the walls. The experimenter then explains that he is going to set up an elaborate toy a few feet behind them. After setting up the toy, the experimenter says that he has to leave the laboratory, and asks the child not to turn around and peek at the toy. The child is secretly filmed by hidden cameras for a few minutes, and then the experimenter returns and asks them whether they peeked. Almost all three-year do, and then half of them lie about it to the experimenter. By the time the children have reached the age of five, all of them peek and all of them lie. The results provide compelling evidence that lying starts to emerge the moment we learn to speak.
            C - A public test of our ability to spot a lie
            So what are the tell-tale signs that give away a lie? In 1994, the psychologist Richard Wiseman devised a large-scale experiment on a TV programme called Tomorrow's World. As part of the experiment, viewers watched two interviews in which Wiseman asked a presenter in front of the cameras to describe his favourite film. In one interview, the presenter picked Some Like It Hot and he told the truth; in the other interview, he picked Gone with the Wind and lied. The viewers were then invited to make a choice - to telephone in to say which film he was lying about. More than 30,000 calls were received, but viewers were unable to tell the difference and the vote was a 50/50 split. In similar experiments, the results have been remarkably consistent - when it comes to lie detection, people might as well simply toss a coin. It doesn’t matter if you are male or female, young or old; very few people are able to detect deception.
            D - Exposing some false beliefs
            Why is this? Professor Charles Bond from the Texas Christian University has conducted surveys into the sorts of behaviour people associate with lying. He has interviewed thousands of people from more than 60 countries, asking them to describe how they set about telling whether someone is lying. People’s answers are remarkably consistent. Almost everyone thinks liars tend to avert their gaze, nervously wave their hands around and shift about in their seats. There is, however, one small problem. Researchers have spent hour upon hour carefully comparing films of liars and truth-tellers. The results are clear. Liars do not necessarily look away from you; they do not appear nervous and move their hands around or shift about in their seats. People fail to detect lies because they are basing their opinions on behaviours that are not actually associated with deception.
            E - Some of the things liars really do
            So what are we missing? It is obvious that the more information you give away, the greater the chances of some of it coming back to haunt you. As a result, liars tend to say less and provide fewer details than truth-tellers. Looking back at the transcripts of the interviews with the presenter, his lie about Gone with the Wind contained about 40 words, whereas the truth about Some Like It Hot was nearly twice as long. People who lie also try psychologically to keep a distance from their falsehoods, and so tend to include fewer references to themselves in their stories. In his entire interview about Gone with the Wind, the presenter only once mentioned how the film made him feel, compared with the several references to his feelings when he talked about Some Like It Hot.
            F - Which form of communication best exposes a lie?
            The simple fact is that the real clues to deceit are in the words that people use, not the body language. So do people become better lie detectors when they listen to a liar, or even just read a transcript of their comments? The interviews with the presenter were also broadcast on radio and published in a newspaper, and although the lie-detecting abilities of the television viewers were no better than chance, the newspaper readers were correct 64% of the time, and the radio listeners scored an impressive 73% accuracy rate.
            `,
        request:`You should spend about 20 minutes on Questions 14 - 26, which are based on Reading Passage 2 below. `,
        content:[
            {
                title:"Questions 14 -19", 
                guide:"The reading passage has six paragraphs, A-F.",
                request:"Choose the correct heading for each paragraph from the list of headings below.",
                content:[
                    ["i","Some of the things liars really do"],
                    ["ii","When do we begin to lie?"],
                    ["iii"," How wrong is it to lie?"],
                    ["iv","Exposing some false beliefs"],
                    ["v","Which form of communication best exposes a lie?"],
                    ["vi","Do only humans lie?"],
                    ["vii","Dealing with known liars"],
                    ["viii","A public test of our ability to spot a lie"]
                ],
                type:"SelectChoiceAnswer",
                listAnswer:[
                    {
                        key:"i",
                        value:"i",
                    },
                    {
                        key:"ii",
                        value:"ii",
                    },
                    {
                        key:"ii",
                        value:"iii",
                    },
                    {
                        key:"iv",
                        value:"iv",
                    },
                    {
                        key:"v",
                        value:"v",
                    },
                    {
                        key:"vi",
                        value:"vi",
                    },
                    {
                        key:"vii",
                        value:"vii",
                    },
                    {
                        key:"viii",
                        value:"viii",
                    }
                ],
                question:[
                    {
                        id:"14",
                        name:"Question 14: __________ Paragraph A",
                        correct:"vi",
                        score:1
                    },
                    {
                        id:"15",
                        name:"Question 15: __________ Paragraph B",
                        correct:"ii",
                        score:1
                    },
                    {
                        id:"16",
                        name:"Question 16: __________ Paragraph C",
                        correct:"viii",
                        score:1
                    },
                    {
                        id:"17",
                        name:"Question 17: __________ Paragraph D",
                        correct:"iv",
                        score:1
                    },
                    {
                        id:"18",
                        name:"Question 18: __________ Paragraph E",
                        correct:"i",
                        score:1
                    },
                    {
                        id:"19",
                        name:"Question 19: __________ Paragraph F",
                        correct:"v",
                        score:1
                    }
                ]
            },
            {
                title:"Questions 20-23", 
                guide:"Look at the following statements and the list of experiments below.",
                request:`Match each statement with the correct experiment, A-C.<br>
                You may use any letter more than once`,
                content:[
                    ["List of Experiments"],
                    ["A.  the gorilla experiment"],
                    ["B. the experiment with children"],
                    ["C. the TV experiment"]
                ],
                type:"SelectChoiceAnswer",
                listAnswer:[
                    {
                        key:"A",
                        value:"A",
                    },
                    {
                        key:"B",
                        value:"B",
                    },
                    {
                        key:"C",
                        value:"C",
                    }
                ],
                question:[
                    {
                        id:"20",
                        name:"Question 20: __________ Someone who was innocent was blamed for something",
                        correct:"A",
                        score:1
                    },
                    {
                        id:"21",
                        name:"Question 21: __________ Those involved knew they were being filmed.",
                        correct:"C",
                        score:1
                    },
                    {
                        id:"22",
                        name:"Question 22: __________ Some objects were damaged.",
                        correct:"A",
                        score:1
                    },
                    {
                        id:"23",
                        name:"Question 23: __________ Some instructions were ignored.",
                        correct:"B",
                        score:1
                    }
                ]
            },
            {
                title:"Questions 24-26", 
                guide:"Choose ONE WORD ONLY from the passage for each answer.",
                request:`Complete the sentences below.`,
                type:"WriteAnswer",                
                question:[
                    {
                        id:"24",
                        name:"<u>Question 24:</u> Filming liars has shown that they do not display __________ behaviour.",
                        correct:"nervous",
                        score:1
                    },
                    {
                        id:"25",
                        name:"<u>Question 25:</u> Liars tend to avoid talking about their own __________.",
                        correct:"feelings",
                        score:1
                    },
                    {
                        id:"26",
                        name:"<u>Question 26:</u> Signs of lying are exposed in people’s __________ rather than their movements.",
                        correct:"words",
                        score:1
                    }
                ] 
            },
        ]
    },
    {
        title:"MOTIVATING DRIVES",
        text:`Scientists have been researching the way to get employees motivated for many years. This research in a relational study which builds the fundamental and comprehensive model for study. This is especially true when the business goal is to turn unmotivated teams into productive ones. But their researchers have limitations. It is like studying the movements of car without taking out the engine.
        Motivation is what drives people to succeed and plays a vital role in enhancing an organizational development. It is important to study the motivation of employees because it is related to the emotion and behavior of employees. Recent studies show there are four drives for motivation. They are the drive to acquire, the drive to bond, the drive to comprehend and the drive to defend.
        The Drive to Acquire
        The drive to acquire must be met to optimize the acquire aspect as well as the achievement element. Thus the way that outstanding performance is recognized, the type of perks that is provided to polish the career path. But sometimes a written letter of appreciation generates more motivation than a thousand dollar check, which can serve as the invisible power to boost business engagement. Successful organizations and leaders not only need to focus on the optimization of physical reward but also on moving other levers within the organization that can drive motivation.
        The Drive to Bond
        The drive to bond is also key to driving motivation. There are many kinds of bonds between people, like friendship, family. In company, employees also want to be an essential part of company. They want to belong to the company. Employees will be motivated if they find personal belonging to the company. In the meantime, the most commitment will be achieved by the employee on condition that the force of motivation within the employee affects the direction, intensity and persistence of decision and behavior in company.
        The Drive to Comprehend
        The drive to comprehend motivates many employees to higher performance. For years, it has been known that setting stretch goals can greatly impact performance. Organizations need to ensure that the various job roles provide employees with simulation that challenges them or allow them to grow. Employees don’t want to do meaningless things or monotonous job. If the job didn’t provide them with personal meaning and fulfillment, they will leave the company.
        
        The Drive to Defend
        The drive to defend is often the hardest lever to pull. This drive manifests itself as a quest to create and promote justice, fairness, and the ability to express ourselves freely. The organizational lever for this basic human motivator is resource allocation. This drive is also met through an employee feeling connection to a company. If their companies are merged with another, they will show worries.
        Two studies have been done to find the relations between the four drives and motivation. The article based on two studies was finally published in Harvard Business Review. Most authors’ arguments have laid emphasis on four-drive theory and actual investigations. Using the results of the surveys which executed with employees from Fortune 500 companies and other two global businesses (P company and H company), the article mentions about how independent drives influence employees’ behavior and how organizational levers boost employee motivation.
        The studies show that the drive to bond is most related to fulfilling commitment, while the drive to comprehend is most related to how much effort employees spend on works. The drive to acquire can be satisfied by a rewarding system which ties rewards to performances, and gives the best people opportunities for advancement. For drive to defend, a study on the merging of P company and H company shows that employees in former company show an unusual cooperating attitude.
        The key to successfully motivate employees is to meet all drives. Each of these drives is important if we are to understand employee motivation. These four drives, while not necessarily the only human drives, are the ones that are central to unified understanding of modern human life.
        `,
        request:"You should spend about 20 minutes on Questions 27-40, which are based on Reading Passage 3 below",
        content:[
            {
                title:"Questions 27-31", 
                guide:"Choose the correct letter A, B, C or D",
                request:"Write the correct letter in boxes 1-5 on your answer sheet.",
                type:"ChoiceAnswer",
                question:[
                    {
                        id:"27",
                        name:"Question 27: According to the passage, what are we told about the study of motivation?",
                        option:[
                            'A. The theory of motivating employees is starting to catch attention in organizations in recent years.',
                            'B. It is very important for managers to know how to motivate their subordinates because it is related to the salary of employees.',
                            `C. Researchers have tended to be too theoretical to their study.`,
                            `D. The goal of employee motivation is to increase the profit of organizations.`
                        ],
                        correct:"c. researchers have tended to be too theoretical to their study.",
                        score:1
                    },
                    {
                        id:"28",
                        name:"Question 28: What can be inferred from the passage about the study of people’s drives?",
                        option:[
                            `A. Satisfying employees’ drives can positively lead to the change of behavior.`,
                            `B. Satisfying employees’ drives will negatively affect their emotions.`,
                            `C. Satisfying employees’ drives can increase companies’ productions.`,
                            `D. Satisfying employees’ drives will result in employees’ outstanding performance.`
                        ],
                        correct:"A. Satisfying employees’ drives can positively lead to the change of behavior.",
                        score:1
                    },
                    {
                        id:"29",
                        name:"Question 29: According to paragraph three, in order to optimize employees’ performance, __________ are needed.",
                        option:[
                            `A. Drive to acquire and achievement element`,
                            `B. Outstanding performance and recognition`,
                            `C. Career fulfillment and a thousand dollar check`,
                            `D. Financial incentive and recognition`
                        ],
                        correct:"D. Financial incentive and recognition",
                        score:1
                    },
                    {
                        id:"30",
                        name:"Question 30: According to paragraph five, how does “the drive to comprehend” help employees perform better?",
                        option:[
                            `A. It can help employees better understand the development of their organizations.`,
                            `B. It can help employees feel their task in meaningful to their companies.`,
                            `C. It can help employees set higher goals.`,
                            `D. It can provide employees with repetitive tasks.`
                        ],
                        correct:"B. It can help employees feel their task in meaningful to their companies.",
                        score:1
                    },
                    {
                        id:"31",
                        name:"Question 31: According to paragraph six, which of following is true about “drive to defend”?",
                        option:[
                            `A. Organizational resource is the most difficult to allocate.`,
                            `B. It is more difficult to implement than the drive to comprehend.`,
                            `C. Employees think it is very important to voice their own opinions.`,
                            `D. Employees think it is very important to connect with a merged corporation.`
                        ],
                        correct:"C. Employees think it is very important to voice their own opinions.",
                        score:1
                    }
                ]
            },
            {
                title:"Questions 32-34", 
                guide:`Choose THREE letters, A-F.`,
                detail:`<i>Write the correct letters in boxes 6-8 on your answer sheet.
                Which THREE of the following statements are true of study of drives?</i>`,
                content:[
                    ["A","Employees will be motivated if they feel belonged to the company."],
                    ["B","If employees get an opportunity of training and development program, their motivation will be enhanced."],
                    ["C","If employees working goals are complied with organizational objectives, their motivation will be reinforced."],
                    ["D","If employees motivation in very low, companies should find a way to increase their salary as their first priority."],
                    ["E","If employees find their work lacking challenging, they will leave the company."],
                    ["F","Employees will worry if their company is sold."]
                ],
                type:"SelectChoiceAnswer",
                listAnswer:[
                    {
                        key:"A",
                        value:"A",
                    },
                    {
                        key:"B",
                        value:"B",
                    },
                    {
                        key:"C",
                        value:"C",
                    },                    
                    {
                        key:"D",
                        value:"D",
                    },
                    {
                        key:"E",
                        value:"E",
                    },
                    {
                        key:"F",
                        value:"F",
                    }
                ],
                question:[
                    {
                        id:"32",
                        name:"Question 32:",
                        correct:"A",
                        score:1
                    },
                    {
                        id:"33",
                        name:"Question 33:",
                        correct:"E",
                        score:1
                    },
                    {
                        id:"34",
                        name:"Question 34:",
                        correct:"F",
                        score:1
                    }
                ]
            },
            {
                title:"Questions 35-40", 
                guide:`In boxes 35-40 on your answer sheet, write`,
                detail:`<i>Do the following statements agree with the claims of the writer in Reading Passage?</i>`,
                content:[
                    ["YES ","if the statement agree with the claims of the writer"],
                    ["NO","if the statement contradicts the claims of the writer"],
                    ["NOT GIVEN","if it is impossible to say what the writer thinks about this"]
                ],
                type:"SelectChoiceAnswer",
                listAnswer:[
                    {
                        key:"YES",
                        value:"YES",
                    },
                    {
                        key:"NO",
                        value:"NO",
                    },
                    {
                        key:"NOT GIVEN",
                        value:"NOT GIVEN",
                    }
                ],
                question:[
                    {
                        id:"35",
                        name:"Question 35: Increasing pay can lead to the high work motivation.",
                        correct:"NO",
                        score:1
                    },
                    {
                        id:"36",
                        name:"Question 36: Local companies benefit more from global companies through the study.",
                        correct:"NOT GIVEN",
                        score:1
                    },
                    {
                        id:"37",
                        name:"Question 37: Employees achieve the most commitment if their drive to comprehend is met.",
                        correct:"NO",
                        score:1
                    },
                    {
                        id:"38",
                        name:"Question 38: The employees in former company presented unusual attitude toward the merging of two companies.",
                        correct:"YES",
                        score:1
                    },
                    {
                        id:"39",
                        name:"Question 39: The two studies are done to analyze the relationship between the natural drives and the attitude of employees.",
                        correct:"NO",
                        score:1
                    },
                    {
                        id:"40",
                        name:"Question 40: Rewarding system cause the company to lose profit",
                        correct:"NOT GIVEN",
                        score:1
                    }
                ]
            }
        ]
    }
]
export const SpeQuestion = [
    {
        url:'https://api.languageconfidence.ai/rvcim17uu8jot3u7/speech-assessment/scripted/us',
        request:"Read and repeat the below passage:",
        content:'Modern science says: ‘The Sun is the past, the Earth is the present, the moon is the future.” From an incandescent mass we have originated, and into a frozen mass, we shall turn. Merciless is the law of nature, and rapidly and irresistibly we are drawn to our doom.'
    },
    {
        url:'https://api.languageconfidence.ai/rvcim17uu8jot3u7/speech-assessment/unscripted/us',
        topic:"Describe A Place You Visited Where The Air Was Polluted",
        request:"Talk about the below topic. You have to talk about the topic for 1 to 2 minutes",
        content:'',
        youSay:`
            <div class="you-say">
                You should say:
                <ul>
                    <li>Where the place is</li>
                    <li>When you visited.</li>
                    <li>Why the air was not good.</li>
                </ul>
                and explain how you felt about the place.
            </div>`
    },
    {
        url:'https://api.languageconfidence.ai/rvcim17uu8jot3u7/speech-assessment/unscripted/us',
        request:"Answer this question no more than 1 minute",
        content:'',
        question:[
            "Do you agree with the statement that companies will only protect the environment if they are forced to do so?",
            "What do you think about the statement that the best way to protect the environment is to leave it alone?",
            "Is there more pollution now than in the past?",
            "In what ways can the air pollution be reduced effectively?"
        ]
    }
]

export const WritingQuestion = [
    {
        request:"You should spend about 40 minutes on this task.",
        content:`The two tables below show the exchange students from universities in Europe to Australia between 2007 and 2009.<br>
        Summaries the information by selecting and reporting the main features, and make comparisons where relevant.`,
        topic:"It is the responsibility of schools to teach children good behavior in addition to providing formal education. To what extent do you agree or disagree?",
        guide:"You should write at least 250 words.",
        img:"/fulltest/img/writing1.PNG"

    },
    {
        request:"You should spend about 40 minutes on this task.",
        topic:"It is the responsibility of schools to teach children good behavior in addition to providing formal education. To what extent do you agree or disagree?",
        guide:"You should write at least 250 words.",

    }
]