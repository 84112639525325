import React, { useEffect, useRef, useState } from 'react'
import './welldoneLight.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { autoCalculatePoint, submitExam } from '../../services/ieltsService'
import MenuWCLight from '../Layout/Menu/MenuLight1'
import { getIeltsExamsAssessment } from '../../services/assessmentTestService'
import VoucherModal from '../ComponentShare/Modal/VoucherModal'

const WelldoneLight = (props) => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [openVoucher,setOpenVoucher] = useState(false)
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    const subSkill = localStorage.getItem("subSkill");
    const skill = localStorage.getItem("skill");

    const getHistory = async ()=>{
        if(!userInfo?.id) return
        const param = [
            `Page=1`,
            `PageSize=100`,
        ]
        const history = await getIeltsExamsAssessment(param)        
        if(!history || !history.isSuccess) return
        const filterAiSpeaing = history?.data?.items?.filter((e)=>e.testType =="AISpeakingBasic" || e.testType =="AISpeakingAdvanced")
        if(filterAiSpeaing.length ==1) setOpenVoucher(true)

    }
    useEffect(()=>{
        // if(statusGetdata.current){
        //     statusGetdata.current=false
            getHistory()
        // }
    },[])
    console.log("skill",skill)
    return (
        <>   
            <MenuWCLight />
            {openVoucher && <VoucherModal setOpen={setOpenVoucher} /> }        
            <div className='welldone-light'>
                <div className='row w-100 d-flex justify-content-center' >    
                    <div className='col-md-6 d-flex justify-content-center flex-column'>               
                        <img className='wd-img' src='/images/light/youdidit.png' />
                        <p className='h2 title text-center mt-3'><span>{t(`welldone.title`)}</span></p>
                        <p className='detail text-center color-black '>{t(`welldone.thankyou`)} <span >{userInfo.userName} </span></p>
                        <p className='detail text-center color-black mt-1'>{(skill === "/speakingai" && subSkill !="") ? t(`welldone.successfullyAi`) :t(`welldone.successfully`) }</p>
                        {
                            skill != "/speaking" && <p className='detail text-center color-black'  ><span dangerouslySetInnerHTML={{ __html: t(`welldone.result`)}}></span></p>
                        }
                        
                        {
                            props.complete?
                            <div className='row mt-4 justify-content-center d-flex flex-nowrap p-0 m-0'>
                                <button className='btn btn-result ' onClick={()=>navigate("/")}><b>{t(`welldone.btnResultback`)}</b></button>
                                <button className='btn btn-register ' onClick={()=>props.handleSubmit()}><b>Xem kết quả ngay</b></button>
                                
                            </div>
                            :
                            <div className='row mt-4 justify-content-center d-flex flex-nowrap p-0 m-0'>
                                <button className='btn btn-result ' onClick={()=>navigate("/")}><b>{t(`welldone.btnResultback`)}</b></button>
                                <button className='btn btn-register btn-loading' disabled >
                                    <div className="spinner-border" role="status">
                                        <span className="sr-only">Đang chấm bài...</span>
                                    </div>   
                                    
                                    <b>Đang chấm bài...</b>
                                </button>
                            </div>
                        }
                        
                    </div>
                </div>
            </div>
        </>
    )
}
export default WelldoneLight