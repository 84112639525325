import jsPDF from 'jspdf'
import autoTable, { Styles, UserOptions } from 'jspdf-autotable'
import moment from 'moment'

const defaultStyleAutoTable = { font: 'Calibri', fontStyle: 'normal' }

const IELTS_SCORES = {
  READING: {
    SCORE: [
      {
        fromAnt: 0,
        toAnt: 5,
        from: 0,
        to: 2.5,
        en: 'Can understand familiar names, words and very simple sentences in very short, simple texts.',
        vi: 'Có thể hiểu các tên quen thuộc, các từ và các câu rất đơn giản trong các văn bản rất ngắn và đơn giản.',
      },
      {
        fromAnt: 6,
        toAnt: 9,
        from: 3,
        to: 3.5,
        en: 'Can understand very short, simple texts.',
        vi: 'Có thể hiểu các văn bản rất ngắn, đơn giản.',
      },
      {
        fromAnt: 10,
        toAnt: 14,
        from: 4,
        to: 4.5,
        en: 'Can understand short, uncomplicated texts using mainly everyday or work-related language.',
        vi: 'Có thể hiểu các văn bản ngắn, không phức tạp, chủ yếu sử dụng ngôn ngữ hàng ngày hoặc liên quan đến công việc.',
      },
      {
        fromAnt: 15,
        toAnt: 29,
        from: 5,
        to: 6.5,
        en: 'Can understand texts that contain frequently used vocabulary about familiar subjects.',
        vi: 'Có thể hiểu các văn bản chứa từ vựng thường được sử dụng về các chủ đề quen thuộc.',
      },
      {
        fromAnt: 30,
        toAnt: 40,
        from: 7,
        to: 9,
        en: 'Can understand long and complex texts from a wide range of settings, on both familiar and unfamiliar topics.',
        vi: 'Có thể hiểu các văn bản dài và phức tạp từ nhiều bối cảnh khác nhau, về các chủ đề quen thuộc và không quen thuộc.',
      },
    ],
  },
  LISTENING: {
    SCORE: [
      {
        fromAnt: 0,
        toAnt: 5,
        from: 0,
        to: 2.5,
        en: 'Can recognise familiar words and very basic phrases from slow, clear speech.',
        vi: 'Có thể nhận ra những từ quen thuộc và những cụm từ rất cơ bản khi nói chậm, rõ ràng.',
      },
      {
        fromAnt: 6,
        toAnt: 9,
        from: 3,
        to: 3.5,
        en: 'Can understand the main points of short, clear, slow speech.',
        vi: 'Có thể hiểu ý chính của bài nói ngắn, rõ ràng, chậm rãi.',
      },
      {
        fromAnt: 10,
        toAnt: 12,
        from: 4,
        to: 4,
        en: 'Can understand the main ideas of clear, standard speech on familiar subjects encountered in daily life.',
        vi: 'Có thể hiểu ý chính của bài phát biểu rõ ràng, chuẩn mực về các chủ đề quen thuộc trong cuộc sống hàng ngày.',
      },
      {
        fromAnt: 13,
        toAnt: 29,
        from: 4.5,
        to: 6.5,
        en: 'Can understand complex spoken language on reasonably familiar topics and in a standard dialect.',
        vi: 'Có thể hiểu ngôn ngữ nói phức tạp về các chủ đề tương đối quen thuộc và bằng phương ngữ chuẩn.',
      },
      {
        fromAnt: 30,
        toAnt: 40,
        from: 7,
        to: 9,
        en: 'Can understand complex spoken language on both familiar and unfamiliar topics.',
        vi: 'Có thể hiểu ngôn ngữ nói phức tạp về các chủ đề quen thuộc và không quen thuộc.',
      },
    ],
  },
  OVERALL: {
    SCORE: [
      {
        from: 0,
        to: 2.5,
        en: `Your score indicates that your level of English is in the range of A1 Beginner, according to the guidelines set by the Common European Framework of Reference (CEFR).\nYou have great difficulty understanding spoken and written English.`,
        vi: `Điểm số cho thấy trình độ tiếng Anh của bạn thuộc cấp A1 Cơ bản, theo Khung Tham chiếu Chung Châu Âu (CEFR).\nBạn gặp nhiều khó khăn trong việc nói và viết tiếng Anh.`,
      },
      {
        from: 3,
        to: 3.5,
        en: `Your score indicates that your level of English is in the range of A2 Elementary, according to the guidelines set by the Common European Framework of Reference (CEFR).\nYou convey and understand only general meaning in very familiar situations. There are frequent breakdowns in communication.`,
        vi: `Điểm số cho thấy trình độ tiếng Anh của bạn thuộc cấp A2 Sơ cấp, theo Khung Tham chiếu Chung Châu Âu (CEFR).Bạn có thể sử dụng tiếng Anh trong một vài tình huống quen thuộc. Thường gặp khó khăn trong các cuộc giao tiếp bình thường.`,
      },
      {
        from: 4,
        to: 5,
        en: `Your score indicates that your level of English is in the range of B1 Intermediate, according to the guidelines set by the Common European Framework of Reference (CEFR).\nYour basic competence is limited to familiar situations. You frequently show problems in understanding and expression. You are not able to use complex language.`,
        vi: `Điểm số cho thấy trình độ tiếng Anh của bạn thuộc cấp B1 Trung cấp, theo Khung Tham chiếu Chung Châu Âu (CEFR).\nBạn có sự thành thạo cơ bản khi sử dụng tiếng Anh ở những tình huống cụ thể. Tuy nhiên, ở mức độ tình huống hay môi trường phức tạp hơn, bạn sẽ có một số khó khăn trong việc sử dụng ngôn ngữ phức tạp.`,
      },
      {
        from: 5.5,
        to: 6.5,
        en: `Your score indicates that your level of English is in the range of B2 Upper intermediate, according to the guidelines set by the Common European Framework of Reference (CEFR).\nYou have a partial command of the language, and cope with overall meaning in most situations, although you are likely to make many mistakes. You should be able to handle basic communication in your own field.`,
        vi: `Điểm số cho thấy trình độ tiếng Anh của bạn thuộc cấp B2 Trung cao cấp, theo Khung Tham chiếu Chung Châu Âu (CEFR).\nBạn có thể sử dụng được một phần ngôn ngữ. Có khả năng nắm được ý nghĩa tổng quát trong phần lớn các tình huống mặc dù còn hay mắc lỗi. Có thể sử dụng ngôn ngữ trong lĩnh vực quen thuộc của mình.`,
      },
      {
        from: 7,
        to: 8,
        en: `Your score indicates that your level of English is in the range of C1 Advanced, according to the guidelines set by the Common European Framework of Reference (CEFR).\nYou have an operational command of the language, though with occasional inaccuracies, inappropriate usage and misunderstandings in some situations. Generally you handle complex language well and understand detailed reasoning.`,
        vi: `Điểm số cho thấy trình độ tiếng Anh của bạn thuộc cấp C1 Cao cấp, theo Khung Tham chiếu Chung Châu Âu (CEFR).\nBạn nắm vững ngôn ngữ nhưng đôi khi có sự không chính xác và khó hiểu trong một số tình huống. Tóm lại là sử dụng tốt những ngôn ngữ phức tạp và hiểu được lý lẽ tinh vi.`,
      },
      {
        from: 8.5,
        to: 9,
        en: `Your score indicates that your level of English is in the range of C2 Proficient, according to the guidelines set by the Common European Framework of Reference (CEFR).\nYou have a full operational command of the language. Your use of English is appropriate, accurate and fluent, and you show complete understanding.`,
        vi: `Điểm số cho thấy trình độ tiếng Anh của bạn thuộc cấp C2 Thành thạo, theo Khung Tham chiếu Chung Châu Âu (CEFR).\nBạn đã hoàn toàn thành thạo và nắm vững ngôn ngữ, cách thể hiện chính xác, lưu loát và tinh vi.`,
      },
    ],
  },
}

const createIeltsResultPdfDocument = (item) => {
  const pointContent = item?.pointContent ?? {}
  const skillsContent = item?.questionContent?.languageSkillExams ?? []
  const answerContent = item?.answerContent ?? []
  var doc = new jsPDF({
    format: 'a4',
  })
  doc.addFont('../../assets/fonts/calibri.ttf', 'Calibri', 'normal')
  doc.addFont('../../assets/fonts/calibrib.ttf', 'Calibri', 'bold')
  const defaultFont = doc.getFont()
  const defaultFontList = doc.getFontList()
  const defaultFontSize = 12
  const titleFontSize = 24
  const subTitleFontSize = 14
  // console.log('Font info', defaultFontList, defaultFont, defaultFontSize)
  doc.setFont('Calibri', 'bold')
  doc.setFontSize(titleFontSize)
  doc.text('KẾT QUẢ BÀI THI', 105, 20, { align: 'center' })

  doc.setFont('Calibri', 'normal')
  doc.setFontSize(subTitleFontSize)
  doc.text('- Full test -', 105, 30, { align: 'center' })

  doc.setFontSize(defaultFontSize)
  doc.text(`Họ và tên học viên: ${item?.user?.displayName || item?.user?.email || 'Guest'}`, 20, 40)
  doc.text(
    `Ngày làm bài: ${item?.submitTime ? moment(item.submitTime).format('DD-MM-yyyy HH:mm') : ''}`,
    20,
    50,
  )

  doc.setFontSize(subTitleFontSize)
  doc.text('TỔNG QUAN', 105, 60, { align: 'center' })

  const summaryColumns = [
    { header: 'Listening', dataKey: 'Listening' },
    { header: 'Reading', dataKey: 'Reading' },
    { header: 'Speaking', dataKey: 'Speaking' },
    { header: 'Writing', dataKey: 'Writing' },
  ]

  const detailColumns = [
    { header: 'Câu', dataKey: 'index' },
    { header: 'Đáp án', dataKey: 'correctAnswer' },
    { header: 'Câu trả lời của bạn', dataKey: 'choiceAnswer' },
    { header: 'Điểm', dataKey: 'points' },
  ]

  const summaryBody = [
    {
      Listening: item?.listeningTriPoints,
      Reading: item?.readingTriPoints,
      Speaking: item?.speakingTriPoints,
      Writing: item?.writingTriPoints,
    },
  ]

  let summaryContent = mapPointToSummaryReview('overall', item?.overall)
  let summaryFooter = [[{ content: summaryContent, colSpan: 4 }]]
  //Add table summary
  addTable(doc, summaryBody, summaryColumns, {
    startY: 70,
    theme: 'grid',
    foot: summaryFooter,
    footStyles: { fillColor: '#4472C4', cellPadding: 3 },
  })

  addText(doc, 'CHI TIẾT', { halign: 'center', fontSize: subTitleFontSize })

  skillsContent.forEach((skill) => {
    switch (skill?.languageSkillType?.toLowerCase()) {
      case 'listening':
      case 'reading':
        const questions = skill?.partitionExams
          .flatMap((p) => p.sectionExams)
          .flatMap((p) => p.groupQuestionExams)
          .flatMap((p) => p.questionExams)
        const skillData = questions.map((p, index) => {
          const aContent = answerContent?.find((a) => a.questionId === p.id)?.answerContent
          const point = pointContent?.find((a) => a.questionId === p.id)
          const choiceAnswer =
            p.questionType === 'FillTheBlank'
              ? aContent
              : p?.answerExams
                  ?.filter((a) =>
                    p.questionType === 'SingleChoice'
                      ? aContent === a.id
                      : aContent
                        ? JSON.parse(aContent).includes(a.id)
                        : false,
                  )
                  .map((k) => k?.content)
                  ?.join('\n')
          return {
            index: index + 1,
            correctAnswer: p?.answerExams
              ?.filter((a) => a.isCorrect)
              ?.map((c) => c.content)
              ?.join('\n'),
            choiceAnswer: choiceAnswer,
            questionType: p?.questionType,
            points: point?.points,
            markAsCorrect: point?.markAsCorrect,
          }
        })
        let reviewContent = mapPointToSummaryReview(
          skill?.languageSkillType?.toLowerCase(),
          item?.[`${skill?.languageSkillType?.toLowerCase()}Points`],
        )
        let rowReview = [[{ content: reviewContent, colSpan: 4 }]]
        // console.log('SkillData', skillData, rowReview, item)
        addText(doc, skill?.languageSkillType, { fontSize: subTitleFontSize, fontStyle: 'bold' })
        addTable(doc, skillData, detailColumns, {
          foot: rowReview,
          showFoot: 'lastPage',
          footStyles: { fillColor: '#4472C4', cellPadding: 3 },
        })
        break
      case 'speaking':
        break
      case 'writting':
        break
      default:
        break
    }
  })

  return doc
}

const mapPointToSummaryReview = (skillType, points) => {
  let foundScore = null
  switch (skillType) {
    case 'listening':
      foundScore = IELTS_SCORES.LISTENING.SCORE.find((p) => p.from <= points && points <= p.to)
      break
    case 'reading':
      foundScore = IELTS_SCORES.READING.SCORE.find((p) => p.from <= points && points <= p.to)
      break
    case 'overall':
      foundScore = IELTS_SCORES.OVERALL.SCORE.find((p) => p.from <= points && points <= p.to)
      break
    default:
      break
  }
  if (foundScore) {
    return `Nhận xét: \n- ${foundScore.en}\n- ${foundScore.vi}`
  }
  return ''
}

/**
 *
 * @param {*} doc
 * @param {*} text
 * @param {Styles} bodyStyles
 */
const addText = (doc, text, bodyStyles) => {
  autoTable(doc, {
    theme: 'plain',
    body: [[text]],
    bodyStyles: { ...bodyStyles },
    showHead: 'never',
    styles: defaultStyleAutoTable,
    margin: { top: 0 },
  })
}

/**
 *
 * @param {*} doc
 * @param {*} body
 * @param {*} columns
 * @param {UserOptions} autoTableProps
 */
const addTable = (doc, body, columns, autoTableProps = {}) => {
  autoTable(doc, {
    theme: 'grid',
    // columnStyles: { europe: { halign: 'center' } }, // European countries centered
    body: body,
    columns: columns,
    bodyStyles: {
      halign: 'center',
      // lineWidth: { top: 0.5, left: 0.5, right: 0.5, bottom: 0.5 },
      // lineColor: '#ffffff',
    },
    headStyles: {
      halign: 'center',
      fillColor: '#4472C4',
      // lineWidth: { top: 0.5, left: 0.5, right: 0.5, bottom: 0.5 },
      // lineColor: '#0000ff',
    },
    styles: defaultStyleAutoTable,
    ...autoTableProps,
  })
}

const pdfHelpers = {
  createIeltsResultPdfDocument,
}

export default pdfHelpers
