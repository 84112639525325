import React, { useEffect } from 'react'
import './index.scss'
import { isDesktop} from "react-device-detect";
import { useTranslation } from 'react-i18next';


const Footer = () => {
    const { t } = useTranslation();

    useEffect(() => {
    }, [])
    
    return (
       <>      
        <footer className="text-center text-white footer">
            <div className="container-fluid">
                {
                    isDesktop && 
                    <div className='row head'>
                        <div className='col-md-2 box-progress p-0'>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: `100%`}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                        </div>
                        <div className='col-md-8 title'>
                            <p className='text-center'>{t(`home.footer.title`)}</p>
                        </div>
                        <div className='col-md-2 box-progress p-0'>
                            <div className="progress">
                                <div className="progress-bar" role="progressbar" style={{width: `100%`}} aria-valuenow={25} aria-valuemin={0} aria-valuemax={100} />
                            </div>
                        </div>
                    </div>
                }              

                <div className='row'>
                    <div className='col-md-2 p-0'>                                           
                    </div>
                    <div className='col-md-4 p-0 info-company mt-5'> 
                        <p className='location' ><i className="fa-solid fa-location-dot fa-lg" />{t(`home.footer.location`)}</p>
                        <p className='location' >{t(`home.footer.address`)}</p>
                        <p className='location mt-5' ><a className='location-map' target='_blank'  href="https://goo.gl/maps/Gw2tFPi2fH36HNbG7">{t(`home.footer.map`)}</a></p>
                    </div>
                    <div className='col-md-6 p-0'> 
                        {
                            isDesktop ?
                            <div className='row row-info p-0 m-0 '>
                                <div className='row mt-5 justify-content-center'>
                                    <button className='btn btn-default btn-info'>info@ant-edu.vn</button>
                                </div>
                                <div className='row mt-3 row-phone justify-content-center'>
                                    <p >092 298 55 55 &nbsp;&nbsp; | &nbsp;&nbsp; 0925 730 482</p>
                                </div>
                                </div>
                            :
                            <div className='row row-logo justify-content-center'>
                                <img
                                    src="/images/logo.png"
                                    className="me-2"
                                    alt="Logo"
                                    loading="lazy"
                                />
                            </div>
                        }                                                                        
                        <div className='row mt-3 row-list-icon justify-content-center'>
                            <ul>
                                <li><a target='_blank' href="https://www.facebook.com/realieltsvn" ><i className="fab fa-facebook-f" /></a></li>
                                <li><a target='_blank'  href="https://www.instagram.com/english_not_crusty"><i className="fab fa-instagram" /></a></li>
                                <li><a target='_blank'  href="https://vt.tiktok.com/ZSdsnqB6S/" ><i className="fa-brands fa-tiktok" /></a></li>
                                {
                                !isDesktop && <li><a target='_blank'  href="tel:0922985555"><i className="fa-solid fa-phone " /></a></li>
                                }
                                <li><a target='_blank'  href="mailto:info@ant-edu.vn"><i className="fa-sharp fa-regular fa-envelope"/></a></li>                                
                            </ul>
                        </div>
                    </div>
                </div>
                   
            </div>
        </footer>
       </>
    )
}
export default Footer;