import React, { useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import {addUser, registerFacebook, registerGoogle} from "../../../services/userService"
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { isDesktop} from "react-device-detect";
import CreateAccountMB from "./indexMb";
import { useSearchParams } from 'react-router-dom';
import toastifyClient from "../../../untils/toastifyClient";
import { useGoogleLogin } from '@react-oauth/google';
import { useLogin } from 'react-facebook';

const CreateAccount =()=>{
    const { t } = useTranslation();
    const [fullName,setFullName] = useState()
    const [email,setEmail] = useState()
    const [phoneNumber,setPhoneNumber] = useState()
    const [password,setPassword] = useState()
    const [showPassword,setShowPassword] = useState(false)
    const [rePassword,setRePassword] = useState()
    const [reShowPassword,setReShowPassword] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const navigate = useNavigate()

    const handleSubmit = async (event)=>{
        event.preventDefault();
        let browserId = localStorage.getItem("browserId")
        if(!browserId) {
            browserId = uuidv4()
            localStorage.setItem("browserId",browserId)
        }
        const param = {            
            displayName:fullName,
            fullName,
            email,
            phoneNumber,
            password,
            rePassword,
            browserId:browserId
        }
        const create = await addUser(param);
        if(create?.data && create.isSuccess){
            toastifyClient.success("Create Account Success")
            let r = searchParams.get("r")
            navigate(`/auth/login?r=${r?? ""}`)
        }else{
            // alert(create.errors[0]?.message);
            toastifyClient.error(create.errors[0]?.message ?? "errors")
        }
    }
    const loginGoogle = useGoogleLogin({
        onSuccess: (googleResponse) => {
          registerGoogle({token: `${googleResponse.access_token}`})
          .then(res => {
            if(res.isSuccess){
              toastifyClient.success("Tạo tài khoản thành công")
              navigate("/auth/login")
            } else {
              toastifyClient.error(res.errors?.[0]?.message ?? "Có lỗi trong quá trình tạo tài khoản")
            }
          })
          .catch(err => {
            console.log(err)
            toastifyClient.error("Server internal error")
          })
        }
      })
    
    const {login: loginFacebook, error, isLoading: isLoadingFacebook, status} = useLogin()

    const onFacebookClick = async () => {
        var res = await loginFacebook({
          scope: "email public_profile",
          reauthorize: false,
        })
        
        registerFacebook({token: `${res.authResponse.accessToken}`})
        .then(res => {
          if(res.isSuccess){
            toastifyClient.success("Tạo tài khoản thành công")
          } else {
            toastifyClient.error(res.errors?.[0]?.message ?? "Có lỗi trong quá trình tạo tài khoản")
          }
        })
        .catch(err => {
          console.log(err)
          toastifyClient.error("Server internal error")
        })
    }
    
    const onGoogleClick = () => {
        loginGoogle()
    }
    return(
        <>
        { 
            !isDesktop ?
            <CreateAccountMB  onFacebookClick={onFacebookClick} onGoogleClick={onGoogleClick}/>
            :
            <>
                <a className="auth-logo" href="/"><img src={darkMode=="dark" ? "/images/logo.png" : "/images/logo-color.png"} /></a>    
                <div className='container-fluid loginpage' id="loginpage" style={{backgroundImage: darkMode =="dark" ? `url("/images/auth-bg.png")` : `url("/images/light/bg-auth.png")`}}>
                    <div className="container h-100 d-flex justify-content-center align-items-center">
                        <div className="col-md-6 col-left">
                            <img src={darkMode =="dark"  ? "/images/haveaniceday.png" : "/images/light/hava-a-nice-day.png" } />
                            {/* <img src="/images/haveaniceday.png" /> */}
                            <img src="/images/img-have-a-nice-day.gif" />
                        </div>
                        <div className="col-md-6 col-right">
                            <div className="box-form-group">
                                <form className="row g-3 needs-validation" novalidate onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <p className={`h3 text-center form-group-title ${darkMode=="dark" ? "color-white" : " color-3060CF"}`}>{t(`authPage.register.title`)}</p>
                                    </div>
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-user icon-prespend fa-lg"></i>
                                            </div>
                                            <input type="text" className="form-control" placeholder={t(`authPage.register.name`)}onChange={(e)=>setFullName(e.target.value) } required/>
                                        </div>
                                    </div>
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-envelope icon-prespend fa-lg"></i>
                                            </div>
                                            <input type="email" className="form-control" placeholder="Email" onChange={(e)=>setEmail(e.target.value) } required/>
                                        </div>
                                    </div>
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-phone icon-prespend fa-lg"></i>
                                            </div>
                                            <input type="number" className="form-control" placeholder={t(`authPage.register.phone`)} onChange={(e)=>setPhoneNumber(e.target.value) } required/>
                                        </div>
                                    </div>
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-lock icon-prespend fa-lg"></i>
                                            </div>
                                            <input type={showPassword ? "text" : "password"} className="form-control form-control-show" placeholder={t(`authPage.register.password`)} onChange={(e)=>setPassword(e.target.value) } required/>
                                            <span class="input-group-text" onClick={()=>setShowPassword(!showPassword)}><i class={showPassword ? "fa fa-eye " : "fa fa-eye-slash "}></i></span>
                                        </div>
                                    </div>
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-lock icon-prespend fa-lg"></i>
                                            </div>
                                            <input type={reShowPassword ? "text" : "password"} className="form-control form-control-show" placeholder={t(`authPage.register.rePassword`)} onChange={(e)=>setRePassword(e.target.value) } required/>
                                            <span class="input-group-text" onClick={()=>setReShowPassword(!reShowPassword)}><i class={reShowPassword ? "fa fa-eye " : "fa fa-eye-slash "}></i></span>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                            <button className={`btn btn-primary btn-auth ${darkMode=="dark"? "background-00F0FF color-black":"background-3060CF color-white"}`}>
                                            {/* <button className="btn btn-default btn-auth"> */}
                                                <p className={darkMode=="dark"? "color-black":"color-white"}>{t(`authPage.register.btnRegis`)}</p>
                                            </button>
                                    </div>
                                    <div className="row mt-3">                                
                                        <p className={`form-check-label text-center ${darkMode=="dark" ? " color-white":" color-black"}`} >
                                        {t(`authPage.register.guide`)} <a href={`/auth/login?r=${searchParams.get("r")?? ""}`} className="color-anttest">{t(`authPage.register.action`)}</a>
                                        </p>                               
                                    </div>
                                </form>
                            </div>
                            <div className="box-form-social">
                                <div className="social ">
                                    <div className="social-fb" onClick={onFacebookClick}>
                                        <i className="fa-brands fa-square-facebook fa-2xl p-1"></i>{t(`authPage.register.reGisterFB`)}
                                    </div>                            
                                </div>
                                <div className="social ">                            
                                    <div className="social-gg " onClick={()=>loginGoogle()}>
                                        <i className="fa-brands fa-google-plus fa-2xl p-1"></i> {t(`authPage.register.reGisterGG`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        </>
    )
}

export default CreateAccount