import React from "react";
import './index.scss'
const SpeakingReView = ({data,point,answer})=>{

    // console.log("--answer",answer)
    // console.log("--point",point)
    // console.log("--data",data)

    const renderMarkAsCorrect =(point)=>{
        return <span className="correct-true">{`+${point?.points}`}</span>
    }
    return (
        <div className="speaking-review">  
            <div className="question-name"></div>
            {
                renderMarkAsCorrect(point)
            }
            <div className="question-content" dangerouslySetInnerHTML={{__html:data?.content}}></div>

            <div className="view-answerExams" >
                <div className="your-answer" dangerouslySetInnerHTML={{__html:answer?? ""}}></div>
                {/* <div className="answer-detail">
                    <p dangerouslySetInnerHTML={{__html:answer?? ''}}></p>
                </div>
                <p className="recommend-answer">Recommend for you</p>
                <div className="recommend-detail">
                    <p dangerouslySetInnerHTML={{__html:point?.note ?? ''}}></p>
                </div> */}
            </div>
            
            
        </div>
    )
}
export default SpeakingReView