import React, { useEffect, useState } from 'react'
import "./index.scss"
import { useTranslation } from 'react-i18next';
// import HomeMenu from '../Menu/Home';
import Particles from "react-tsparticles";
// import { loadFull } from "tsparticles";
import { loadSlim } from 'tsparticles-slim';
// import { checkUser } from '../../../services/userService';

const Header = () => {
    const { t, i18n } = useTranslation();
    const [language,setLanguage] = useState(localStorage.getItem('i18nextLng')? localStorage.getItem('i18nextLng') : "vi" )

    const changeLanguage = () => {
        const newLanguage = language==='en' ? 'vi':'en';
        setLanguage(newLanguage)
        i18n.changeLanguage(newLanguage);    
        localStorage.setItem('i18nextLng',newLanguage)

    };
    const particlesInit = async (main) => {
        console.log(main);
        await loadSlim(main);
    };

    const particlesLoaded = (container) => {
        console.log(container);
    };
    
    // const checkTokenExpried = async () =>{
    //     try {
    //         const result = await checkUser()
    //         console.log('userInfo',result)
    //     } catch (error) {
    //         console.log('error',error)
    //         localStorage.removeItem("userInfo")
    //         localStorage.removeItem("token")
    //     }
       
    // };
    // useEffect(() => {
    //     checkTokenExpried()
    // }, [])
    
    return (
    <>      
        <header id='header'>                     
             
            <div id="intro" className="bg-image">   
                <Particles
                    id="tsparticles"
                    init={particlesInit}
                    loaded={particlesLoaded}
                    style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0",
                        left: "0"
                    }}
                    options={{ 
                        fullScreen: {
                            enable: false,
                            zIndex: -1
                        },                   
                        fpsLimit: 120,
                            interactivity: {
                                events: {
                                onClick: {
                                    enable: true,
                                    mode: "push"
                                },
                                onHover: {
                                    enable: true,
                                    mode: "repulse"
                                },
                                resize: true
                                },
                                modes: {
                                push: {
                                    quantity: 4
                                },
                                repulse: {
                                    distance: 200,
                                    duration: 0.4
                                }
                                }
                            },
                            particles: {
                                color: {
                                value: "#ffffff"
                                },
                                links: {
                                color: "#ffffff",
                                distance: 150,
                                enable: true,
                                opacity: 0.5,
                                width: 1
                                },
                                collisions: {
                                enable: true
                                },
                                move: {
                                direction: "none",
                                enable: true,
                                outModes: {
                                    default: "bounce"
                                },
                                random: false,
                                speed: 1,
                                straight: false
                                },
                                number: {
                                density: {
                                    enable: true,
                                    area: 800
                                },
                                value: 80
                                },
                                opacity: {
                                value: 0.5
                                },
                                shape: {
                                type: "circle"
                                },
                                size: {
                                value: { min: 1, max: 5 }
                                }
                            },
                            detectRetina: true
                        }
                    }
                />            
                <div className="mask text-white" >
                   {/* <HomeMenu/> */}
                    <div className="container d-flex align-items-center text-center mask-content">
                        <div className='w-100 p-2'>
                            <p className="giveatry" dangerouslySetInnerHTML={{ __html: t(`home.header.heightlight`)}} ></p>
                            <p className='with-ai'  dangerouslySetInnerHTML={{ __html: t(`home.header.guide`)}} ></p>
                            <div className='btn-testnow'>
                                <a href='/#testlist'>{t(`home.testlist.btnTest`)}</a>
                            </div>
                        </div>
                        
                    </div>
                    <div className="container d-flex align-items-center text-center">
                        <div className='muiten'>
                            <img
                                src="./images/gif-arrow.gif"
                                alt="gif"
                                loading="lazy"
                            />
                        </div>
                    </div>
                </div>
                <div className='bg-hidden'></div>
            </div>
        </header>    
    </>
    )
}
export default Header;