import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Speaking from "../PresenTations/Speaking";
import MenuTest from "../Layout/Menu/MenuTest";
import { getStartExam } from "../../services/ieltsService";
import { isDesktop} from "react-device-detect";
import SpeakingMb from "../PresenTations/Speaking/speakingMb";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";
import toastifyClient from "../../untils/toastifyClient";

const SpeakingAiPage=()=>{
    const [data,setData] = useState();
    const refData = useRef(false);
    const navigate = useNavigate();
    const subSkill = localStorage.getItem("subSkill")
    const darkMode = localStorage.getItem('darkMode') ?? "dark"
    const menuItem = [
        {item:"speaking",link:"/speaking"}
    ]
    const nextSkill =()=>{
        // navigate("/speaking")
    }
    const getData = async ()=>{
        let getDataLocal = localStorage.getItem('exam')
        if(getDataLocal){
            setData(JSON.parse(getDataLocal))
            return
        }  

        try {
            const result = await getStartExam({
                "examType":subSkill==="basic" ? "AISpeakingBasic" : "AISpeakingAdvanced",
                "browserId":localStorage.getItem("browserId")
            });

            if(result.isSuccess && result.data) {
                localStorage.setItem('exam',JSON.stringify(result.data))
                setData(result.data)
            }else{
                if(result?.errors[0]?.code == 1){
                    toastifyClient.error(result?.errors[0]?.message ?? "error auth");
                    navigate("/auth/login")
                }
                if(result?.errors[0]?.code == 2){
                    toastifyClient.error(result?.errors[0]?.message ?? "error coin");
                    navigate("/")
                }
            }
          
        } catch (error) {
          console.log(error)
        }    
    }

    useEffect(()=>{
        if(!refData.current) {
            refData.current=true
            getData();
        }
        return () => {
            localStorage.removeItem('answer')
        };
    },[])
    return(
        <>
            {
                data && 
                <div className='container-fluid p-0 content-ielts'>
                    {
                        isDesktop ? <MenuTest active={'/speaking'} menuItem={menuItem} time={480000}/> : <HeaderMB content={"Speaing"} nextSkill={nextSkill} section={0} />
                    }  
                    <div className='content-ielts-wrapper' style={{backgroundImage: isDesktop ?darkMode =="light" ? `url("/images/light/bg-test.png")` : `url("/images/speaking-bg.png")` : ''}} >
                    { 
                        isDesktop ? <Speaking nextSkill={nextSkill} /> : <SpeakingMb nextSkill={nextSkill} />
                    }
                    </div>
                </div>
            }
            
        </>
    )
}
export default SpeakingAiPage