import React, { useEffect, useState } from "react";
import "./index.scss"

const Writing = (props)=>{
    const [data,setData] = useState([])
    // const [listAnswered,setListAnsered]= useState(localStorage.getItem('WritingAnswer') ? JSON.parse(localStorage.getItem('WritingAnswer')): [] )
    const [listAnswered,setListAnsered]= useState(new Map())
    const [part,setPart] = useState(0)
    const [countNumber,setCountNumber] = useState(0);
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const countWords = (str)=> {
        let arr = str.replace(/\n/g,' ')
        arr = arr.split(' ');
        return arr.filter(word => word !== '').length;
    }

    const nextPassage =()=>{
        if(data?.length > part+1) {
            setPart(part+1)       
        }
    }
    const prevPassage =()=>{
        if(part>0) {
            setPart(part-1)       
        }
    }
    const handleAnswer =(questionId,value)=>{        
        let stgAnswer = localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map();
        stgAnswer.set(
            questionId, 
            {
                questionId,answerContent:value
            }
        );
        setListAnsered(stgAnswer)
        localStorage.setItem('answer',JSON.stringify(Object.fromEntries(stgAnswer)))
        setCountNumber(countWords(value))
    }

    const getData = async ()=>{
        let questionContent =  JSON.parse(localStorage.getItem('exam'))?.questionContent
        // questionContent = JSON.parse(questionContent)
        let content = questionContent.languageSkillExams.filter(e => e.languageSkillType ==='Writing');
        let newData = []
        content[0]?.partitionExams[0]?.sectionExams?.map((e)=>{
            e.groupQuestionExams?.map((i)=>{
                i.questionExams?.map((j)=>{
                    newData.push(j)
                })
            })
        })
        setData(newData)
    }
    useEffect(()=>{
        getData();
    },[])
    return(
        <>
             <div  id="writing" className="writing">
                <div className="justify-content-center align-items-center ">
                    <div className="container-fluid wrapper-content">
                        <div className="container wrapper-content-writing h-100">
                            <div className="row h-100">
                                <div className="col-md-6 box-read-question">
                                    {
                                        data?.length > 1 &&  
                                        <div className="box-passage d-flex justify-content-center align-items-conter ">
                                            <div className="item-passage">
                                                <div className={(part+1) >= data?.length?"item-icon item-icon-active":"item-icon"}  onClick={()=>prevPassage()}>
                                                    <i className="fa fa-angle-left" aria-hidden="true"></i>
                                                </div> 
                                            </div>
                                            <div className="item-passage">
                                                Part {part+1}
                                            </div>
                                            <div className="item-passage ">
                                                <div className={(part+1) >= data?.length?"item-icon":"item-icon item-icon-active"} onClick={()=>nextPassage()}>
                                                    <i className="fa fa-angle-right" aria-hidden="true"></i>
                                                </div>                                        
                                            </div>
                                        </div>
                                    }
                                
                                    <div className={`ielts-writing-head ${darkMode=="dark"? "dark-mode":"light-mode"}`}>
                                        <p className={`writing-detail ${darkMode=="dark"? "color-white":"color-black"}`} dangerouslySetInnerHTML={{__html:data[part]?.content}} id={data[part]?.id}></p>
                                    </div>
                                    <div className="ielts-wrapper-content">
                                        <div className="row d-flex justify-content-center">
                                        </div>
                                    
                                    </div>
                                </div>
                                <div className="col-md-6 ">
                                    <div className="box-writing-text">
                                        <div className="form-group">
                                            <textarea className={` rounded-0 ${darkMode=="dark"? " color-white ":"color-black"} `} placeholder="Writing here..." onChange={(e)=>handleAnswer(data[part].id,e.target.value)} key={part}></textarea>
                                            <p className={` count-number  ${darkMode=="dark"? " color-white ":"color-black"} `}>{countNumber}/1000 words</p>
                                        </div>
                                    </div>                               
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    
                    {/* <div className="ielts-wrapper-footer">
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-md-8 p-0 d-flex align-items-center h-100">
                                    <ul className="list_question p-0 m-0">
                                         {
                                            data?.map((e,i)=>{
                                                return(
                                                    <li key={i} className={listAnswered.get(e.id) ? "answered":""} >{i+1}</li>
                                                )
                                            })
                                         }
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    <div className="row h-100 guide-question ">
                                        <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                                            <li><div className="circle " ></div></li>                                 
                                            <li>Unanswered</li>                             
                                            <li><div className="circle circle-answered" ></div></li>                                 
                                            <li>Answered</li>                                 
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className={`ielts-wrapper-footer ${darkMode == "light" ? "background-white" :" background-footer-dark"}`}>
                        <div className="container h-100">
                            <div className="row h-100">
                                <div className="col-md-8 p-0 d-flex align-items-center h-100">
                                    <ul className="list_question p-0 m-0">
                                        {
                                            data?.map((e,i)=>{
                                                return <li className={listAnswered.get(e.id) ? darkMode =="light"? "answered border-3060CF background-3060CF" :"answered border-00F0FF background-00F0FF"  : darkMode =="light"? "border-black " :" border-white"}  key={i}><a className={darkMode =="light" ? listAnswered.get(e) ?"color-white" : "color-black":"color-white"} href={`#question-${e}`}>{i+1}</a></li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className="col-md-4">
                                    {/* <div className={darkMode =="light" ?"row h-50 color-black" : "row h-50 color-white"}>
                                        <div className={darkMode =="light" ? "d-flex align-items-end justify-content-center btn-next-skill-light"  : "d-flex align-items-end justify-content-center btn-next-skill"  }onClick={()=>nextSkill(1)}>Go to next skill <i className="fa fa-angle-double-right" aria-hidden="true"></i></div> 
                                    </div> */}
                                    <div className={darkMode == "light" ? "row h-100 guide-question color-black ":"row h-100 guide-question color-white"}>
                                        <ul className="w-100 d-flex align-items-center justify-content-center m-0">
                                            <li><div className={darkMode=="light"?"circle border-black":"circle border-white"} ></div></li>                                 
                                            <li>Unanswered</li>                             
                                            <li><div className={darkMode=="light"?"circle circle-answered border-3060CF background-3060CF":"circle circle-answered border-00F0FF background-00F0FF"}></div></li>                                 
                                            <li>Answered</li>                                 
                                        </ul>
                                    </div>                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Writing