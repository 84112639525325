import React, { useState } from "react";
import "./index.scss"
import { checkUser, getUser, signIn, signInFacebook, signInGoogle } from "../../../services/userService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { useEffect } from "react";
import { isDesktop} from "react-device-detect";
import LoginMB from "./indexMb";
import { useSearchParams } from 'react-router-dom';
import toastifyClient from "../../../untils/toastifyClient";
import { useGoogleLogin } from '@react-oauth/google';
import { useLogin } from 'react-facebook';

const Login =()=>{
    const { t } = useTranslation();
    const [email,setEmail] = useState()
    const [password,setPassword] = useState()
    const [errorC,setErrorC] = useState()
    const [showPassword,setShowPassword] = useState(false)
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate()  
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const handleSubmit = async (event)=>{
        event.preventDefault();
        try {
            const param = {            
                userName:email,password
            }
            const result = await signIn(param);
            if(result?.data && result.isSuccess){
                localStorage.setItem('token',result.data.token)
                const userinfo = await getUser();
                if(userinfo?.data && result.isSuccess){
                    toastifyClient.success("Đăng nhập thành công!")
                    localStorage.setItem('userInfo',JSON.stringify(userinfo.data))
                    let r = searchParams.get("r")
                    
                    if(r?.length>0) return window.location.replace(r)
                    navigate("/")
                }            
            }else{
                // alert(result.errors[0].message)
                toastifyClient.error(result.errors[0].message ?? "errors")
                setErrorC(result.errors[0].message)

            }
        } catch (error) {
            console.log(error)
            // alert(error.message)
            setErrorC(error.message)
            // alert(result.errors[0].message)
        }
       
    }
    const checkLogin = async () =>{
        try {
            const result = await checkUser()
            let r = searchParams.get("r")
            if(r?.length>0) return window.location.replace(r)
            return window.location.replace('/');
        } catch (error) {
            localStorage.removeItem("userInfo")
            localStorage.removeItem("token")
        }
       
    };

    const loginGoogle = useGoogleLogin({
        onSuccess: (googleResponse) => {
            signInGoogle({token: `${googleResponse.access_token}`})
          .then(res => {
            if(res.isSuccess){
              toastifyClient.success("Đăng nhập khoản thành công")             
              navigate("/")
            } else {
              toastifyClient.error(res.errors?.[0]?.message ?? "Có lỗi trong quá trình tạo tài khoản")
            }
          })
          .catch(err => {
            console.log(err)
            toastifyClient.error("Server internal error")
          })
        }
      })
    
    const onGoogleClick = () => {
        loginGoogle()
    }

    const {login: loginFacebook, error, isLoading: isLoadingFacebook, status} = useLogin()

    const onFacebookClick = async () => {
        var res = await loginFacebook({
          scope: "email public_profile",
          reauthorize: false,
        })
        
        signInFacebook({token: `${res.authResponse.accessToken}`})
        .then(res => {
          if(res.isSuccess){
            toastifyClient.success("Tạo tài khoản thành công")
          } else {
            toastifyClient.error(res.errors?.[0]?.message ?? "Có lỗi trong quá trình tạo tài khoản")
          }
        })
        .catch(err => {
          console.log(err)
          toastifyClient.error("Server internal error")
        })
    }
    useEffect(() => {
        checkLogin()
    }, [])
    return(
        <>
        { 
            !isDesktop ?
            <LoginMB onGoogleClick={onGoogleClick} onFacebookClick={onFacebookClick}/>
            :
            <>            
                <a  className="auth-logo " href="/"><img src={darkMode=="dark" ? "/images/logo.png" : "/images/logo-color.png"} /></a>
                <div className='container-fluid loginpage' id="loginpage" style={{backgroundImage: darkMode =="dark" ? `url("/images/auth-bg.png")` : `url("/images/light/bg-auth.png")`}}>
                        
                    <div className="container">
                        <div className="col-md-6 col-left">
                            {/* <img src="/images/haveaniceday.png" /> */}
                            <img src={darkMode =="dark"  ? "/images/haveaniceday.png" : "/images/light/hava-a-nice-day.png" } />
                            <img src="/images/img-have-a-nice-day.gif" />
                        </div>
                        <div className="col-md-6 col-right">
                            <div className={`box-form-group ${darkMode == "dark" ? " border-black":""}`}>
                                <form className="row g-3 needs-validation" novalidate onSubmit={handleSubmit}>
                                    <div className="form-group">
                                        <p className={`h3 text-center form-group-title ${darkMode=="dark" ? "color-white" : " color-3060CF"}`}>{t(`authPage.loginPage.title`)}</p>
                                    </div>
                                    {
                                        errorC && <div className="form-group">
                                                    <p className="text-center text-danger">{errorC}</p>
                                                </div>
                                    }
                                    
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-envelope icon-prespend fa-lg"></i>
                                            </div>
                                            <input type="text" className="form-control" placeholder="Email" onChange={(e)=>setEmail(e.target.value)} required/>
                                        </div>
                                    </div>
                                    <div className="form-group">                            
                                        <div className="input-group">
                                            <div className="input-group-prepend">
                                                <i className="fa-solid fa-lock icon-prespend fa-lg"></i>
                                            </div>
                                            <input type={showPassword ? "text" : "password"} className="form-control form-control-show" placeholder= {t(`authPage.loginPage.password`)} onChange={(e)=>setPassword(e.target.value)} required/>                                                                       
                                            <span className="input-group-text" onClick={()=>setShowPassword(!showPassword)}><i className={showPassword ? "fa fa-eye " : "fa fa-eye-slash "}></i></span>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center mt-3">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" id="gridCheck" value="option1" aria-label="..." />
                                                    <label className={`form-check-label ${darkMode=="dark" ? "color-white" : " color-black"}`} htmlFor="gridCheck">
                                                        {t(`authPage.loginPage.savePassword`)}
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row justify-content-md-center">
                                            <button className={`btn btn-primary btn-auth ${darkMode=="dark"? "background-00F0FF color-black":"background-3060CF color-white"}`}>
                                                <p className={darkMode=="dark"? "color-black":"color-white"}>{t(`authPage.loginPage.btnLogin`)}</p>
                                            </button>
                                    </div>
                                    <div className="row mt-3">                                
                                        <p className="form-check-label text-center">
                                            <a href="/auth/forgot-password">
                                                <u>{t(`authPage.loginPage.forgotPassword`)}</u> 
                                            </a>
                                        </p>                               
                                    </div>
                                    <div className="row">                                
                                        <p className="form-check-label text-center">
                                        <u className={darkMode=="dark"? "color-white":"color-black"}>{t(`authPage.loginPage.guide`)}</u>  <a href={`/auth/register?r=${searchParams.get("r")?? ""}`} className={darkMode=="dark"? "color-anttest color-00F0FF":" color-anttest color-3060CF"}> <u>{t(`authPage.loginPage.action`)}</u> </a>
                                        </p>                               
                                    </div>
                                </form>
                            </div>
                            <div className="box-form-social">
                                <div className="social ">
                                    <div className="social-fb" onClick={()=>alert("Coming soon")}>
                                        <i className="fa-brands fa-square-facebook fa-2xl p-1"></i>{t(`authPage.loginPage.loginFB`)}
                                    </div>                            
                                </div>
                                <div className="social ">                            
                                    <div className="social-gg" onClick={()=>loginGoogle()}>
                                        <i className="fa-brands fa-google-plus fa-2xl p-1"></i>{t(`authPage.loginPage.loginGG`)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        }
        </>
       
    )
}

export default Login