import React, { useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Listening from "../PresenTations/Listening";
import { GraAndReadingQuestionShorttest } from "../../constants/quiz";
import { ReadingQuestion } from "../../constants/fulltest";
import Reading from "../PresenTations/Reading";
import GrammarAndReading from "../PresenTations/GrammarAndReading";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";
import { isDesktop} from "react-device-detect";

const ReadingPage=()=>{
    const navigate = useNavigate();
    const skill = localStorage.getItem("skill")
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const nextSkill =()=>{
        navigate("/writing",{ replace: true })
    }
    useEffect(()=>{
        localStorage.removeItem('ReadingAnswer')
    },[])
    return(
        <>
            {!isDesktop && <HeaderMB content={"Reading"} nextSkill={nextSkill} section={0} />}
            {/* <div className='content-ielts-wrapper' style={{backgroundImage: `url("/images/reading-bg.png")`}}>  */}
            <div className='content-ielts-wrapper' style={{backgroundImage: isDesktop?  darkMode =="light" ? `url("/images/light/bg-test.png")` : `url("/images/listening-bg.png")` : "none"}}> 
                {skill ==='/shorttest' &&  <GrammarAndReading nextSkill={nextSkill}/>}
                {skill ==='/fulltest' && <Reading nextSkill={nextSkill} data={ReadingQuestion}/>}
                
            </div>
        </>
    )
}
export default ReadingPage