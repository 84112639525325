import React, { useState } from "react";
import HomeMenu from "../Layout/Menu/Home";
import Particles from "react-tsparticles";
import { useTranslation } from "react-i18next";
import { loadSlim } from 'tsparticles-slim';
import "./index.scss"
import { useForm } from "react-hook-form";
import { getStartExamByCode } from "../../services/ieltsService";
import { useNavigate } from "react-router-dom";
import { isDesktop} from "react-device-detect";
import { v4 as uuidv4 } from 'uuid';
import { useSearchParams } from 'react-router-dom';
import { checkUser } from "../../services/userService";
import toastifyClient from "../../untils/toastifyClient";

const ExamByCode = ()=>{
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams();
    const {register, handleSubmit, formState:{errors}} = useForm();
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )

    const particlesInit = async (main) => {
        // console.log(main);
        await loadSlim(main);
    };

    const particlesLoaded = (container) => {
        // console.log(container);
    };

    const handleRegistration= async (data) =>{
        // console.log(data)
        try {
            const result = await checkUser()
        } catch (error) {
           alert('Bạn cần đăng nhập trước khi làm bài Test')
           return navigate(`/auth/login?r=/exam-by-code?code=${searchParams.get("code")}`)
        }

        try {

            // userInfo.displayName = data.fullname;
            // userInfo.email = data.email;
            // userInfo.phoneNumber = data.phone;
            // localStorage.setItem("userInfo",JSON.stringify(userInfo))
            const browserId = localStorage.getItem("browserId")
            if(!browserId){
                browserId = uuidv4()
                localStorage.setItem("browserId",browserId);
            } 
            const result = await getStartExamByCode({testCode:data?.code,browserId:browserId})
            
            // if(!result?.isSuccess) return alert(result?.errors[0]?.message)
            if(!result?.isSuccess) return toastifyClient.error(result?.errors[0]?.message)
            let skill,subSkill=""
            switch (result.data.testType) {
                case "Short":
                    skill="/shorttest"
                    break;
                case "Full":
                    skill="/fulltest"                    
                    break;

                case "AISpeakingBasic":
                    skill="/speakingai"
                    subSkill="basic"
                    break;
                case "AISpeakingAdvanced":
                    skill="/speakingai"
                    subSkill="advance"                    
                    break;
                default:
                    break;
            }
            if(!isDesktop && (skill=="/shorttest" || skill=="/fulltest")) return alert("kỹ nằng ShortTest, FullTest hiện đang không hỗ trợ trên Mobile")

            localStorage.setItem("skill",skill)
            localStorage.setItem("subSkill",subSkill)
            localStorage.setItem('exam',JSON.stringify(result.data))
            return navigate(skill)
        
        } catch (error) {
            alert("API Error")
        }
       
    }
    const registrationOptions = {
        fullname:{required: "FullName is required"},
        email:{required: "Email is required"},
        phone:{required: "SĐT is required"},
        code:{required: "Code is required"}

    }
    console.log("userinfo",userInfo);
    console.log("--code",searchParams.get("code"));
    return(
        <>
            <div className='container-fluid homepage' id="homepage">
            <HomeMenu />
            <header id='header'>                     
             
                <div id="intro" className="bg-image">   
                    <Particles
                        id="tsparticles"
                        init={particlesInit}
                        loaded={particlesLoaded}
                        style={{
                            width: "100%",
                            height: "100%",
                            position: "absolute",
                            top: "0",
                            left: "0"
                        }}
                        options={{ 
                            fullScreen: {
                                enable: false,
                                zIndex: -1
                            },                   
                            fpsLimit: 120,
                                interactivity: {
                                    events: {
                                    onClick: {
                                        enable: true,
                                        mode: "push"
                                    },
                                    onHover: {
                                        enable: true,
                                        mode: "repulse"
                                    },
                                    resize: true
                                    },
                                    modes: {
                                    push: {
                                        quantity: 4
                                    },
                                    repulse: {
                                        distance: 200,
                                        duration: 0.4
                                    }
                                    }
                                },
                                particles: {
                                    color: {
                                    value: "#ffffff"
                                    },
                                    links: {
                                    color: "#ffffff",
                                    distance: 150,
                                    enable: true,
                                    opacity: 0.5,
                                    width: 1
                                    },
                                    collisions: {
                                    enable: true
                                    },
                                    move: {
                                    direction: "none",
                                    enable: true,
                                    outModes: {
                                        default: "bounce"
                                    },
                                    random: false,
                                    speed: 1,
                                    straight: false
                                    },
                                    number: {
                                    density: {
                                        enable: true,
                                        area: 800
                                    },
                                    value: 80
                                    },
                                    opacity: {
                                    value: 0.5
                                    },
                                    shape: {
                                    type: "circle"
                                    },
                                    size: {
                                    value: { min: 1, max: 5 }
                                    }
                                },
                                detectRetina: true
                            }
                        }
                    />
                    <div className="box-input-code">
                        <div className="form-input-code">
                            <form onSubmit={handleSubmit(handleRegistration)}>
                                {/* {
                                    !userInfo?.id ? <>
                                        <div className="form-group">
                                            <label htmlFor="fullname">Họ và tên:</label>
                                            <input type="text" className="form-control" id="fullname" aria-describedby="fullname" placeholder="Nhập tên của bạn tại đây" {...register('fullname',registrationOptions.fullname)}  />
                                            <small className="text-danger">{errors?.fullname && errors.fullname.message}</small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="email">Email:</label>
                                            <input type="email" className="form-control" id="email" aria-describedby="email" placeholder="Nhập email tại đây" {...register('email',registrationOptions.email)}  />
                                            <small className="text-danger">{errors?.email && errors.email.message}</small>
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="phone">Số điện thoại:</label>
                                            <input type="number" className="form-control" id="phone" placeholder="Nhập SĐT  tại đây" {...register('phone',registrationOptions.phone)}  />
                                            <small className="text-danger">{errors?.phone && errors.phone.message}</small>
                                        </div> 
                                    </>
                                    :""
                                } */}
                               
                                <div className="form-group">
                                    <label htmlFor="code">Code:</label>
                                    <input type="text" className="form-control" id="code" placeholder="Nhập mã code tại đây" defaultValue={searchParams.get("code") ?? ""} {...register('code',registrationOptions.code)}  />
                                    <small className="text-danger">{errors?.code && errors.code.message}</small>

                                </div>                                  
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </form>
                        </div>
                              
                    </div>
                    <div className='bg-hidden'></div>
                </div>
            </header>    
            </div>
        </>
    )
}

export default ExamByCode