import React from "react";
import './index.scss'
const SingleChoiceReview = ({data,point,answer})=>{

    // console.log("--answer",answer)
    // console.log("--point",point)

    const renderList =(answerData)=>{
    // console.log("--data",data.displayType,data)
        return (
            <div className="answer-list">
                {
                    answerData?.map(e=>{
                        let styleCorrect = {
                            "borderColor": "#FFFFFF" 
                        }
                        if(point?.markAsCorrect == false && e?.id === answer?.id ) styleCorrect["borderColor"] = "#FB545C";
                        if(e?.isCorrect === true ) styleCorrect["borderColor"] = "#00F043";
                        return(
                            <div className="form-check" key={e?.id}>
                                <input className="form-check-input" type="radio" name={e?.id} style={styleCorrect} checked={e?.isCorrect === true || e?.id === answer?.id}  disabled/>
                                <label className="form-check-label" >
                                    {e?.content}
                                </label>
                            </div>
                        )
                    })
                }
                
            </div>
        )
    }
    const renderMarkAsCorrect =(point)=>{
        // console.log("---",point)
        if(point?.points) return <span className="correct-true">(TRUE) + 1</span>
        return <span className="correct-false">(FALSE)</span>
    }
    return (
        <div className="singleChoice-review">  
            <div className="question-name"></div>
            {
                renderMarkAsCorrect(point)
            }
            <div className="question-content" dangerouslySetInnerHTML={{__html:data?.content}}></div>
           
           
            {data?.materials?.map((e)=>{
                if(e.materialType =="mp3") return (
                    <>
                        <audio controls class="w-50" key={e?.id}>
                            <source
                                src={`${process.env.REACT_APP_BE_HOST+e.url}`}
                                type="audio/mpeg"
                            />                                                                                                           
                            Audio not supported
                        </audio>
                    </>
                )
            })}
            <div className="view-answerExams">
                {/* {(data?.displayType == "List" || data?.displayType == "" || data?.displayType == null) && renderList(data?.answerExams?? [])}
                {data?.displayType == "Dropdown" && renderList(data?.answerExams?? [])} */}
                {
                    renderList(data?.answerExams?? [])
                }
            </div>
            
            
        </div>
    )
}
export default SingleChoiceReview