import React, { useEffect, useState } from 'react'
import './index.scss'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import HeaderMB from '../Layout/Menu/HeaderMB/Header'
import { checkUser } from '../../services/userService'

const ListTest = () => {
    const { t } = useTranslation();
    const [username,setUsername] = useState()
    const [input,setInput] = useState()
    const navigate = useNavigate()
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    const skill = localStorage.getItem("skill")
    const subSkill = localStorage.getItem("subSkill")
    
    const handleClickSpeaingTest = async (subSkill)=>{
        
        try {
            const result = await checkUser()
        } catch (error) {
            navigate("/auth/login")
            return
        }            
        localStorage.setItem("skill",'/speaking')
        localStorage.setItem("subSkill",subSkill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }
    const handleclicktest = (skill)=>{
        localStorage.setItem("skill",skill)
        localStorage.removeItem("exam")
        localStorage.removeItem("answer")
        navigate("/welcome")
    }
    return (
        <div className={"welcome-mb"}>   
           <HeaderMB content={"Khảo thí - Thi thử"}/>
           <div className='wc-content'>
                
           <div className="input-test-list" id="inputTest">
                        <div className="list-test">
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-top" >
                                        <div className="top-left">
                                            <img  src="/images/mb/test1.png"/>
                                        </div>
                                        <div className="top-right">
                                            <p className="top-right-title">
                                                Standard Test
                                            </p>
                                            <p className="top-right-guide">
                                                Bài kiểm tra đầu vào tiêu chuẩn với 3 kỹ năng: Nghe, Đọc, Viết
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-bottom" >
                                        <div className="btn-test" onClick={()=>handleclicktest('/shorttest')}>
                                            Short test (30’)
                                        </div>
                                        <div className="btn-test btn-full-test" onClick={()=>handleclicktest('/fulltest')}>
                                            Full Test (90’)
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="card">
                                <div className="card-body">
                                    <div className="card-top" >
                                        <div className="top-left">
                                            <img  src="/images/mb/test2.png"/>
                                        </div>
                                        <div className="top-right">
                                            <p className="top-right-title">
                                                AI Speaking Test
                                            </p>
                                            <p className="top-right-guide">
                                                Bài kiểm tra năng lực nói tiếng Anh bằng công nghệ AI
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-bottom1" >
                                        <div className="btn-test btn-full-test"  data-bs-toggle="modal" data-bs-target="#speakingModal">
                                            Thi ngay
                                        </div>
                                        <div className="modal fade" id="speakingModal" tabIndex={-1} aria-labelledby="speakingModalLabel" aria-hidden="true">
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content">
                                                    <div className='row'>
                                                        <div className='col-11 '>
                                                            <h1 className="modal-title fs-5 text-center" id="speakingModalLabel" dangerouslySetInnerHTML={{ __html: t(`home.testlist.speakingtest.lable`)}}></h1>
                                                        </div>
                                                        <div className='col-1 p-0'>
                                                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                                                        </div>
                                                    </div>
                                                    <div className='row mt-3'>
                                                        <div className='col-6'>
                                                            <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickSpeaingTest("basic")}>{t(`home.testlist.speakingtest.selectSkill.basic`)}</button>
                                                        </div>
                                                        <div className='col-6'>
                                                            <button type="button" className="btn btn-outline-primary btn-lg w-100 btn-advance" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>handleClickSpeaingTest("advance")} >{t(`home.testlist.speakingtest.selectSkill.advance`)}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="card">
                                <div className="card-body">
                                    <div className="card-top" >
                                        <div className="top-left">
                                            <img  src="/images/mb/test1.png"/>
                                        </div>
                                        <div className="top-right">
                                            <p className="top-right-title">
                                                Thi thử
                                            </p>
                                            <p className="top-right-guide">
                                                Bài kiểm tra năng lực nói tiếng Anh bằng công nghệ AI
                                            </p>
                                        </div>
                                    </div>
                                    <div className="card-bottom1" >
                                        <div className="btn-test btn-full-test" onClick={()=>alert("Coming soon")}>
                                            Thi ngay
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>

           </div>
        </div>
    )
}
export default ListTest