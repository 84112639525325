import React, { useEffect, useState } from "react";
import Speaking from "../PresenTations/Speaking";
import Writing from "../PresenTations/Writing";
import Listening from "../PresenTations/Listening";
import GrammarAndReading from "../PresenTations/GrammarAndReading";
// import { GraAndReadingQuestionShorttest, ListenQuestionShorttest, SpeQuestionShorttest, WritingQuestionShorttest } from "../../constants/quiz";
import MenuShortTest from "../Layout/Menu/MenuShortTest";
import { isDesktop} from "react-device-detect";
import HeaderMB from "../Layout/Menu/HeaderMB/Header";
import SpeakingMb from "../PresenTations/Speaking/speakingMb";

const Shorttest =()=>{
    const [section,setSection] = useState(0)
    const data =  JSON.parse(localStorage.getItem('exam'))
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const content = data.questionContent
    return(
        <div className={!isDesktop?"short-test-mb":""}>
            {
                isDesktop? 
                <MenuShortTest active={ content.languageSkillExams[section]?.languageSkillType.toLowerCase()} /> 
                : 
                <HeaderMB content={content.languageSkillExams[section]?.languageSkillType} nextSkill={setSection} section={section}/>
            }
            
            <div className='content-ielts-wrapper' style={{backgroundImage: isDesktop?  isDesktop ? darkMode =="light" ? `url("/images/light/bg-test.png")` : `url("/images/${content.languageSkillExams[section]?.languageSkillType.toLowerCase()}-bg.png")`:"" : ""}}> 
                {
                    content.languageSkillExams[section]?.languageSkillType.toLowerCase()==='listening' && <Listening  nextSkill={setSection} />
                }
                {
                    content.languageSkillExams[section]?.languageSkillType.toLowerCase()==='reading' && <GrammarAndReading nextSkill={setSection}/>
                }
                {
                    content.languageSkillExams[section]?.languageSkillType.toLowerCase()==='speaking' && isDesktop && <Speaking nextSkill={setSection} />
                }
                {
                    content.languageSkillExams[section]?.languageSkillType.toLowerCase()==='speaking' && !isDesktop && <SpeakingMb nextSkill={setSection} />
                }
                {
                    content.languageSkillExams[section]?.languageSkillType.toLowerCase()==='writing' && <Writing nextSkill={setSection} />
                }
            </div>
            {/* {section ===0 && 
                <>
                <MenuShortTest active={"listening"}/>
                <div className='content-ielts-wrapper' style={{backgroundImage: `url("/images/listening-bg.png")`}}> 
                    <Listening nextSkill={setSection} />
                </div>
                </>
            }
            {section ===1 && 
                <>
                <MenuShortTest active={"reading"}/>
                <div className='content-ielts-wrapper' style={{backgroundImage: `url("/images/reading-bg.png")`}}> 
                    <GrammarAndReading  nextSkill={setSection}/>
                </div>
                </>
            }
            {section ===2 && 
                <>
                <MenuShortTest active={"speaking"}/>
                <div className='content-ielts-wrapper' style={{backgroundImage: `url("/images/speaking-bg.png")`}}> 
                    <Speaking  nextSkill={setSection}/>
                </div>
                </>
            }
            {section ===3 && 
                <>
                <MenuShortTest active={"writing"}/>
                <div className='content-ielts-wrapper' > 
                    <Writing   nextSkill={setSection}/>
                </div>
                </>
            } */}
        </div>
    )
}
export default Shorttest