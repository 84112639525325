import React, { useState } from "react";
import { uploadFile } from "../../../services/ieltsService";
import { putUser } from "../../../services/userService";

const Sidebar = (props)=>{
    const listOfSidebar= [
        {
            text:"My Courses",
            img:"/images/computer.png",
            link:"/user/profile"
        },
        {
            text:"Assesment History",
            img:"/images/history.png",
            link:"/user/assesment-history"
        },
        {
            text:"Live Class",
            img:"/images/play-button.png",
            link:"#"
        },
        {
            text:"New Features",
            img:"/images/play-button.png",
            link:"#"
        },
        {
            text:"Video Course",
            img:"/images/video.png",
            link:"course"
        },
        {
            text:"My Account",
            img:"/images/user-icon.png",
            link:"/user/account"
        }
    ]
    const listOfSidebarBottom= [
        {
            text:"Setting",
            img:"/images/setting.png",
            link:"#"
        },
        {
            text:"Help",
            img:"/images/customer-service.png",
            link:"#"
        }
    ]
    const [userInfo,setUserInfo] = useState(localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {}) 
    const token = localStorage.getItem("token")

    const handleOnChange = async (event)=>{
        try {
            const result = await uploadFile(event.target.files[0],'image')
            if(result?.data && result?.isSuccess) {
                setUserInfo({...userInfo,avatar:result?.data})
                const update = await putUser({...userInfo,avatar:result?.data})
                localStorage.setItem('userInfo',JSON.stringify({...userInfo,avatar:result?.data}))
            }
        } catch (error) {
            
        }
    }
    const handleClick = (value)=>{
        if(value === "course" )  return window.open(`${process.env.REACT_APP_ONLINE_DOMAIN}/study-route`,'_blank')
        window.location.href = value
    }
    return(
        <div className="col-md-2 profile-left">
            <div className="card ">
                <div className="card-body box-profile p-0">
                    <div className="text-center avata-box">
                        <a className="navbar-brand " href="/">
                            <img
                                src="/images/logo.png"
                                className="me-2"
                                // height="63"
                                width="100"
                                alt="Logo"
                                loading="lazy"
                            />
                        </a>
                        {/* <img className="profile-user-img img-fluid img-circle" src={userInfo.avatar? `${process.env.REACT_APP_BE_HOST}${userInfo.avatar}` : `${process.env.REACT_APP_DOMAIN}/images/user.png`} alt="User profile picture" />
                        <div className="custom-file">
                            <input type="file" className="custom-file-input visually-hidden" accept="image/png, image/jpeg" id="inputAvata" onChange={handleOnChange} />
                            <label  htmlFor="inputAvata"><img src='/images/camera.png' /></label>
                        </div> */}
                    </div>
                    {/* <h3 className="profile-username text-center">{ userInfo?.displayName ? userInfo?.displayName : userInfo?.userName}</h3> */}
                    <ul className="mb-3 sidebar p-0">
                        <li ><b>Danh Mục</b></li>
                        {
                            listOfSidebar.map((e,i)=>{
                                return(
                                    <li key={i} className={window.location.pathname === e.link ? "active" : ""} onClick={()=>handleClick(e.link)}>
                                        <a  href="#"> <img src={e.img}/>{e.text}</a>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                </div>
                <div className="card-body box-setting p-0">
                    <ul className="mb-3 sidebar p-0">
                        <li ><b>Thiết lập</b></li>
                        {
                            listOfSidebarBottom.map((e,i)=>{
                                return(
                                    <li key={i} className={window.location.pathname === e.link ? "active" : ""} onClick={()=>handleClick(e.link)}>
                                        <a  href="#"> <img src={e.img}/>{e.text}</a>
                                    </li>
                                )
                            })
                        }
                        
                    </ul>
                </div>
            </div>
        </div> 
    )
}

export default Sidebar