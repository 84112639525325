import axios from 'axios';

const httpClient = axios.create({
    baseURL: process.env.REACT_APP_BE_HOST,
    timeout: 10000,
    headers: {
        "Content-Type": "application/json",
    },
    withCredentials: true
});

// const getAuthToken = () => localStorage.getItem('token');

// const authInterceptor = (config) => {
//     config.headers['Authorization'] = `Bearer ${getAuthToken()}`;
//     return config;
// }

// interceptor to catch errors
const errorInterceptor = error => {
    // check if it's a server error
    if (!error.response) {
    //   notify.warn('Network/Server error');
      return Promise.reject(error);
    }
    // all the other error responses
    switch(error.response.status) {
        case 400:
            console.error(error.response.status, error.message);
            // notify.warn('Nothing to display','Data Not Found');
            break;
        case 401: // authentication error, logout the user

            localStorage.removeItem('token');
            localStorage.removeItem('userInfo');
            window.location.replace('/auth/login');
            // router.push('/auth');
            break;
        default:
            console.error(error.response.status, error.message);
    }
    return Promise.reject(error);
}
// Interceptor for responses
const responseInterceptor = response => {
    switch(response.status) {
        case 200: 
            // yay!
            break;
        // any other cases
        default:
            // default case
    }
    return response;
}

// httpClient.interceptors.request.use(authInterceptor);

httpClient.interceptors.response.use(responseInterceptor, errorInterceptor);

export default httpClient;