import React, { useEffect, useState } from "react";
import "./speakingMb.scss"
import Spinner from "../share/Sprinner/index.js";
import { useLocation } from "react-router-dom";
import RecordViewMb from "../share/Recorder/recordViewMb.js";

const SpeakingMb = ({nextSkill})=>{
    // const [listAnswered,setListAnsered]= useState(localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map())
    const [listAnswered,setListAnsered] = useState(new Map())
    const [spData,setSpData]= useState([])
    const [part,setPart] = useState(0)
    const [testType,setTestType] = useState()
    const [showSprinner, setShowSprinner] = useState(false);

    const token = localStorage.getItem("token")
    const location = useLocation()
    const uri = location.pathname

    const nextPart =()=>{
        setPart(part+1)
    }
    
    const handleRecord = async ({data,status})=>{
        if(!status) return alert("Lỗi upload recording!")
        setShowSprinner(true)
        try {
            let stgAnswer = localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map();
            stgAnswer.set(
                spData[part].id, 
                {
                    questionId:spData[part].id,answerContent:data
                }
            );

            setListAnsered(stgAnswer);
            localStorage.setItem('answer',JSON.stringify(Object.fromEntries(stgAnswer)))
            nextPart()
            
            setShowSprinner(false)
        } catch (error) {
            console.log('Elsa error',error)
            setShowSprinner(false)
        }              
    }

    const getData = ()=>{
        console.log(333)
        const data = JSON.parse(localStorage.getItem('exam'))
        if(!data) return
        setTestType(data?.testType)
        const newQuesion =  data?.questionContent
        // const newQuesion = JSON.parse(questionContent)
        let content = newQuesion?.languageSkillExams.filter(e => e.languageSkillType ==='Speaking');
        let newData = []
        content[0].partitionExams[0]?.sectionExams?.map((e)=>{
            e.groupQuestionExams?.map((i)=>{
                i.questionExams?.map((j)=>{
                    newData.push(j)
                })
            })
        })
        setSpData(newData)

        // console.log('newData',newData)
        // newData.map((e)=>{
        //     let stgAnswer = localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map();
        //     stgAnswer.set(
        //         e.id, 
        //         {
        //             questionId:e.id,answerContent:"https://apitest.ant-edu.ai/media/9d2b0740-291b-43fa-bb82-9f4d66bc6500.mp3"
        //         }
        //     );
        //     localStorage.setItem('answer',JSON.stringify(Object.fromEntries(stgAnswer)))
        // })
        
    }
    useEffect(()=>{
        // nextSkill(3) /// comment speaking
        getData()
    },[])
    return(
        <>
            <div className="speaking"  id="speaking">
                <div className="justify-content-center align-items-center ">                    
                    <div className="speaking-part">
                        {
                            // isDesktop ? spData?.map((j,index)=>{
                            //     return(
                            //             <div className={part ===(index)? "box-speaking-part active":"box-speaking-part" } key={j.id}>
                            //                 <div className="content" dangerouslySetInnerHTML={{__html:j.content}}></div>              
                            //             </div>
                            //         )
                            //     })
                            // :
                            spData?.map((j,index)=>{
                                let time = index >= 1 ? 120 : 45
                                return(
                                    <>                                    
                                        <div key={index} className={`box-speaking-part ${index===part ? "active" : ""}`}>
                                            <div className="content" dangerouslySetInnerHTML={{__html:j?.content}}></div>              
                                        </div>
                                        {
                                            index >= part ? 
                                                <RecordViewMb handleRecord={handleRecord} active={index===part ? true : false} timeRecord={time} /> 
                                            : 
                                                <div className="part-passed">
                                                    <img src="/images/mb/tick.png" />
                                                    <p className="passed-text">Your Part {index + 1}’s answer is recorded sucsessfully</p>
                                                </div>
                                        }
                                        
                                    </>
                                )
                            })
                            //     <>
                            //     <div className="box-speaking-part active">
                            //         <div className="content" dangerouslySetInnerHTML={{__html:spData[part]?.content}}></div>              
                            //     </div>
                            //         <RecordView handleRecord={handleRecord} nextPart={nextPart} />   
                            // </>
                            
                        }                                    
                    </div>
                </div>
            </div>
            {showSprinner && <Spinner/>}

        </>
    )
}

export default SpeakingMb