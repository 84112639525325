import React from "react";
import "./index.scss"
const NotFound = ()=>{
    return(
        <div className='container-fluid not-found d-flex align-items-center'>
            <div className="container">
                <div className="content-wrapper">
                    <section className="content">
                        <div className="error-page">
                        <h2 className="headline text-warning"> 404</h2>
                        <div className="error-content">
                            <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Page not found.</h3>
                            <p>
                            We could not find the page you were looking for.
                            Meanwhile, you may <a href="/">return to Home page</a> or try using the search form.
                            </p>
                            <form className="search-form">
                            </form>
                        </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default NotFound