import React from "react";
import './index.scss'
const FillTheBlankReView = ({data,point,answer})=>{

    const renderMarkAsCorrect =(point)=>{
        if(point?.points) return <span className="correct-true">(TRUE)</span>
        return <span className="correct-false">(FALSE)</span>
    }
    return (
        <div className="fillTheBlankReView-review">  
            <div className="question-name"></div>
            {
                renderMarkAsCorrect(point)
            }
            <div className="question-content" dangerouslySetInnerHTML={{__html:data?.content}}></div>
            <div className="view-answerExams">
                <u>Answer:</u> {data?.answerExams?.map(x=><span key={x?.id}>{x.content},&nbsp; </span>)}
            </div>
            <div className="view-answerExams">
                <u>Your Answer :</u><span>{answer?? ''}</span>
            </div>
                        
        </div>
    )
}
export default FillTheBlankReView