import React, { useEffect, useState } from "react";
import "./index.scss"
import { useParams } from "react-router-dom";
import { getAssessmentById } from "../../../../services/assessmentTestService";

const AssesmentById = ()=>{
    const {id} = useParams();
    const [data,setDate] = useState()
    const [answerContent,setAnswerContent] = useState()
    const [pointContent,setPointContent] = useState()
    const [questionContent,setQuestionContent] = useState()

    const getDataByID = async (id)=> {
        if(!id) return
        const result = await getAssessmentById(id)
        console.log(result)
        if(result && result.isSuccess){
            // setAnswerContent(JSON.parse(result.data.answerContent))
            // setPointContent(JSON.parse(result.data.pointContent))
            // setQuestionContent(JSON.parse(result.data.questionContent))
            setAnswerContent(result.data.answerContent)
            setPointContent(result.data.pointContent)
            setQuestionContent(result.data.questionContent)
        }else{
            setDate(result)
        }
    }

    const getPointContentByID = (id)=>{
        const point = pointContent?.find((e)=>e.questionId === id)
        return point
    }
    const getAnswerContentByID = (id,answer,questionType)=>{
        
        const resultAnswer = answerContent?.find((e)=>e.questionId === id)
        if(questionType==='SingleChoice'){
            const resullt =answer.find((e)=>e.id === resultAnswer?.answerContent )
            return resullt?.content ?? '';
        }else if(questionType==='MultipleChoice' && resultAnswer?.answerContent){
            const resullt =answer.filter((e)=>JSON.parse(resultAnswer?.answerContent).indexOf(e.id) != -1 )
            return resullt.map(x => x.content).join('<br/>')
        }else if(questionType==='TopicSpeaking' || questionType==='TextSpeaking'){
            // console.log(resultAnswer)
            return `<audio className="w-100" src="${resultAnswer?.answerContent ?? ''}" controls="controls"  ></audio>`
        }        
        // console.log(questionType)
        // console.log(resultAnswer)

        return resultAnswer?.answerContent ?? ''
    }
    useEffect(()=>{
        getDataByID(id)
    },[])
    console.log(data)
    return(
        <div className="col-md-10 assesment-details">
            <h3 className="text-center">Assesment Details</h3>
            <div className="accordion" >
                {
                    data?.isSuccess ===false && 
                    <>{data.errors[0]?.message}</>
                }
                {
                    questionContent?.languageSkillExams?.map((e)=>{
                        // console.log(e)
                        return(
                            <div className="accordion-item" key={e.id}>
                                <h2 className="accordion-header">
                                    <button className="accordion-button" type="button" disabled aria-expanded="true" >
                                    {e.languageSkillType} 
                                    </button>
                                </h2>
                                <div className="accordion-collapse collapse show">
                                    <div className="accordion-body">
                                        {
                                            e.partitionExams?.map((i)=>{                                                
                                                return(
                                                    <>
                                                    {
                                                        i.sectionExams?.map((j)=>{
                                                            // console.log(j)
                                                            return(
                                                                <div className="session">
                                                                    <p className="session-title" dangerouslySetInnerHTML={{__html:j.description}}></p>
                                                                    {
                                                                        j.groupQuestionExams?.map((t)=>{
                                                                            // console.log(t)
                                                                            return(
                                                                                <div className="group-question">
                                                                                <p dangerouslySetInnerHTML={{__html:t.description}}></p>
                                                                                {
                                                                                    t.questionExams?.map((k)=>{
                                                                                        const answer = k.answerExams?.filter((l)=>l.isCorrect === true )
                                                                                        const point = getPointContentByID(k.id)
                                                                                        return(
                                                                                            <div className="question-box">
                                                                                                <p  className="question-name" dangerouslySetInnerHTML={{__html:k.content}}></p>
                                                                                                <table className="table">
                                                                                                    
                                                                                                    <tbody>
                                                                                                        <tr  className="table-dark text-center" >
                                                                                                            <th scope="col">Đáp án</th>
                                                                                                            <th scope="col">Câu trả lời của bạn</th>
                                                                                                            <th scope="col">Điểm {point?.points ? <i className="fa-solid fa-check answer-true"></i>:<i className="answer-false fa-solid fa-xmark"></i>}</th>
                                                                                                        </tr>
                                                                                                        <tr  className="text-center" >
                                                                                                            <td >
                                                                                                                {
                                                                                                                    answer?.map(x=><><span>{x.content}</span><br/></>)
                                                                                                                }
                                                                                                            </td>
                                                                                                            <td dangerouslySetInnerHTML={{__html:getAnswerContentByID(k.id,k.answerExams,k.questionType)}}></td>
                                                                                                            <th>{point?.points ?? 0}</th>
                                                                                                        </tr>
                                                                                                        {
                                                                                                            point?.note && 
                                                                                                            <tr  className="text-center" >
                                                                                                                <td dangerouslySetInnerHTML={{__html:point?.note}}>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        }
                                                                                                       
                                                                                                    </tbody>
                                                                                                </table>
                                                                                            </div>
                                                                                        )
                                                                                    })
                                                                                }
                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                            </div>   
                        )
                    })
                }
                             
            </div>
        </div>
    )
}

export default AssesmentById