import React, { useEffect, useState } from 'react'
import './WelcomeLight.scss'
import HomeMenu from '../Layout/Menu/Home'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isDesktop} from "react-device-detect";
import WelcomeMb from './WelcomeMb'
import MenuWCLight from '../Layout/Menu/MenuLight1'

const WelcomeLight = () => {
    const { t } = useTranslation();
    const [username,setUsername] = useState()
    const navigate = useNavigate()
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    const skill = localStorage.getItem("skill")
    const subSkill = localStorage.getItem("subSkill")
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const handleClick = ()=>{        
        if(userInfo.userName || username){
            if(skill==='/speaking' && subSkill !=='' ) return navigate('/speakingai')
            if(skill==='/speaking') return navigate(skill)
            return navigate('/listening')
        }
        if(!username) return alert("Username Requirements")
        userInfo.userName = username;
        localStorage.setItem("userInfo",JSON.stringify(userInfo))
        
        if(skill==='/speaking') return navigate(skill)
        return navigate('/listening')
    }
    return (
        <div className='container-fluid welcome p-0' id="welcomeLight">
            <MenuWCLight />
            <div className='intro  d-flex  '>
                <div className='container'>
                    <div className='row d-flex justify-content-center' >
                        <div className='col-md-7 d-flex flex-column justify-content-center ' >

                            <img className='wc-img' src='/images/mb/welcom-img.png' />
                            {
                                userInfo.userName ? 
                                <>
                                    <p className='h2 title'>Hello {userInfo.userName}, </p>
                                    <p className='detail'>{t(`welcome.detail`)}</p>
                                    <div className="btn-get-started" onClick={()=>handleClick()}>
                                        <p>GET STARTED</p>
                                    </div>
                                </>
                                :
                                <>
                                    {/* <p className='h2 title'>Welcome to <span>Ant Edu’s</span>  Input Test!</p> */}
                                    <p className='h2 title' dangerouslySetInnerHTML={{__html:t(`welcome.hello`) }}></p>
                                    <p className='detail'>{t(`welcome.detail`)}</p>
                                    <div className="input-group mb-3 box-username ">
                                        <input type="text" className="form-control" placeholder="Recipient's username" aria-label="Enter your name here" aria-describedby="basic-addon2" onChange={event => setUsername(event.target.value)}/>
                                        <div className="input-group-append">
                                            <div className="btn-get-started" onClick={()=>handleClick()}>
                                                <p>GET STARTED</p>
                                            </div>
                                        </div>
                                    </div>
                                </>
                            }
                                                                                     
                        </div>
                    </div>
                </div>
            </div>                
        </div>
    )
}
export default WelcomeLight