import React, { useEffect, useRef, useState } from 'react'
import './welldone.scss'
import HomeMenu from '../Layout/Menu/Home'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { autoCalculatePoint, reviewExamId, submitExam } from '../../services/ieltsService'
import { isDesktop} from "react-device-detect";
import WelldoneMb from './welldoneMb'
import Spinner from '../PresenTations/share/Sprinner'
import WelldoneLight from './WelldoneLight'

const Welldone = () => {
    const { t } = useTranslation();
    const navigate = useNavigate()
    const [email,setEmail] = useState();
    const [complete,setComplete] = useState(false)
    const [statusSprin,setStatusSprin] = useState(true)
    const refStatus = useRef(true);
    const [phoneNumber,setPhoneNumber] = useState();
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    const [token,setToken] = useState(localStorage.getItem('token'))
    const  stgAnswer = localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map();
    const  stgExam = JSON.parse(localStorage.getItem('exam'));
    const subSkill = localStorage.getItem("subSkill");
    const skill = localStorage.getItem("skill");
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const handleSubmit =()=>{
        // const subSkill = localStorage.getItem("subSkill");
        // const skill = localStorage.getItem("skill");
        const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {};
        userInfo.email = email;
        userInfo.phoneNumber = phoneNumber;
        localStorage.setItem("userInfo",JSON.stringify(userInfo));
        // if(userInfo.id){
            if(skill === "/speakingai" && subSkill !="" ) return navigate("/result-analysis-ai")
            navigate("/result-analysis")
        // }
      
    }
    const handleback =()=>{
        navigate("/")
    }

    const submit = async ()=>{        
        const data = {
            'id':stgExam.id,
            'answers':Array.from(stgAnswer.values())
        }
        const result = await submitExam(data)
        const calculatePoint = autoCalculatePoint(stgExam?.id)

        let intervalId = setInterval(async () => {
            const result = await reviewExamId(stgExam?.id)
            if(result?.data?.pointContent?.length>0){
                setComplete(true)      
                clearInterval(intervalId);         
            }
        }, 10000);
        
    }
    
    useEffect(()=>{
        if(refStatus.current){   
            refStatus.current = false
            if(localStorage.getItem('exam')) submit()
        }
        
    },[])
    return (
        <>   
            
            <div className='container-fluid welldone p-0' id="welldone">
                {
                    isDesktop ? 
                        <>
                        {
                            darkMode =="dark" &&
                            <>
                                <HomeMenu/>                
                                <div className='intro'>
                                    {/* <div className='container'> */}
                                        <div className='row w-100 justify-content-between' style={{position:"absolute"}} >
                                            <div className='box-phao-hoa col-md-4'>
                                                <img src='/images/phaohoa.gif' alt="gif"  loading="lazy" /> 
                                            </div>
                                            <div className='box-phao-hoa col-md-4 '>
                                                <img src='/images/phaohoa.gif' alt="gif"  loading="lazy" style={{transform: "rotate(90deg)"}}/> 
                                            </div>
                                        </div>
                                        <div className='row w-100 d-flex justify-content-center' style={{position:"absolute"}} >
                                            <div className='box-head d-flex justify-content-center flex-column'>
                                                <p className='h2 title text-center'><span>{t(`welldone.title`)}</span></p>
                                                <p className='detail text-center'>{t(`welldone.thankyou`)} <span  style={{color:"#00F0FF", fontWeight:"bold"}}>{userInfo.userName} </span>,<br/> {(skill === "/speakingai" && subSkill !="") ? t(`welldone.successfullyAi`) :t(`welldone.successfully`) }</p>
                                            </div>                            
                                            <div className='box-content d-flex  align-items-center justify-content-center'>
                                                <div className='col-md-8 box-content-detail'>
                                                    <div className='row h-100 d-flex justify-content-center' >
                                                        <div className='col-md-4 d-flex justify-content-center align-items-center'>
                                                            <div className='form-info'>
                                                                {
                                                                    complete?  
                                                                    <div className='row justify-content-center d-flex flex-nowrap p-0 m-0'>
                                                                        <button className='btn btn-back' onClick={()=>handleback()}><b>{t(`welldone.btnResultback`)}</b></button>
                                                                        <button className='btn btn-completed' onClick={()=>handleSubmit()}>{t(`welldone.btnComplete`)}</button>
                                                                    </div>
                                                                    :
                                                                    <div className='row justify-content-center d-flex flex-nowrap p-0 m-0'>
                                                                        <button className='btn btn-back' onClick={()=>handleback()}><b>{t(`welldone.btnResultback`)}</b></button>
                                                                        <button className='btn btn-completed btn-loading' disabled >
                                                                            <div className="spinner-border" role="status">
                                                                                <span className="sr-only">Đang chấm bài...</span>
                                                                            </div>   
                                                                            
                                                                            <b>Đang chấm bài...</b>
                                                                        </button>
                                                                    </div>
                                                                } 
                                                                <i className='note' dangerouslySetInnerHTML={{__html:t(`welldone.note`)}}></i>
                                                            </div>  
                                                        </div>
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                        </div>
                                        
                                    {/* </div> */}
                                </div>
                            </>
                        }
                        {
                            darkMode =="light" && <WelldoneLight  complete={complete} handleSubmit={handleSubmit}/>
                        }
                            
                        </>
                    :
                     <WelldoneMb complete={complete} />
                }
                {/* {
                    statusSprin && <Spinner/>
                } */}
            </div>
            
        </>
    )
}
export default Welldone