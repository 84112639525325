import React, { useEffect, useRef, useState } from 'react'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from '@ckeditor/ckeditor5-custom-build/build/ckeditor'
import classNames from 'classnames'
import CommentsAdapter from '../components/ComponentShare/CKEditer/CommentsAdapter'
import ckeditor5Helpers from '../components/ComponentShare/CKEditer/ckeditor5Helpers'

const commentSideBarDisplayMode = ['inline', 'wideSidebar', 'narrowSidebar']
const defaultConfig = {
  placeholder: 'Typing here...',
  fontSize: {
    options: [9, 11, 13, 'default', 17, 19, 21],
  },
  extraPlugins: [ckeditor5Helpers.createImageUploadPlugins(), CommentsAdapter],
  licenseKey: process.env.REACT_APP_CKEDITOR5_LICENSE_KEY,
  sidebar: {
    // preventScrollOutOfView: false,
  },
  toolbar: [
    // 'bold',
    // 'underline',
    // 'italic',
    // 'strikethrough',
    // 'alignment',
    // '|',
    // 'comment',
    // 'commentsArchive',
  ],
  commentsOnly: true,
  collaboration: {
    channelId: '',
  },
}
/**
 *
 * @param {EditorConfig} param0
 * @param  {EditorConfig} otherProps
 * @returns
 */
const CCKEditor5WithComments = (
  { value, config, onChange, onBlur, onFocus, onReady, onError, invalid, valid },
  ...otherProps
) => {
  const sideBarRef = useRef()
  const editorRef = useRef()
  const [displayMode, setDisplayMode] = useState(commentSideBarDisplayMode[0])
  const onReadyEditor = (editor) => {
    editorRef.current = editor
    onReady?.(editor)
  }
  const onChangeEditor = (e, editor) => {
    onChange?.(editor.getData())
  }
  const onBlurEditor = (e, editor) => {
    onBlur?.(e, editor)
  }
  const onFocusEditor = (e, editor) => {
    onFocus?.(e, editor)
  }
  const onErrorEditor = (error, errorDetail) => {
    console.error(error, errorDetail)
    onError?.(error, errorDetail)
  }

  useEffect(() => {
    if (sideBarRef.current) {
      sideBarRef.current.style.display =
        displayMode === commentSideBarDisplayMode[0] ? 'none' : 'block'
    }
  }, [displayMode])

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.plugins.get('Sidebar').setContainer(sideBarRef.current)
      editorRef.current.plugins.get('AnnotationsUIs').switchTo(displayMode)
    }
    return () => {
      if (editorRef.current) {
        editorRef.current.plugins?.get('AnnotationsUIs')?.deactivateAll()
      }
    }
  }, [])
  return (
    <div
      className={classNames({
        'is-valid': valid === true ? true : false,
        'is-invalid': invalid === true ? true : false,
        'editor-container': true,
      })}
    >
      <CKEditor
        editor={Editor}
        config={{
          ...defaultConfig,
          ...config,
          extraPlugins: [...defaultConfig.extraPlugins, ...(config?.extraPlugins || [])],
        }}
        data={value}
        onReady={onReadyEditor}
        onChange={onChangeEditor}
        onBlur={onBlurEditor}
        onFocus={onFocusEditor}
        onError={onErrorEditor}
        {...otherProps}
      />
      <div
        className={classNames('sidebar-container', {
          narrow: displayMode === commentSideBarDisplayMode[2],
        })}
        style={{
          minWidth:
            displayMode === commentSideBarDisplayMode[0]
              ? 0
              : displayMode === commentSideBarDisplayMode[1]
                ? 300
                : 65,
        }}
        ref={sideBarRef}
      ></div>
    </div>
  )
}

export default CCKEditor5WithComments
