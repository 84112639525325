import { Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import './App.css';
import ProtectedRoute from './components/Layout/ProtectedRoute';
import { createBrowserHistory } from 'history';
import Welcome from './components/Welcome/Welcome';
import Shorttest from './components/ShortPage';
import RealIELTSRoute from './components/Route/RealIELTSRoute';
import Welldone from './components/Welldone/Welldone';
import ResultAnalysis from './components/ResultAnalysis';
import Completing from './components/Completing';
import HomePage from './components/HomePage';
import Fulltest from './components/FullTestPage';
import Reading from './components/PresenTations/Reading';
import Login from './components/Auth/LoginPage';
import CreateAccount from './components/Auth/CreateAccountPage';
import ForgotPassword from './components/Auth/ForgotPasswordPage';
import Survey from './components/SurveyPage';
import ListeningPage from './components/ListeningPage';
import ReadingPage from './components/ReadingPage';
import WritingPage from './components/WritingPage';
import SpeakingPage from './components/SpeakingPage';
import GuestIELTSRoute from './components/Route/GuestIELTSRoute';
import UserRoute from './components/Route/UserRoute';
import HomeUserPage from './components/UserPage/HomePage';
import { useEffect } from 'react';
import NotFound from './components/NotFoundPage';
import AssesmentHistory from './components/UserPage/AssesmentHistory';
import MyCourse from './components/UserPage/MyCourse';
import MyAccount from './components/UserPage/MyAccount';
import SpeakingAiPage from './components/SpeakingAIPage';
import ResultAnalysisAi from './components/ResultAnalysisAi';
import OverView from './components/UserPage/AssesmentHistory/OverView';
import AssesmentByIdNew from './components/UserPage/AssesmentHistory/AssesmenByIdNew';
import AssesmentById from './components/UserPage/AssesmentHistory/AssesmenById';
import ExamByCode from './components/ExamByCodePage';
import ListTest from './components/ListTestPage';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AuthRoute from './components/Route/AuthRoute';

function App() {
  const history = createBrowserHistory();
  
  return (
    <>
      <Routes history={history}>        
        <Route path='/' element={<HomePage />} />
        <Route path='/exam-by-code' element={<ExamByCode />} />
        <Route path='/list-test' element={<ListTest />} />
        <Route element={<AuthRoute />}>   
          <Route path='/welcome' element={<Welcome />} />
          <Route path='/welldone' element={<Welldone />} />
          <Route path='/result-analysis' element={<ResultAnalysis />} />
          <Route path='/result-analysis-ai' element={<ResultAnalysisAi />} />
          <Route path='/completing' element={<Completing />} />
          <Route path='/survey' element={<Survey />} />          
        </Route>
        <Route element={<RealIELTSRoute />}>          
          <Route path='/shorttest' element={<Shorttest />} />
          <Route path='/fulltest' element={<Fulltest />} />
        </Route>
        <Route element={<GuestIELTSRoute />}>  
          <Route path='/listening' element={<ListeningPage />} />
          <Route path='/reading' element={<ReadingPage />} />
          <Route path='/writing' element={<WritingPage />} />
        </Route>
        <Route path='/speaking' element={<SpeakingPage />} />
        <Route path='/speakingai' element={<SpeakingAiPage />} />
        <Route path='/auth'>
          <Route path='login' element={<Login />} />
          <Route path='register' element={<CreateAccount />} />
          <Route path='forgot-password' element={<ForgotPassword />} />
          <Route path='*' element={<NotFound />}></Route>       
        </Route>
        <Route path='/user' element={<UserRoute />}>
          <Route index path='profile' element={<MyCourse />} />
          <Route path='assesment-history' element={<AssesmentHistory />} />
          <Route path='assesment/:id' element={<AssesmentById />} />
          <Route path='assesment/overview/:id' element={<OverView />} />
          <Route path='account' element={<MyAccount />} />
          <Route path='*' element={<NotFound />}></Route>
        </Route>
        <Route path='assesment-detail/:id' element={<AssesmentByIdNew />} />
        <Route path='*' element={<NotFound />}></Route>
      </Routes>
      <ToastContainer />
    </>
  );
}

export default App;
