import { editorCommentService } from "./editorComment.service"

class CommentsAdapter {
  constructor(editor) {
    this.editor = editor
  }

  static get requires() {
    return ['CommentsRepository']
  }

  init() {
    const commentsRepositoryPlugin = this.editor.plugins.get('CommentsRepository')
    const usersPlugin = this.editor.plugins.get('Users')

    // Setting adapter for comments add remove ....
    commentsRepositoryPlugin.adapter = {
      addComment(data) {
        let model = {
          ...data,
          id: data?.commentId,
        }
        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        // When the promise resolves with the comment data object, it
        // will update the editor comment using the provided data.
        // return Promise.resolve({
        //   createdAt: new Date(), // Should be set on the server side.
        // })
        return editorCommentService.createComment(model)
      },

      updateComment(data) {
        let model = {
          ...data,
          id: data?.commentId,
        }
        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        return editorCommentService.updateComment(model)
      },

      removeComment(data) {
        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        return editorCommentService
          .deleteComment(data?.commentId)
          .then((res) => {
            if (res.isSuccess) {
              return res.data[0]
            } else {
              console.error(res?.errors)
              return {}
            }
          })
          .catch((err) => {
            console.error(err.message || 'Có lỗi trong quá trình lấy comments')
          })
      },

      //Tự động chạy nếu dùng Adapter thay vì function Plugin
      getCommentThread(data) {
        // Write a request to your database here. The returned `Promise`
        // should resolve with the comment thread data.

        // const mockupData = {
        //   threadId: 'e02e87b8a67a5ca6415b50564460782a3',
        //   comments: [
        //     {
        //       commentId: 'e660d26cd2ea2a10fd9767aae8d9e26e7',
        //       authorId: '255175',
        //       content: '<p>Are we sure we want to use a made-up disorder name?</p>',
        //       // createdAt: new Date(),
        //       attributes: null,
        //       createdAt: '2024-03-29T08:45:33.15105',
        //       createdBy: '255175',
        //       threadId: 'e02e87b8a67a5ca6415b50564460782a3',
        //       updatedAt: '2024-03-29T08:45:33.15105',
        //       updatedBy: '255175',
        //     },
        //   ],
        //   channelId: '08db8dbb-c072-44cd-8b6c-e1eb2938b10d|08db877b-0655-4cb0-8055-b3ded6e8f1f6',
        //   // It defines the value on which the comment has been created initially.
        //   // If it is empty it will be set based on the comment marker.
        //   context: { type: 'text', value: 'words' },
        //   unlinkedAt: null,
        //   resolvedAt: null,
        //   resolvedBy: null,
        //   createdAt: '2024-03-29T08:45:33.150784',
        //   createdBy: '255175',
        //   updatedAt: '2024-03-29T08:45:33.150784',
        //   updatedBy: '255175',
        //   attributes: null,
        //   // attributes: {},
        //   // isFromAdapter: true,
        // }

        return editorCommentService
          .getThreadByThreadId(data?.threadId)
          .then((res) => {
            if (res.isSuccess) {
              for (const cm of res.data.comments) {
                //Check lại đoạn addUser này nhé xem đúng không/ check luôn đi a =))
                // Dưạ vào cái này
                // Tạm thời check code Add thêm tại đây vì comment có trả về giáo viên nào là người nhận xét
                // Chạy API đi
                var existUser = usersPlugin.getUser(cm.authorId)
                if (!existUser) {
                  usersPlugin.addUser({ id: cm.authorId, name: cm.authorName })
                }
              }
              return res.data
            } else {
              console.error(res?.errors)
              return []
            }
          })
          .catch((err) => {
            console.error(err.message || 'Có lỗi trong quá trình lấy comments')
          })
      },

      addCommentThread(data) {
        if (!data?.channelId) {
          throw new Error('ChannelId cant not be null')
        }
        let model = {
          ...data,
          id: data?.threadId,
          comments: data?.comments?.map((p) => ({ ...p, id: p.commentId })),
        }

        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        return editorCommentService
          .createThread(model)
          .then((res) => {
            if (res.isSuccess) {
              return res.data
            } else {
              console.error(res?.errors)
              return {}
            }
          })
          .catch((err) => {
            console.error(err.message || 'Có lỗi trong quá trình lấy comments')
          })
      },

      updateCommentThread(data) {
        let model = {
          ...data,
          id: data?.threadId,
        }
        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        return editorCommentService
          .updateThread(model)
          .then((res) => {
            if (res.isSuccess) {
              return res.data
            } else {
              console.error(res?.errors)
              return {}
            }
          })
          .catch((err) => {
            console.error(err.message || 'Có lỗi trong quá trình lấy comments')
          })
      },

      resolveCommentThread(data) {
        let model = {
          ...data,
          id: data?.threadId,
        }

        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        return editorCommentService
          .updateThread(model)
          .then((res) => {
            if (res.isSuccess) {
              return res.data
            } else {
              console.error(res?.errors)
              return {}
            }
          })
          .catch((err) => {
            console.error(err.message || 'Có lỗi trong quá trình lấy comments')
          })
      },

      reopenCommentThread(data) {
        let model = {
          ...data,
          id: data?.threadId,
        }
        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        return editorCommentService
          .updateThread(model)
          .then((res) => {
            if (res.isSuccess) {
              return res.data
            } else {
              console.error(res?.errors)
              return {}
            }
          })
          .catch((err) => {
            console.error(err.message || 'Có lỗi trong quá trình lấy comments')
          })
      },

      removeCommentThread(data) {
        // Write a request to your database here. The returned `Promise`
        // should be resolved when the request has finished.
        return editorCommentService.deleteThread(data?.threadId)
      },
    }
  }
}

export default CommentsAdapter
