import React from 'react'
import { NavLink, Outlet, useLocation, useNavigate } from 'react-router-dom'
import MenuUser from '../Layout/Menu/MenuUser'
import Sidebar from '../UserPage/Sidebar'

const UserRoute = () => {
  const skill = localStorage.getItem("skill")
  const userInfo = localStorage.getItem("userInfo")
  const location = useLocation()

  const menuItem = [
      {item:"listening"},
      {item:skill === "/shorttest" ? "grammar & reading" :"reading"},
      {item:"writing"},
      {item:"speaking"}
  ]
  const uri = location.pathname
  if(!userInfo){
    return (
      <div className='container-fluid p-0 content-profile'>
        <MenuUser active={''} menuItem={[]} time={uri==="/speaking"? 480000  : 900000}/>
        <div className='container text-center'>
          <h1>Unauthorized :(</h1>
          <span>
            <NavLink to='/auth/login'>Login</NavLink> to gain access
          </span>
        </div>       
      </div>
    )
  }
  return (
    <>
      <div className='container-fluid p-0 content-profile'>
        {/* <MenuUser active={''} menuItem={[]} time={uri==="/speaking"? 480000  : 900000}/> */}
        <div className="container-fluid profile p-0" id="profile">
          <div className="row h-100">
            <Sidebar/>
            <div className="col-md-10 p-0">
              <MenuUser active={''} menuItem={[]} time={uri==="/speaking"? 480000  : 900000}/>
              <div className='row d-flex justify-content-center '>
                <div className='col-md-11 d-flex justify-content-center'>
                  <Outlet />
                </div>              
              </div>              
            </div>            
          </div>
        </div>        
      </div>
    </>
  )
  
}

export default UserRoute
