import React, { useEffect, useState } from 'react'
import './header.scss'
import Countdownclock from '../../../PresenTations/Countdownclock'
import { useLocation } from 'react-router-dom';

const HeaderMB = ({content,nextSkill,section}) => {
    // console.log("---section--",section)
    // console.log("---content--",content)
    let location = useLocation();
    const [statusExten,setStatusExten] = useState(false);
    const [totalQuestion,setTotalQuestion] = useState([])
    const [listAnswered,setListAnsered]= useState(new Map())
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )

    let statusbtnNext = true;
    // console.log(userInfo)
    // console.log(section)
    // console.log(location)
    if(userInfo?.id ) {
        if(section >=3 ) statusbtnNext = false;// submit
        if(location.pathname=="/speakingai") statusbtnNext = false;// submit
        
    }else if(location.pathname=="/writing" || location.pathname=="/speakingai"){
        statusbtnNext = false;// submit
    }    
    // console.log("statusbtnNext",statusbtnNext)
    const getData = ()=>{        
        const newdata =  JSON.parse(localStorage.getItem('exam'))?.questionContent
        // const newdata = JSON.parse(questionContent?? 0)
        // console.log(newdata)

        let data = newdata?.languageSkillExams?.find(e => e.languageSkillType ===content);
        // console.log("--data---",data)
        // setData(content)
        
        let arrQuestion = [];
        data?.partitionExams.map((t)=>{
            t.sectionExams?.map((e)=>{
                e.groupQuestionExams?.map((i)=>{
                    i.questionExams?.map((j)=>{
                        let newPoint = j.points ?? 1;
                        for (let index = 0; index < newPoint; index++) {
                            arrQuestion.push(j.id)                    
                        }
                    })
                })
            })
        })
      
        setTotalQuestion(arrQuestion)
        let intervalAnswer;
        intervalAnswer = setInterval(() => {
            let stgAnswer = localStorage.getItem('answer') ? new Map(Object.entries(JSON.parse(localStorage.getItem('answer')))) : new Map();         
            setListAnsered(stgAnswer);
        }, 1000);                 
    }
    const handleNext = ()=>{
        nextSkill()
    }
    const renderBtnNext =()=>{
        return(
            <>
                <div className='btn-next' data-bs-toggle="modal" data-bs-target="#nextModal" >
                    Next
                </div>
                <div className="modal fade" id="nextModal" tabIndex={-1} aria-labelledby="nextModal" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className='row row-content'>
                                <img src='/images/mb/submit.png' />
                                <p className='text-center'><b>Bạn đã sẵn sàng cho phần thi tiếp theo?</b></p>
                                <p className='text-center'>Hệ thống sẽ không thể quay lại phần thi trước nên hãy kiểm tra thật kỹ trước khi tiếp tục nhé!</p>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" data-bs-dismiss="modal" aria-label="Close"  >Hủy</button>
                                </div>
                                <div className='col-6'>
                                    <button type="button" className="btn  btn-lg w-100 btn-submit" data-bs-dismiss="modal" aria-label="Close"  onClick={()=>nextSkill(section+1)}>Tiếp tục</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </> 
        )
    }

    const renderBtnSubmit =()=>{
        return(
            <>
                <div className='btn-next' data-bs-toggle="modal" data-bs-target="#submitModal">
                    Submit
                </div>
                <div className="modal fade" id="submitModal" tabIndex={-1} aria-labelledby="speakingModalLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className='row row-content'>
                                <img src='/images/mb/submit.png' />
                                <p className='text-center'>Thật tuyệt vời bạn đã hoàn thành trước khi thời gian kết thúc!</p>
                                <p className='text-center'><b>Bạn chắc chắn muốn nộp bài thi?</b></p>
                            </div>
                            <div className='row mt-3'>
                                <div className='col-6'>
                                    <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" data-bs-dismiss="modal" aria-label="Close"  >Hủy</button>
                                </div>
                                <div className='col-6'>
                                    <button type="button" className="btn  btn-lg w-100 btn-submit" onClick={()=>window.location.href='/welldone'}>Nộp bài</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>          
        )
    }
    useEffect(()=>{
        getData();
    },[section])
    
    return (
        <div className='wc-header'>
            <div className='header-top'>
                <div className='wc-back'>
                    <div className='btn-back' onClick={()=>window.location.href="/"}>
                        <i className="fa-solid fa-chevron-left"></i>
                    </div>                    
                </div>
                <div className='wc-title'>
                    {content}
                </div>
                <div className='wc-next'>
                    {
                      nextSkill && statusbtnNext &&  renderBtnNext()
                    }
                    {
                      nextSkill && !statusbtnNext &&  renderBtnSubmit()
                    }
                </div>
            </div>
            {
                section >=0 &&  <div className='extend'>
                {
                    statusExten ? 
                    <div className='extend-opend'>
                        <div className='list-answer'>
                            <div className="p-0 d-flex align-items-center">
                                <ul className="list_question p-0 m-0">
                                    {
                                        totalQuestion.map((e,i)=>{
                                            return <li className={listAnswered.get(e) ?"answered":""}  key={i}><a href={`#question-${e}`}>{i+1}</a></li>
                                        })
                                    }
                                </ul>
                            </div>
                        </div>
                        <div className='answered-detail'>
                            <ul className="w-100 d-flex align-items-center justify-content-start mt-2 p-0">
                                <li><div className="circle " ></div></li>                                 
                                <li>Unanswered</li>                             
                                <li><div className="circle circle-answered" ></div></li>                                 
                                <li>Answered</li>                                 
                            </ul>
                        </div>
                        <div className='acction-extend' onClick={()=>setStatusExten(!statusExten)}>
                            Mở rộng
                            <i className="fa-solid fa-chevron-up"></i>
                        </div>
                    </div>
                    :
                    <>
                        <div className='clock'>
                            <Countdownclock time={900000}/>
                        </div>
                        <div className='acction-extend' onClick={()=>setStatusExten(!statusExten)}>
                            Mở rộng
                            <i className="fa-solid fa-chevron-down"></i>
                        </div>
                    </>
                }                
               </div>
            }
          
        </div>
    )
}
export default HeaderMB