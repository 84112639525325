import React from "react";
import { useTranslation } from "react-i18next";
import Countdownclock from "../../../PresenTations/Countdownclock";
import { useNavigate } from "react-router-dom";
import { isDesktop} from "react-device-detect";

const MenuFullTest = (props)=>{
    const { t, i18n } = useTranslation();
    const navigate = useNavigate()
    const userInfo = localStorage.getItem("userInfo") ? JSON.parse(localStorage.getItem('userInfo')) : {};
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const handleClick =()=>{
        const check = window.confirm("Bạn chắc chắn sẽ thoát khỏi bài kiểm tra chứ!");
        if(check) window.location.href = "/"
    }
    return(
        <>
            <nav className={`navbar navbar-expand-lg navbar-scroll ${darkMode == "light" ? "background-light" : "background-dark"}`} id='menuietls'>
                <div className="container-fluid">
                    <div className='main-logo d-flex justify-content-center' >
                        <a className="navbar-brand " href="#" onClick={()=>handleClick()}>
                            <img
                                src="/images/logo.png"
                                className="me-2"
                                height="63"
                                width="73"
                                alt="Logo"
                                loading="lazy"
                            />
                        </a>
                    </div>
                    <div className='navbarsItem' >
                        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsItemList" aria-controls="navbarsItemList" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon" />
                        </button>
                        <div className="collapse navbar-collapse navbarsItemList" id="navbarsItemList">
                            <ul className="navbar-nav">
                                <li className={props.active === "listening" ? "nav-item active":"nav-item" } >
                                    <a className="nav-link" href="#">Listening</a>
                                </li>
                                <li className={props.active === "reading" ? "nav-item active":"nav-item" } >
                                    <a className="nav-link" href="#">Reading</a>
                                </li>
                                <li className={props.active === "speaking" ? "nav-item active":"nav-item" } >
                                    <a className="nav-link" href="#">Speaking</a>
                                </li>
                                <li className={props.active === "writing" ? "nav-item active":"nav-item" } >
                                    <a className="nav-link" href="#">Writing</a>
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                    <div className='action-ielts' >
                        <div className="row m-0 p-0">
                            <ul>
                                <li>
                                    <div className="d-flex align-items-center p-0">
                                        <Countdownclock />
                                    </div>
                                </li>
                                <li>
                                    <div className="box-btn-submit" data-bs-toggle="modal" data-bs-target="#submission">
                                        <div className="btn-submit m-0 ">
                                            <p className="m-0"><i className="fa-solid fa-location-arrow"></i>Submit</p>
                                        </div>
                                    </div>
                                </li>
                                {
                                    isDesktop &&  <li>
                                    <div className="box-review" onClick={()=>alert("comming soon")}>
                                        <i className="fa-regular fa-eye"></i>Review
                                    </div>
                                </li>
                                }
                            </ul>
                        </div>
                        
                    </div>
                
                </div>
            </nav>
            <div className="modal fade " id="submission" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex={-1} aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-header">
                            <p className="modal-title"><b> { userInfo?.firstName ? userInfo.firstName : userInfo?.displayName? userInfo.displayName : userInfo?.userName }</b></p>
                            <button type="button" className="btn-close btn-close-black" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            <p>Are you sure with this submission?</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-submission btn-submission-no" data-bs-dismiss="modal" aria-label="Close"><b>No</b></button>
                            <button type="button" className="btn btn-submission btn-submission-yes" onClick={()=>window.location.href='/welldone'}><b>Yes</b></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default MenuFullTest