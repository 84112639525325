import React from "react";
import "./index.scss"

const ToggleDarkMode = ()=>{
    const darkMode = localStorage.getItem('darkMode') ?? "dark"

    const toggleTheme = () =>{
        if(darkMode=="light"){
            localStorage.setItem('darkMode','dark')
           
        }
        if(darkMode=="dark"){
            localStorage.setItem('darkMode','light')           
        }
        window.location.reload()
    }
    return(
        <div className='toggle-darl-mode' onClick={()=>toggleTheme()}>
            {
                darkMode =="light" && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  id="dark-mode-icon"><path fill="currentcolor" fillRule="evenodd" d="M12.486 2.675a1 1 0 0 1-.223 1.172 7 7 0 0 0 8.358 11.146 1 1 0 0 1 1.421 1.283A10 10 0 0 1 13 22C7.477 22 3 17.523 3 12c0-4.99 3.653-9.123 8.43-9.877a1 1 0 0 1 1.056.552M8.862 5.152a8 8 0 1 0 9.553 12.738q-.693.109-1.415.11A9 9 0 0 1 8.862 5.152" clipRule="evenodd"></path></svg>
            }
            {
                darkMode == "dark" && <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"  id="light-mode-icon"><path fill="currentcolor" d="M12 5a1 1 0 1 0 0-2 1 1 0 0 0 0 2"></path><path fill="currentcolor" fillRule="evenodd" d="M7 12a5 5 0 1 1 10 0 5 5 0 0 1-10 0m5-3a3 3 0 1 0 0 6 3 3 0 0 0 0-6" clipRule="evenodd"></path><path fill="currentcolor" d="M13 20a1 1 0 1 1-2 0 1 1 0 0 1 2 0M4 13a1 1 0 1 0 0-2 1 1 0 0 0 0 2M21 12a1 1 0 1 1-2 0 1 1 0 0 1 2 0M18 7a1 1 0 1 0 0-2 1 1 0 0 0 0 2M7 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0M6 19a1 1 0 1 0 0-2 1 1 0 0 0 0 2M19 18a1 1 0 1 1-2 0 1 1 0 0 1 2 0"></path></svg>
            }
        </div>
    )
}

export default ToggleDarkMode