import React, { useEffect, useState } from 'react'
import './index.scss'
import HomeMenu from '../Layout/Menu/Home'
import Countdown from "react-countdown";
import { Navigate, useNavigate } from 'react-router-dom';
import { addUser, getUser, signIn } from '../../services/userService';
import { useTranslation } from 'react-i18next';

const Survey = () => {
    const { t } = useTranslation();

    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )
    
    // const [fullName,setFullName] = useState()
    const [email,setEmail] = useState()
    const [phoneNumber,setPhoneNumber] = useState()
    const [password,setPassword] = useState()
    const [rePassword,setRePassword] = useState()
    const [targetGoal,setTargetGoal] = useState()
    const [currentGoal,setCurrentGoal] = useState()
    const navigate = useNavigate();

    const handleSubmit = async (event)=>{
        event.preventDefault();
        const param = {            
            fullName: userInfo.userName?userInfo.userName :"",
            displayName: userInfo.userName?userInfo.userName :"",
            email:email? email : userInfo.email,
            phoneNumber:phoneNumber? phoneNumber: userInfo.phoneNumber,
            password,
            rePassword,
            goalType: "IELTS",
            targetGoal: targetGoal,
            currentGoal:currentGoal,
            browserId:localStorage.getItem("browserId")
        }
        
        try {
            const create = await addUser(param);
            if(create?.data && create.isSuccess){
                const param = {            
                    userName:email,password
                }
                const result = await signIn(param);
                if(result?.data && result.isSuccess){
                    localStorage.setItem('token',result.data.token)
                    const userinfo = await getUser();
                    localStorage.setItem('userInfo',JSON.stringify(userinfo.data))
                }
                alert(" Upgrade Success")
                navigate("/speaking")
            }else{
                alert(create.errors[0]?.message);
            }
        } catch (error) {
            alert(error.message)
        }
       
    }
    return (
        <>   
            
            <div className='container-fluid survey p-0' id="survey">
                <HomeMenu/>
                    <div className='container container-survey d-flex align-items-center justify-content-center flex-column'>
                       <p className='heigh-light'>{t(`survey.heighLight`)} {userInfo.userName? userInfo.userName : ""},</p>                
                       <p className='guide'>{t(`survey.guide`)}</p>
                                              
                        <div className='container d-flex justify-content-center'>
                            <div className='col-md-4'>
                                <div className='box-survey-info mt-4'>
                                    <form className="row g-3 needs-validation" novalidate onSubmit={handleSubmit}>
                                        <div className="form-group">
                                            <input type="email" className="form-control" defaultValue={userInfo?.email}  placeholder={t(`survey.placEmail`)} onChange={(e)=>setEmail(e.target.value) } required/>
                                        </div>
                                        <div className="form-group">
                                            <input type="number" className="form-control" defaultValue={userInfo?.phoneNumber} placeholder={t(`survey.placPhone`)} onChange={(e)=>setPhoneNumber(e.target.value) } required/>
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control"  placeholder={t(`survey.placPassword`)} onChange={(e)=>setPassword(e.target.value) } required />
                                        </div>
                                        <div className="form-group">
                                            <input type="password" className="form-control" placeholder={t(`survey.placRePassword`)} onChange={(e)=>setRePassword(e.target.value) } required />
                                        </div>
                                        <div className="form-group">
                                        <textarea className="form-control" id="textAreaExample1" rows="2" placeholder={t(`survey.placLevel`)} onChange={(e)=>setCurrentGoal(e.target.value) }></textarea>
                                        </div>
                                        <div className="form-group">
                                            <textarea className="form-control" id="textAreaExample1" rows="3" placeholder={t(`survey.placPoints`)} onChange={(e)=>setTargetGoal(e.target.value) }></textarea>                                        
                                        </div>
                                        <div className="row justify-content-md-center">
                                                <button className="btn btn-default btn-auth">
                                                    {t(`survey.btnConfirm`)}
                                                </button>
                                        </div>

                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>            
            </div>
            
        </>
    )
}
export default Survey