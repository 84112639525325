import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const VoucherModal = (props)=>{
    const navigate = useNavigate()
    const [show, setShow] = useState(0)
    const { t } = useTranslation();
    return (
        <>  
            <div className="voucher-modal">                   
                <div className="fade modal-backdrop show"></div>
                <div className="modal fade show" id="speakingModal" tabIndex={-1} aria-labelledby="speakingModalLabel" style={{display:'block'}} aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" >
                        {
                            show == 0 && 
                            <div className="modal-content d-flex justify-content-center flex-column">
                                <img className="voucher-gif" src="/images/light/voucher.gif" />
                                <p className="title">Chúc mừng bạn đã hoàn thành bài test</p>
                                <p className="sub">Bạn đã nhận được một Voucher Khóa học</p>
                                <p className="sub">từ Ant Edu với trị giá  </p>
                                <p className="voucher-value">5 triệu đồng</p>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" onClick={()=>props.setOpen(false)}>Không, cảm ơn</button>
                                    </div>
                                    <div className='col-6'>
                                        <button type="button" className="btn btn-outline-primary btn-lg w-100 btn-advance" onClick={()=>setShow(1)} >Nhận ngay</button>
                                    </div>
                                </div>
                            </div>
                        }
                        {
                            show ==1 && 
                            <div className="modal-content d-flex justify-content-center flex-column">
                                <img className="voucher-gif" src="/images/light/thankyou.gif" />
                                <p className="title">Cảm ơn bạn</p>
                                <p className="sub">Trong <b>24h</b>  tới Cố vấn Học tập của chúng mình sẽ liên hệ để gửi <b>Voucher</b> đến bạn nhé.</p>
                                <div className='row mt-3'>
                                    <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" onClick={()=>props.setOpen(false)}>Đóng</button>                                    
                                </div>
                            </div>
                        }
                    </div>
                </div>

            </div>
        </>
    )
}
export default VoucherModal