import React from "react";
import "./index.scss"
const ErrorResult = ()=>{
    return(
        <div className='container-fluid error-result d-flex align-items-center'>
            <div className="container">
                <div className="content-wrapper">
                    <section className="content">
                        <div className="error-page">
                            <h2 className="headline text-warning"> 404</h2>
                            <div className="error-content">
                                <h3><i className="fas fa-exclamation-triangle text-warning" /> Oops! Results not found.</h3>
                                <p>
                                We couldn't find the results you were looking for. In the meantime, you can go back to<a href="/"> Home page </a> or try <a href="#" onClick={()=>window.location.reload()} >refreshing</a> the page.
                                </p>
                                <form className="search-form">
                                </form>
                            </div>
                        </div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default ErrorResult