import React, { useEffect, useState } from 'react'
import './index.scss'
import HomeMenu from '../Layout/Menu/Home'
import Countdown from "react-countdown";
import { Navigate, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Completing = () => {
    const navigate = useNavigate()
    const { t } = useTranslation();

    const renderer = ({ hours, minutes, seconds, completed }) => {             
        if (completed) {
            // Render a completed
            // return <Navigate to="/" replace={true} />
        } else {
            return <p>{t(`completing.countDownText`)}{seconds}s!</p>
        } 
    };
    return (
        <>   
            
            <div className='container-fluid completing p-0' id="Completing">
                <HomeMenu/>
                    <div className='container d-flex align-items-center justify-content-center flex-column'>
                       <p className='heigh-light'>{t(`completing.heighLight`)}</p>                
                       <p className='guide'><i dangerouslySetInnerHTML={{__html:t(`completing.guide`)}}></i></p>
                       <p className='guide-qr'>{t(`completing.guideqr`)}</p>
                       <div className='complating-qr'>
                            <img src='./images/completing-qr.png' />
                       </div>
                       <div className='back-to-home'>
                            <div className='btn-back-to-home' onClick={()=>navigate("/")}>
                                <p>{t(`completing.btnBack`)}</p>
                            </div>
                       </div>
                       <div className='auto-back-to-home'>                                                        
                            <Countdown date={Date.now() + 1000000 } renderer={renderer}/>
                       </div>
                    </div>            
            </div>
            
        </>
    )
}
export default Completing