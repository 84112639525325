import React, { useEffect, useState } from 'react'
import './index.scss'
import { CircularProgressbar,buildStyles  } from 'react-circular-progressbar';
import "react-circular-progressbar/dist/styles.css";
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { reviewExamId } from '../../services/ieltsService';
import ErrorResult from '../ComponentShare/ErrorResult';
import { isDesktop} from "react-device-detect";

const ShortTestResult = (props) => {
    const { t } = useTranslation();
    const [reviewExam,setReviewExam] = useState()
    const [examId,setExamId] = useState()
    const [status,setStatus] = useState(false)

    const navigate = useNavigate()
    const [userInfo,setUserInfo] = useState(localStorage.getItem('userInfo')? JSON.parse(localStorage.getItem('userInfo')) : {} )

    const handleSubmit = (event)=>{
        event.preventDefault();
        navigate("/completing")
    }
    const getReviewExamId = async ()=>{
        let exam = localStorage.getItem("exam")
        if(!exam) return;
        exam = JSON.parse(exam)
        const result = await reviewExamId(exam?.id);
        if(result.isSuccess){
            setReviewExam(result.data)
            setExamId(exam?.id ?? null)
        }
    }
    useEffect(()=>{
        getReviewExamId()
    },[])
    return (
        <>  
        {
            status && <>
            
                <div className='row m-1'>
                    <div className='box-result-analysis'>
                        <p className='title'>
                            <b>{t(`resultAnalysis.boxResultAnalysis.title`)}</b>
                        </p>
                        <p className='guide'>
                            {t(`resultAnalysis.boxResultAnalysis.guide1`)}
                        </p>
                        <p className='guide'>
                            {t(`resultAnalysis.boxResultAnalysis.guide2`)}
                        </p>
                        <div className='row mt-5 p-2'>
                            <div className='col-md-6 box-score'>
                                <div className='score-circle'>
                                    <CircularProgressbar
                                        value={(reviewExam?.listeningPoints ?? 0 )}
                                        text={`${reviewExam?.listeningPoints ?? 0 }`}
                                        background
                                        strokeWidth={8}
                                        // backgroundPadding={3}
                                        
                                        styles={buildStyles({
                                            backgroundColor: `none`,
                                            textColor: "#FFFFFF",
                                            pathColor: "#00F0FF",
                                            trailColor: "#060B24",   
                                            // strokeLinecap: "butt"                                                     
                                        })}
                                    />
                                </div>
                                <div className='score-skill'>
                                    <div className='skill-name'>
                                        <p>Listening</p> 
                                    </div>
                                    <div className='skill-score-detail'>
                                        <p>{t(`resultAnalysis.boxResultAnalysis.correctAnswer`)} {reviewExam?.listeningTriPoints ?? 0 }</p> 
                                    </div>
                                </div>
                            </div>
                            <div className='col-md-6 box-score'>
                                <div className='score-circle'>
                                    <CircularProgressbar
                                        value={(reviewExam?.readingPoints?? 0 )}
                                        text={`${reviewExam?.readingPoints ?? 0}`}
                                        background
                                        strokeWidth={8}
                                        // backgroundPadding={3}
                                        
                                        styles={buildStyles({
                                            backgroundColor: `none`,
                                            textColor: "#FFFFFF",
                                            pathColor: "#00F0FF",
                                            trailColor: "#060B24",   
                                            // strokeLinecap: "butt"                                                     
                                        })}
                                    />
                                </div>
                                <div className='score-skill'>
                                    <div className='skill-name'>
                                        <p>Reading</p> 
                                    </div>
                                    <div className='skill-score-detail'>
                                        <p>Total Correct Answer:  {reviewExam?.readingTriPoints ?? 0}</p> 
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div className='row m-1'>
                    <div className='box-speaking-test'>
                        <p className='title' dangerouslySetInnerHTML={{__html:t(`resultAnalysis.speakingTest.sayHi`, {name: userInfo?.userName})}}>
                        </p>
                        <p className='title upgreade'>
                            {t(`resultAnalysis.speakingTest.upgreade`)}
                        </p>                                    
                        <p className='title experience'><b>{t(`resultAnalysis.speakingTest.experience`)}</b></p>
                        <p className='guide'>{t(`resultAnalysis.speakingTest.guide`)}</p>
                    </div>
                </div>
                <div className='row m-1 upgrade'>
                    <div className='freemium'>
                        <div className='box-freemium'>
                            <div className='freemium-head'dangerouslySetInnerHTML={{__html:t(`resultAnalysis.freemium.head`)}}></div>
                            <div className='freemium-content'>
                                <ul>
                                    <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.freemium.content1`)}}></p></li>
                                    <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.freemium.content2`)}}></p></li>
                                    <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.freemium.content3`)}}></p></li>
                                    <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.freemium.content4`)}}></p></li>
                                </ul>
                            </div>
                            <div className='freemium-footer d-flex justify-content-center'>
                                <div className='btn-pulse' onClick={()=>navigate("/survey")}>
                                    <p>{t(`resultAnalysis.freemium.btnUpgrade`)}</p>
                                </div>
                            </div>
                        </div>                                        
                    </div>
                    <div className='guest-user'>
                        <div className='box-guest-user'>
                            <div className='guest-user-head'>{t(`resultAnalysis.guestUser.title`)}</div>
                            <form className="row g-3 needs-validation" novalidate onSubmit={handleSubmit}>
                                <div className='guest-user-content'>
                                    <ul>
                                        <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.guestUser.content1`)}}></p></li>
                                        <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.guestUser.content2`)}}></p></li>
                                        <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.guestUser.content3`)}}></p></li>
                                        <li><img src='./images/checked.png'/> <p dangerouslySetInnerHTML={{__html:t(`resultAnalysis.guestUser.content4`)}}></p></li>
                                    </ul>
                                    <div className="input-group box-username ">
                                        <input type="email" className="form-control" placeholder={t(`resultAnalysis.guestUser.sendEmail`)} required />
                                    </div>
                                </div>
                                <div className='guest-user-footer d-flex justify-content-center'>
                                    <button className='btn btn-pulse' >
                                        <p>{t(`resultAnalysis.guestUser.btnResult`)}</p>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    examId && <p className="text-center mt-4" ><i className={isDesktop? "color-white" : 'color-black'} dangerouslySetInnerHTML={{__html:t(`overview.detailLink1`,{id:examId}) }}></i></p>
                }

            </>
        }
        {
            status && <ErrorResult />
        }
        </>
    )
}
export default ShortTestResult