import React, { useState } from "react";
// import "./index.scss"

const MultipleChoice = ({data,handleAnswer})=>{
    const [checked,setChecked] = useState([])
    const toggleChange =(event)=>{       
        let newChecked = [...checked]
        if(event.target.checked){        
            if(checked.length >= data.points ) {
                alert(`giới hạn ${data.points} đáp án` );
                return;
            }    
            newChecked.push(event.target.id)
            console.log(newChecked)
            setChecked(newChecked)
            handleAnswer(data.id,JSON.stringify(newChecked))
        }else{
             newChecked = newChecked.filter(function (letter) {
            return letter !== event.target.id;
        });
        setChecked(newChecked)
       }
    }
    return(
        <>
        <div className="question"  id={`question-${data?.id}`}>
            <div className="question-name" >
                <p dangerouslySetInnerHTML={{ __html: data?.content}}></p>
            </div>
            {
                data.materials?.map((tj)=>{
                    if(tj.materialType ==='mp3') return (
                            <div key={tj.id} className="question-audio" >
                                <audio className="w-100" src={process.env.REACT_APP_BE_HOST+tj.url}  controls="controls"  ></audio>
                            </div>
                    )
                })
            }
            <div className="list-answer">
                <ul>
                    {
                        data?.answerExams?.map((e)=>{
                            return(
                                <li key={e.id}>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" checked={checked.indexOf(e.id) >= 0 ? true : false} id={e.id} onChange={toggleChange} />
                                        <label className="form-check-label"  htmlFor={e.id}>
                                            {e.content}
                                        </label>
                                    </div>
                                </li>
                            )
                        })
                    }
                                        
                </ul>
            </div>
        </div>
        </>
    )
}
export default MultipleChoice