import React, { useEffect, useRef, useState } from "react";
import './index.scss'
import { useNavigate } from "react-router-dom";
import { checkUser } from "../../../../services/userService";
import { useTranslation } from "react-i18next";

const HomeModal = (props)=>{
    const [show, setShow] = useState(0)
    const refOne = useRef(null)
    const navigate = useNavigate()
    const { t } = useTranslation();

    const handleClickOutside = (e)=>{
        if(!refOne.current?.contains(e.target)){
            console.log("outside")
            setShow(false)

        }else{
            console.log("inside")
        }
    }
    const handleClickImg = ()=>{
        props.selecetTest("/speakingai")
        setShow(2)        
    }
    useEffect(()=>{
        setTimeout(()=>{
          setShow(1)
        }, 1000)
        document.addEventListener("click",handleClickOutside,true)
      }, [])
    return (
        <>  
            {
                show==1 && 
                <div className="home-modal">
                    <div className="fade modal-backdrop show"></div>
                    <div className="modal fade show" id="loading"  style={{display:'block'}}>
                        <div className="modal-dialog modal-dialog-centered modal-xl" ref={refOne}>
                            <div className="modal-content">
                                <div className="modal-body">
                                    <button type="button" className="btn-closex" onClick={()=>setShow(false)}><i className="fa fa-times"></i></button>
                                    <img src='./images/popup.png' onClick={()=>handleClickImg()} />                                    
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
            {
                show==2 && 
                <div className="home-modal">                   
                    <div className="fade modal-backdrop show"></div>
                    <div className="modal fade show" id="speakingModal" tabIndex={-1} aria-labelledby="speakingModalLabel" style={{display:'block'}} aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered" ref={refOne}>
                            <div className="modal-content">
                                <div className='row'>
                                    <div className='col-11 '>
                                        <h1 className="modal-title fs-5 text-center" id="speakingModalLabel" dangerouslySetInnerHTML={{ __html: t(`home.testlist.speakingtest.lable`)}}></h1>
                                    </div>
                                    <div className='col-1 p-0'>
                                        <button type="button" className="btn-close" onClick={()=>setShow(false)} />
                                    </div>
                                </div>
                                <div className='row mt-3'>
                                    <div className='col-6'>
                                        <button type="button" className="btn btn-outline-secondary btn-lg w-100 btn-basic" onClick={()=>props.handleClickTest("basic")}>{t(`home.testlist.speakingtest.selectSkill.basic`)}</button>
                                    </div>
                                    <div className='col-6'>
                                        <button type="button" className="btn btn-outline-primary btn-lg w-100 btn-advance" onClick={()=>props.handleClickTest("advance")} >{t(`home.testlist.speakingtest.selectSkill.advance`)}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            }
        </>
    )
}
export default HomeModal