import axios from 'axios';
import httpClient from './httpClient.service';

const assessmentTest=  () => {
    return new Promise((resolve, reject) => {    
       
    })         
}
export const submit=(data)=>{
  return new Promise((resolve, reject) => {    
    const config = {
      method: 'post',
      url: process.env.REACT_APP_BE_DOMAIN + '/test-temps/submit',
      data : JSON.stringify(data),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    };
    
    axios(config)
    .then(function (response) {
      resolve(response.data)
    })
    .catch(function (error) {
      reject(error)
    });
  }) 
}

export const getHistoryAssessment=(data)=>{
  return new Promise((resolve, reject) => {
    const config = {
      method: 'get',
      url: process.env.REACT_APP_BE_DOMAIN + '/test-temps/history',
      data : JSON.stringify(data),
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`

      },
    };
    axios(config)
    .then(function (response) {
      resolve(response.data)
    })
    .catch(function (error) {
      reject(error)
    });
  }) 
}

export const getHistoriesAssessment=(param)=>{
  return new Promise((resolve, reject) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BE_DOMAIN}/test-temps/histories?${param.join('&')}`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    };
    axios(config)
    .then(function (response) {
      resolve(response.data)
    })
    .catch(function (error) {
      reject(error)
    });
  }) 
}

export const getIeltsExamsAssessment = (param)=>{
  return new Promise((resolve, reject) => {
    httpClient.get(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/histories?${param.join('&')}`)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}

// export const getIeltsExamsAssessment=(param)=>{
//   return new Promise((resolve, reject) => {
//     const config = {
//       method: 'get',
//       url: `${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/histories?${param.join('&')}`,
//       headers: { 
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${localStorage.getItem('token')}`
//       },
//     };
//     axios(config)
//     .then(function (response) {
//       resolve(response.data)
//     })
//     .catch(function (error) {
//       reject(error)
//     });
//   }) 
// }
export const getMetrics=()=>{
  return new Promise((resolve, reject) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/metrics?type=Full`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    };
    axios(config)
    .then(function (response) {
      resolve(response.data)
    })
    .catch(function (error) {
      reject(error)
    });
  }) 
}

export const getRank=(param)=>{
  return new Promise((resolve, reject) => {
    const config = {
      method: 'get',
      url: `${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/ielts-attempts/rank?${param.join('&')}`,
      headers: { 
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      },
    };
    axios(config)
    .then(function (response) {
      resolve(response.data)
    })
    .catch(function (error) {
      reject(error)
    });
  }) 
}
export const getAssessmentById = (id)=>{
  return new Promise((resolve, reject) => {
    httpClient.get(`${process.env.REACT_APP_BE_DOMAIN}/ielts-exams/histories/${id}`)
    .then(res => {
      if (res.status === 200) {
        resolve(res.data);
      } else {              
        reject(res);
      }
    })
    .catch((e) => {
      reject(e);
    });
  });
}
export default assessmentTest